<div class="profilePanel">

    <div class="">

        <div class="flex flex-col w-full bg-slate-50 pb-4">

            <ng-container *ngIf="showTrialStepper">
                <app-trialstepper></app-trialstepper>
            </ng-container>

            <ng-container>
                <div class="flex flex-row items-start">
                    <div class="pb-2">
                        <div class="text-xl md:text-2xl font-semibold tracking-tight leading-7 md:leading-snug truncate mb-2">Scenario: {{scenarioRec.title | titlecase}}</div>
                    </div>
                </div>
            </ng-container>

            <mat-drawer-container class="flex-auto h-full">

                <!-- Drawer content -->
                <mat-drawer-content class="flex flex-col">

                    <!-- Main -->
                    <div class="flex-auto drawerContent">

                        <!-- CONTENT GOES HERE -->
                        <div class="h-full min-h-400 rounded">
                            <router-outlet></router-outlet>
                        </div>

                    </div>

                </mat-drawer-content>

                <!-- Drawer -->
                <mat-drawer class="w-48 dark:bg-gray-900"
                            [autoFocus]="false"
                            [mode]="drawerMode"
                            [opened]="drawerOpened && showSidebar"
                            [position]="drawerPosition"
                            [disableClose]="true"
                            #matDrawer>

                    <!-- Scenario Sidebar -->
                    <ng-template [ngTemplateOutlet]="ScenarioSidebar" *ngIf="showSidebar"></ng-template>

                </mat-drawer>

            </mat-drawer-container>


        </div>
    </div>

</div>




<ng-template #ScenarioSidebar>


    <div class="flex flex-col flex-auto w-full rounded-md shadow-sm py-2 sidebarMenu" *ngIf="showSidebar">

        <div class="text-lg font-bold tracking-tight leading-6 truncate text-center mt-2 mb-2">Scenario Menu</div>

        <div class="bg-card flex flex-col max-w-80 items-center sidebarItem mt-2 ml-2 mr-2 rounded-md shadow-sm"
             [ngClass]="{'bg-teal-100 text-gray-800 dark:bg-gray-600 dark:text-gray-50': getMenuformat(null)}">
            <span class="text-md font-semibold leading-none mt-2">Guidance</span>

            <button class="my-2 mx-1"
                    mat-flat-button
                    [color]="'primary'"
                    (click)="toggleGuidanceOpen()">
                <mat-icon>help</mat-icon><span class="text-md ml-2">Toggle</span>
            </button>

        </div>

        <ng-container *ngFor="let menuitem of scenarioSideBarItems">

            <div class="bg-card flex flex-col max-w-80 items-center sidebarItem mt-2 mx-2 p-1 rounded-md shadow-sm"
                 [ngClass]="{'scenarioMenuHighlight bg-cogsolo-200 text-gray-800 dark:bg-gray-600 dark:text-gray-50': getMenuformat(menuitem)}">
                <span class="text-md font-semibold leading-none mt-2">{{menuitem.title | titlecase}}</span>

                <a class="mt-2 mb-2"
                   [color]="'primary'"
                   (click)="scenarioMenu(menuitem)" *ngIf="menuitem.title=='Scenario' || menuitem.title=='Response' || menuitem.title=='Practice'">
                    <img class="scenarioThumb" [src]="menuitem.thumbnail">
                </a>
                <button class="mt-2 mb-2"
                        mat-flat-button
                        [color]="'primary'"
                        (click)="scenarioMenu(menuitem)" *ngIf="menuitem.title=='New Response'">
                    <mat-icon>{{menuitem.icon}}</mat-icon><span class="text-md ml-2">{{menuitem.label}}</span>
                </button>
                <button *ngIf="feedbackCompleted && menuitem.id=='feedback'" class="mt-2 mb-2"
                        mat-flat-button
                        [color]="'primary'"
                        matBadge="*" matBadgePosition="after" matBadgeSize="small" matBadgeColor="accent"
                        (click)="scenarioMenu(menuitem)">
                    <mat-icon>{{menuitem.icon}}</mat-icon><span class="text-md ml-2">{{menuitem.label}}</span>
                </button>

                <button *ngIf="!feedbackCompleted && menuitem.id=='feedback'" class="mt-2 mb-2"
                        mat-flat-button
                        [color]="'primary'"
                        (click)="scenarioMenu(menuitem)">
                    <mat-icon>{{menuitem.icon}}</mat-icon><span class="text-md ml-2">{{menuitem.label}}</span>
                </button>


                <span class="text-sm text-secondary leading-none mt-1 mb-2" *ngIf="ShowMenuDate(menuitem)">{{menuitem.date | date : "short"}}</span>



            </div>

        </ng-container>



    </div>

</ng-template>

