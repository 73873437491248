
export class FeedbackStatus {

    name: string;
    messageText: string;
    status: boolean;
    value: number;
}

export class FeedbackStatusArray {

    feedbackStatuses: FeedbackStatus[];
}
