<div class="text-2xl md:text-5xl font-semibold tracking-tight leading-7 md:leading-snug truncate">{{PageTitle}}</div>

<div *ngIf="!showItemDetail">

    <div class="sectionPanel">
        <p class="text-2xl font-semibold">Item 1</p>
        <p class="text-secondary">Select a first item for which to set the graph relationships.</p>
    </div>

    <mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <!-- New Aspect Column -->
        <ng-container matColumnDef="newAspect">
            <mat-header-cell *matHeaderCellDef> New ?</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.newAspect}} </mat-cell>
        </ng-container>

        <!-- Aspect Type -->
        <ng-container matColumnDef="aspectType">
            <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.aspectType}} </mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
        </ng-container>

        <!-- Id Column -->
        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
        </ng-container>

        <!-- Start Column -->
        <ng-container matColumnDef="engStart">
            <mat-header-cell *matHeaderCellDef> Start </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.engStart}} </mat-cell>
        </ng-container>

        <!-- Early Column -->
        <ng-container matColumnDef="engEarly">
            <mat-header-cell *matHeaderCellDef> Early </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.engEarly}} </mat-cell>
        </ng-container>

        <!-- Detail Column -->
        <ng-container matColumnDef="details">
            <mat-header-cell *matHeaderCellDef> Details </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <button class="px-3 mx-3"
                        mat-flat-button
                        [color]="'primary'" (click)="SelectFirstItem(element)">
                    <span>Select</span>
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
    </mat-table>

</div>

<!-- Selected Aspect Edit Details Form -->

<div class="w-full" *ngIf="showItemDetail">

    <div class="w-full">

        <div class="flex flex-col mt-8 bg-card shadow rounded overflow-hidden">
            <div class="sectionPanel">
                <p class="text-2xl font-semibold">Aspect Relationships</p>
                <p class="text-secondary">Adjust the relationships for the selected aspect.</p>
            </div>
            <mat-divider></mat-divider>

            <div class="flex items-center justify-left mt-8 mb-8">
                <button class="px-3 mx-3"
                        mat-flat-button
                        [color]="'primary'" (click)="AddNewEdge()">
                    <mat-icon>add</mat-icon>
                    <span>Add New Edge</span>
                </button>

                <button class="px-6 ml-3"
                        mat-flat-button
                        type="button"
                        [color]="'primary'"
                        (click)="SaveEdges()">
                    Save Edges
                </button>

                <!-- Alert -->
                <fuse-alert class="mt-8"
                            *ngIf="showAlert"
                            [appearance]="'outline'"
                            [showIcon]="false"
                            [type]="alert.type"
                            [@shake]="alert.type === 'error'">
                    {{alert.message}}
                </fuse-alert>

                <button class="px-6 ml-3"
                        mat-flat-button
                        type="button"
                        [color]="'primary'"
                        (click)="CloseItem()">
                    Close
                </button>

            </div>

            <mat-divider></mat-divider>

            <!-- Set of relationships for the selected first item -->
            <form id="assignmentForm" [formGroup]="assignmentForm" class="w-full" *ngIf="hasRelationships">
                <ng-container formArrayName="formedges">
                    <mat-table [dataSource]="formedges.controls" class="mat-elevation-z8">

                        <!-- Checkbox Column -->
                        <!--<ng-container matColumnDef="select">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="edgeselectHandler(row)"
                                      [checked]="edgeselection.isSelected(row)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>-->
                        <!-- Start Node Name Column -->
                        <ng-container matColumnDef="startnodename">
                            <mat-header-cell *matHeaderCellDef> Start Node </mat-header-cell>
                            <mat-cell *matCellDef="let element" [formGroup]="element">
                                <mat-form-field floatLabel="never">
                                    <input matInput [placeholder]="startNodeName" formControlName="startNodeName" [readonly]="true">
                                </mat-form-field>
                            </mat-cell>

                        </ng-container>

                        <!-- End Node Name Column -->
                        <ng-container matColumnDef="endnodename">
                            <mat-header-cell *matHeaderCellDef> End Node </mat-header-cell>
                            <mat-cell *matCellDef="let element" [formGroup]="element">
                                <mat-form-field floatLabel="never">
                                    <input matInput [placeholder]="endNodeName" formControlName="endNodeName" [readonly]="true">
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>

                        <!-- End Node Id Column -->
                        <ng-container matColumnDef="endnodeid">
                            <mat-header-cell *matHeaderCellDef> End Node Id </mat-header-cell>
                            <mat-cell *matCellDef="let element" [formGroup]="element">
                                <mat-form-field floatLabel="never">
                                    <input matInput [placeholder]="endId" formControlName="endId" [readonly]="true">
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>

                        <!-- Relationship Type -->
                        <ng-container matColumnDef="type">
                            <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
                            <mat-cell *matCellDef="let element;" [formGroup]="element">
                                <mat-form-field floatLabel="never">
                                    <mat-select formControlName="type" required>
                                        <mat-option value="Precedes">Precedes</mat-option>
                                        <mat-option value="RelatesTo">Relates To</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>


                        <!-- Length -->
                        <ng-container matColumnDef="length">
                            <mat-header-cell *matHeaderCellDef> Length </mat-header-cell>
                            <mat-cell *matCellDef="let element" [formGroup]="element">
                                <mat-form-field floatLabel="never">
                                    <input matInput [placeholder]="length" formControlName="length" required>
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>

                        <!-- Weight -->
                        <ng-container matColumnDef="weight">
                            <mat-header-cell *matHeaderCellDef> Weight </mat-header-cell>
                            <mat-cell *matCellDef="let element" [formGroup]="element">
                                <mat-form-field floatLabel="never">
                                    <input matInput [placeholder]="weight" formControlName="weight" required>
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>

                        <!-- Actions Column -->
                        <ng-container matColumnDef="actions">
                            <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <button class="px-3 mx-3"
                                        mat-flat-button
                                        [color]="'primary'" (click)="DeleteEdge(element)">
                                    <mat-icon>delete</mat-icon>
                                    <span>Delete</span>
                                </button>
                            </mat-cell>
                        </ng-container>


                        <mat-header-row *matHeaderRowDef="edgedisplayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: edgedisplayedColumns;">
                        </mat-row>
                    </mat-table>

                </ng-container>
            </form>

        </div>

    </div>


</div>

<div *ngIf="showItem2">

    <div class="sectionPanel">
        <p class="text-2xl font-semibold">Item 2</p>
        <p class="text-secondary">Select a second item to create a relationship to.</p>
    </div>

    <mat-table [dataSource]="dataSource2" class="mat-elevation-z8">

        <!-- New Aspect Column -->
        <ng-container matColumnDef="newAspect">
            <mat-header-cell *matHeaderCellDef> New ?</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.newAspect}} </mat-cell>
        </ng-container>

        <!-- Aspect Type -->
        <ng-container matColumnDef="aspectType">
            <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.aspectType}} </mat-cell>
        </ng-container>


        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
        </ng-container>

        <!-- Id Column -->
        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
        </ng-container>

        <!-- Start Column -->
        <ng-container matColumnDef="engStart">
            <mat-header-cell *matHeaderCellDef> Start </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.engStart}} </mat-cell>
        </ng-container>

        <!-- Early Column -->
        <ng-container matColumnDef="engEarly">
            <mat-header-cell *matHeaderCellDef> Early </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.engEarly}} </mat-cell>
        </ng-container>

        <!-- Detail Column -->
        <ng-container matColumnDef="details">
            <mat-header-cell *matHeaderCellDef> Details </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <button class="px-3 mx-3"
                        mat-flat-button
                        [color]="'primary'" (click)="SelectSecondItem(element)">
                    <span>Select</span>
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
    </mat-table>

</div>
