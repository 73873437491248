<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
    <fuse-vertical-navigation
        class="dark bg-gray-900 print:hidden"
        [mode]="'over'"
        [name]="'mainNavigation'"
        [navigation]="navigation.default"
        [opened]="false">
        <!-- Navigation header hook -->
        <ng-container fuseVerticalNavigationContentHeader>
            <!-- Logo -->
            <div class="flex items-center h-20 pt-6 px-8">
                <img class="w-24"
                     src="assets/images/logo/cogsolo_logo_1.png"
                     alt="Logo image">
            </div>

            <div class="flex flex-0 items-center justify-center h-8 pr-6 pl-2 mt-2 mb-4">
                <div class="ml-0.5 font-medium text-secondary">
                    <span>Beta Version 0.8</span>
                </div>
            </div>

        </ng-container>
    </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto h-screen">

    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 dark:shadow-none border-b dark:border-b bg-card dark:bg-transparent print:hidden">

        <ng-container *ngIf="!isScreenSmall">
            <!-- Logo -->
            <div class="flex items-center mx-2 lg:mr-8">
                <div class="hidden lg:flex">
                    <img class="dark:hidden w-24"
                         src="assets/images/logo/cogsolo_logo_1.png" />
                    <img class="hidden dark:flex w-24"
                         src="assets/images/logo/cogsolo_logo_1.png" />
                </div>
                <img class="flex lg:hidden w-8"
                     src="assets/images/logo/cogsolo_logo_1.png" />
            </div>

            <div class="flex flex-0 items-center justify-center h-8 pr-6 pl-2 mt-2 mb-4">
                <div class="ml-0.5 font-medium text-secondary">
                    <span>Beta Version 0.8</span>
                </div>
            </div>

            <!-- Horizontal navigation -->
            <!--<fuse-horizontal-navigation
            class="mr-2"
            [name]="'mainNavigation'"
            [navigation]="navigation.horizontal"></fuse-horizontal-navigation>-->
        </ng-container>
        <!-- Navigation toggle button -->
        <!--<ng-container *ngIf="isScreenSmall">
            <button
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>
        </ng-container>-->
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <!--<languages></languages>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <search [appearance]="'bar'"></search>
            <shortcuts></shortcuts>-->
            <messages></messages>
            <notifications></notifications>
            <!--<button class="lg:hidden"
                    mat-icon-button
                    (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
            </button>-->
            <user></user>
        </div>
    </div>

    <!-- MAIN PORTION OF THE APPLICATION -->
    <!-- Content -->
    <div class="flex-grow w-full">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
        Otherwise, layout changes won't be registered and the view won't be updated! -->
        <!-- Don't need a router outlet here because this is just passing layout control to one of 3 primary layout controllers -->
        <!--<router-outlet *ngIf="true"></router-outlet>-->


        <div class="flex flex-col flex-auto min-w-0 bg-slate-50">

            <!--<div>Modern layout router outlet header </div>-->
            <router-outlet></router-outlet>
            <!--<div>Modern layout router outlet footer</div>-->
            <!--<app-userprofile *ngIf="userType === 'User'"></app-userprofile>

            <app-coachprofile *ngIf="userType === 'Coach'"></app-coachprofile>

            <app-adminprofile *ngIf="userType === 'Admin'"></app-adminprofile>-->

        </div>

    </div>

    <!-- Footer -->
    <!--<div class="relative flex flex-0 items-center w-full h-14 sm:h-20 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div>-->
    <!-- Footer -->
    <div class="flex items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card text-white dark:bg-transparent print:hidden bg-slate-800">
        <div class="flex-none w-14 h-14"></div>
        <div class="flex grow font-medium text-secondary items-center justify-center">
            IdeaGPS, Inc. &copy; {{currentYear}}
        </div>
        <div class="flex-none w-14 h-14"></div>
    </div>

</div>

<!-- Quick chat -->
<!--<quick-chat #quickChat="quickChat"></quick-chat>-->
