import { NgModule } from '@angular/core';
import { UserProfileComponent } from 'app/modules/userprofile/userprofile.component';
import { SharedModule } from '../../shared/shared.module';
import { FullDashboardModule } from './fulldashboard/fulldashboard.module';
import { ProfileDetailModule } from './profiledetail/profiledetail.module';
import { ProgramSettingsModule } from './programsettings/programsettings.module';
import { ProgramStreamModule } from './programstream/programstream.module';
import { RecConfigModule } from './recconfig/recconfig.module';
import { ProgramSummaryListModule } from './programsummary/programsummarylist/programsummarylist.module';
import { ProgramScenarioDetailModule } from './programsummary/programscenariodetail/programscenariodetail.module';
import { UserProfileRoutingModule } from './userprofile.routing';
import { FuseNavigationModule } from '@fuse/components/navigation';
import { UserModule } from '../../layout/common/user/user.module';

@NgModule({
    declarations: [
        UserProfileComponent
    ],
    imports: [
        SharedModule,
        RecConfigModule,
        ProfileDetailModule,
        ProgramStreamModule,
        ProgramSettingsModule,
        FullDashboardModule,
        ProgramSummaryListModule,
        ProgramScenarioDetailModule,
        UserProfileRoutingModule,
        FuseNavigationModule,
        UserModule,
    ],
    exports: [
        UserProfileComponent
    ]
})
export class UserProfileModule
{
}
