<div class="text-2xl md:text-5xl font-semibold tracking-tight leading-7 md:leading-snug truncate">{{PageTitle}}</div>

<button class="px-3 mx-3 mb-5"
        mat-flat-button
        [color]="'primary'" (click)="AddNewItem()">
    <mat-icon>add</mat-icon>
    <span>Add New Aspect</span>
</button>


<!-- Selected Aspect Edit Details Form -->
<ng-template [ngTemplateOutlet]="ItemDetail" *ngIf="showItemDetail"></ng-template>

<div *ngIf="!showItemDetail">

    <mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <!-- New Aspect -->
        <ng-container matColumnDef="newAspect">
            <mat-header-cell *matHeaderCellDef> New? </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.newAspect}} </mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
        </ng-container>

        <!-- Id Column -->
        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
        </ng-container>

        <!-- Start Column -->
        <ng-container matColumnDef="engStart">
            <mat-header-cell *matHeaderCellDef> Start </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.engStart}} </mat-cell>
        </ng-container>

        <!-- Early Column -->
        <ng-container matColumnDef="engEarly">
            <mat-header-cell *matHeaderCellDef> Early </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.engEarly}} </mat-cell>
        </ng-container>

        <!-- Aspect Type -->
        <ng-container matColumnDef="aspectType">
            <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.aspectType}} </mat-cell>
        </ng-container>

        <!-- Detail Column -->
        <ng-container matColumnDef="details">
            <mat-header-cell *matHeaderCellDef> Details </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <button class="px-3 mx-3"
                        mat-flat-button
                        [color]="'primary'" (click)="ShowItemDetail(element)">
                    <span>Details</span>
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
    </mat-table>

</div>


<ng-template #ItemDetail>

    <div class="flex flex-auto">
        <form id="aspectDetailForm" [formGroup]="aspectDetailForm" class="w-full">


            <div class="flex flex-col mt-8 bg-card shadow rounded overflow-hidden">
                <div class="sectionPanel">
                    <p class="text-2xl font-semibold">Aspect Details</p>
                    <p class="text-secondary">All fields are required.</p>
                </div>
                <mat-divider></mat-divider>

                <div class="flex items-center justify-left mt-8 mb-8">
                    <button class="px-6 ml-3"
                            mat-flat-button
                            type="button"
                            [color]="'primary'"
                            (click)="SaveItem()">
                        <mat-icon>save</mat-icon>
                        Save Item
                    </button>

                    <button class="px-6 ml-3"
                            mat-flat-button
                            type="button"
                            [color]="'warn'"
                            (click)="DeleteItem()">
                        <mat-icon>delete</mat-icon>
                        Delete Item
                    </button>

                    <button class="px-6 ml-3"
                            mat-flat-button
                            type="button"
                            [color]="'primary'"
                            (click)="CloseItem()">
                        <mat-icon>close</mat-icon>
                        Close
                    </button>

                    <!-- Alert -->
                    <fuse-alert class="mt-8"
                                *ngIf="showAlert"
                                [appearance]="'outline'"
                                [showIcon]="false"
                                [type]="alert.type"
                                [@shake]="alert.type === 'error'">
                        {{alert.message}}
                    </fuse-alert>
                </div>
                <mat-divider></mat-divider>
                <div class="p-8 pb-4">

                    <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                        <mat-form-field class="w-full">
                            <mat-label class="font-semibold mt-5">Name</mat-label>
                            <input id="name"
                                   matInput
                                   [formControlName]="'name'">
                        </mat-form-field>
                    </div>

                    <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                        <mat-form-field class="w-full">
                            <mat-label class="font-semibold mt-5">Description</mat-label>
                            <input id="description"
                                   matInput
                                   [formControlName]="'description'">
                        </mat-form-field>
                    </div>


                    <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                        <mat-form-field class="fuse-mat-dense flex-auto">
                            <mat-label class="font-semibold mt-5">Aspect Type</mat-label>
                            <mat-select formControlName="aspectType">
                                <mat-option value="aspect">Aspect</mat-option>
                                <mat-option value="aspectgroup">Aspect Group</mat-option>
                                <mat-option value="model">Model</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                        <mat-form-field class="fuse-mat-dense flex-auto">
                            <mat-label class="font-semibold mt-5">New ?</mat-label>
                            <mat-select formControlName="newAspect">
                                <mat-option value="true">True</mat-option>
                                <mat-option value="false">False</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>


                    <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                        <span class="font-semibold mt-5 mb-1 gt-xs:text-right gt-xs:min-w-32 gt-xs:mr-4 gt-xs:mb-0">Required Money Budget</span>
                        <div class="fuse-mat-dense flex-auto">
                            <mat-slider min="1" max="1000" step="5" showTickMarks discrete class="slider">
                                <input matSliderThumb formControlName="moneyBudget">
                            </mat-slider>Currently: ${{selectedItem.moneyBudget}}
                        </div>
                    </div>


                    <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                        <span class="font-semibold mt-5 mb-1 gt-xs:text-right gt-xs:min-w-32 gt-xs:mr-4 gt-xs:mb-0">Required Time Budget</span>
                        <div class="fuse-mat-dense flex-auto">
                            <mat-slider min="0" max="1000" step="5" showTickMarks discrete class="slider">
                                <input matSliderThumb formControlName="hrsBudget">
                            </mat-slider>Currently: {{selectedItem.hrsBudget}} Hours
                        </div>
                    </div>

                    <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                        <span class="font-semibold mt-5 mb-1 gt-xs:text-right gt-xs:min-w-32 gt-xs:mr-4 gt-xs:mb-0">Starting Engagement</span>
                        <p class="text-secondary">Higher value indicates it's more important as a starting aspect in a program.</p>
                        <div class="fuse-mat-dense flex-auto">
                            <mat-slider min="0" max="5" step="1" showTickMarks discrete class="slider">
                                <input matSliderThumb formControlName="engStart">
                            </mat-slider>Currently: {{selectedItem.engStart}}
                        </div>
                    </div>

                    <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                        <span class="font-semibold mt-5 mb-1 gt-xs:text-right gt-xs:min-w-32 gt-xs:mr-4 gt-xs:mb-0">Early Engagement</span>
                        <p class="text-secondary">Higher value indicates it's more important early in a program.</p>
                        <div class="fuse-mat-dense flex-auto">
                            <mat-slider min="0" max="5" step="1" showTickMarks discrete class="slider">
                                <input matSliderThumb formControlName="engEarly">
                            </mat-slider>Currently: {{selectedItem.engEarly}}
                        </div>
                    </div>

                </div>


            </div>

        </form>

    </div>


</ng-template>
