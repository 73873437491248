import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { FormBuilder, FormGroup, FormControl} from '@angular/forms';
import { SMService } from '../../../services/sm.service';
import { ILoadResult } from '../../../models/ILoadResult';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { FuseAlertType } from '@fuse/components/alert';
import { fuseAnimations } from '@fuse/animations';
import { Scenario } from '../../../models/Scenario';
import { v4 as uuidv4, NIL as NIL_UUID } from 'uuid';
import { VideoProcessingService } from '../../userprofile/programsummary/programscenariodetail/viewresponse/video-processing-service';
import { Rubric } from '../../../models/Rubric';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FeedbackOutput } from '../../../models/FeedbackOutput';

export enum SelectType {
    single,
    multiple
}

@Component({
  selector: 'app-adminscenarios',
    templateUrl: './adminscenarios.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    styleUrls: ['./adminscenarios.component.css']
})

export class AdminScenariosComponent implements OnInit {
    baseUrl = '';
    public theURL: string;
    public thehttp: HttpClient;
    public loadResults: ILoadResult[];
    public currentResult: string;
    public currentUser: string = "";
    public smService: SMService;
    public scenarioDetailForm: FormGroup;
    public scenarioVideoForm: FormGroup;
    public videoFile: File;
    public type: string;
    public thumbnailData: string;
    public showPreview: boolean = false; // Whether to show the video preview
    public videoPreviewUrl: string;
    public fileName: string;
    public displayFileName: string;
    public uploadfile: File;

    public PageTitle: string = "Scenarios Admin";

    public scenarios: Scenario[];
    public filteredscenarios: Scenario[];
    public filteredscenariosLength: number = 0;

    public selectedItem: Scenario;
    public showItemDetail: boolean = false;
    public showItemDetailForm: boolean = true;
    public transcript: string = "";
    public syntheticResponse: string = "";
    public feedbackOutputs: FeedbackOutput[];

    public rubrics: Rubric[];
    public selectedRubric: Rubric;
    public showRubricDetail: boolean = false;
    public rubricDetailForm: FormGroup;

    visibleTab: string = "";

    evalDisplayedColumns: string[] = [
        "question",
        "response",
        "score"
    ];

    evalDataSource = new MatTableDataSource<Rubric>();


    displayedColumns: string[] = [
        "ASLid",
        "ASLname",
        "ASLdescription",
        "ASLresourceURL",
        "ASLformat",
        "ASLindexed",
        "ASLnumRelationships",
        "ASLhasTranscript",
        "ASLrubrics",
        "ASLdetails"
    ];

    rubricDisplayedColumns: string[] = [
        "rubricnumber",
        "rubricquestion",
        "rubricintent",
        "rubriclevel",
        "rubricpriority",
        "rubricquestionFormat",
        "rubrictype",
        "rubricdetails"
    ];

    selectType = [
        { text: "Single", value: SelectType.single },
        { text: "Multiple", value: SelectType.multiple }
    ];

    selectFilterType = [
        { text: "Scenarios", value: "Scenarios"},
        { text: "Tips", value: "Tips" },
        { text: "All", value: "All" },
    ];

    dataSource = new MatTableDataSource<Scenario>();
    selection = new SelectionModel<Scenario>(true, []);
    displayType = SelectType.multiple;
    filterType = "Scenarios";

    rubricsDataSource = new MatTableDataSource<Rubric>();
    rubricSelection = new SelectionModel<Rubric>(true, []);


    alert: { type: FuseAlertType; message: string } = {
        type: 'success',
        message: 'Successfully updated the item.'
    };
    showAlert: boolean = false;

    selectedFiles?: FileList;
    selectedFileNames: string[] = [];
    progressInfos: any[] = [];
    message: string[] = [];
    previews: string[] = [];
    public hasPreview: boolean = false;
    public showSelectedFile: boolean = false;

    constructor(
        http: HttpClient,
        private formBuilder: FormBuilder,
        sms: SMService,
        private videoService: VideoProcessingService,
        private sanitizer: DomSanitizer
        )
    {
        this.baseUrl = environment.BASE_URL;
        this.theURL = this.baseUrl;
        this.thehttp = http;
        this.smService = sms;
    }

    selectHandler(row: Scenario) {
        if (this.displayType == SelectType.single) {
            if (!this.selection.isSelected(row)) {
                this.selection.clear();
            }
        }
        this.selection.toggle(row);
    }

    selectRubricHandler(row: Rubric) {
        if (this.displayType == SelectType.single) {
            if (!this.rubricSelection.isSelected(row)) {
                this.rubricSelection.clear();
            }
        }
        this.rubricSelection.toggle(row);
    }

    onChange(typeValue: number) {
        this.displayType = typeValue;
        this.selection.clear();
    }

    onFilterChange(typeValue: string) {
        this.filterType = typeValue;
        this.FilterScenarios();
    }


    ngOnInit(): void {

        this.ReloadScenarios();
    }

    ReloadScenarios() {
        // Get the List of Scenarios in the Graph Database
        this.smService.GetAdminScenarios().subscribe(result => {

            this.scenarios = result.scenarios;
            this.FilterScenarios();

        }, error => console.error(error));
    }

    FilterScenarios() {

        // Filter the list of scenarios based on the filterType
        if (this.filterType == "Scenarios") {
            this.filteredscenarios = this.scenarios.filter(x => x.format == "Scenario" || x.format == "scenario");
        }
        else if (this.filterType == "Tips") {
            this.filteredscenarios = this.scenarios.filter(x => (x.format == "Tip" || x.format == "tip" || x.format == "" || x.format == undefined));
        } else {
            this.filteredscenarios = this.scenarios;
        }

        // Filter further to remove any scenarios that have no uploaded video for now
        //this.filteredscenarios = this.filteredscenarios.filter(x => x.resourceURL != null && x.resourceURL != "");

        // Sort the scenarios by scenarioNum which need to be converted from strings to integers
        this.filteredscenarios.sort((a, b) => Number.parseInt(a.scenarioNum) - Number.parseInt(b.scenarioNum));

        this.filteredscenariosLength = this.filteredscenarios.length;
        this.dataSource = new MatTableDataSource<Scenario>(this.filteredscenarios);

    }

    public scrollToTop() {
        window.scrollTo(0, 0);
    }


    CloseItem() {
        this.showItemDetail = false;
        //this.showItemDetailForm = false;
        this.showSelectedFile = false;
        this.transcript = "";
        this.syntheticResponse = "";

        this.FilterScenarios();
    }


    CloseRubric() {
        this.showRubricDetail = false;
    }

    EditItem() {
        this.showItemDetailForm = true;

    }

    GetTranscript() {

        // Load the transcript for the given scenario
        this.smService.GetScenarioTranscript(this.selectedItem.id, this.selectedItem.transcriptURL).subscribe(result => {

            this.transcript = result.transcript;
            this.selectedItem.transcript = this.transcript;
            //console.log("Transcript Text: " + this.transcript.toString());

            this.scenarioDetailForm.controls['transcript'].setValue(this.transcript);


        }, error => console.error(error));

    }

    GenResponse() {

        // Generate and load a synthetic response for the given scenario
        this.smService.GetSyntheticScenResponse(this.selectedItem.id, this.selectedItem.transcriptURL).subscribe(result => {

            this.syntheticResponse = result.syntheticResponse;
            //console.log("Synthetic Response Text: " + this.syntheticResponse.toString());

        }, error => console.error(error));
    }

    TestResponse() {

        // Test the evaluation of the current synthetic response
        this.smService.TestSyntheticScenResponse(this.selectedItem, this.syntheticResponse).subscribe(fboutputs => {

            // Initialize the feedbackOutputs array
            this.feedbackOutputs = [];

            // Push each of the feedback outputs to local feedbackOutputs array
            if (fboutputs == undefined || fboutputs == null) {
                return;
            }
            this.feedbackOutputs.push(...fboutputs);

            // Group the feedbackOutputs by feedbackType
            if (this.feedbackOutputs != undefined && this.feedbackOutputs != null) {
                this.feedbackOutputs.sort((a, b) => (a.feedbackType > b.feedbackType) ? 1 : -1);
            }

            // Loop through each feedback output and create a table data source for each
            this.feedbackOutputs.forEach(fbo => {
                if (fbo.rubrics != undefined && fbo.rubrics != null) {
                    if (fbo.feedbackType == "UserResponseTextAnalysis") {
                        console.log("Feedback Type for fbo: " + fbo.id + " is: " + fbo.feedbackType);
                        fbo.dataSource = new MatTableDataSource<Rubric>(fbo.rubrics);
                        this.evalDataSource = new MatTableDataSource<Rubric>(fbo.rubrics);
                    }
                }
            });

        },
            (error) => {
                console.log("Error loading feedback outputs: " + error);
            });
    }

    SaveEvaluations() {

        // Save the given feedback outputs 
        /*this.smService.SaveSyntheticEvaluations(this.selectedItem, this.syntheticResponse, this.feedbackOutputs).subscribe(result => {

            console.log("Saved feedback outputs: " + result);

        }, error => console.error(error));*/


    }



    ShowItemDetail(item) {
        this.selectedItem = item;
        this.showAlert = false;

        this.smService.VideoSource.next(this.selectedItem.resourceURL);

        this.scenarioDetailForm = this.formBuilder.group({
            name: [this.selectedItem.name],
            description: [this.selectedItem.description],
            scenarioIntent: [this.selectedItem.scenarioIntent],
            transcript: [this.selectedItem.transcript],
            title: [this.selectedItem.title],
            format: [this.selectedItem.format],
            difficulty: [this.selectedItem.difficulty],
            estEngagement: [this.selectedItem.estEngagement],
            estTimeInvestment: [this.selectedItem.estTimeInvestment],
            estMoneyInvestment: [this.selectedItem.estMoneyInvestment],
            estEnergyInvestment: [this.selectedItem.estEnergyInvestment],
            indexed: [this.selectedItem.indexed],
            sequence: [this.selectedItem.sequence],
            userLevel: [this.selectedItem.userLevel],
            practiceRequired: [this.GetSelectString(this.selectedItem.practiceRequired)],
            ucPublicSpeaking: [this.GetSelectString(this.selectedItem.uC_PublicSpeaking)],
            ucInterviews: [this.GetSelectString(this.selectedItem.uC_Interviews)],
            ucTeaching: [this.GetSelectString(this.selectedItem.uC_Teaching_and_training)],
            ucSalesMarketing: [this.GetSelectString(this.selectedItem.uC_Sales_marketing)],
            ucSocialNetworking: [this.GetSelectString(this.selectedItem.uC_Social_and_Networking)],
            notes: [this.selectedItem.notes],
        });

        this.scenarioVideoForm = this.formBuilder.group({
            fileInput: new FormControl(),
        });

        // Load any rubrics for the given scenario
        this.smService.GetScenarioRubrics(this.selectedItem.id).subscribe(result => {

            this.rubrics = result.rubrics;
            this.rubricsDataSource = new MatTableDataSource<Rubric>(this.rubrics);

            this.selectedItem.numRubrics = this.rubrics.length;

            this.showItemDetail = true;

            this.scrollToTop();

        }, error => console.error(error));

        // Reload the transcript for the scenario
        this.GetTranscript();
    }

    getDataSource(fbo: FeedbackOutput) {
        return fbo.dataSource;
    }

    public getMaxScore(rubric: Rubric): number {

        var score = 10;
        if (rubric != undefined) {
            if (rubric.maxScore != null) {
                score = rubric.maxScore;
            }
        }
        return score;
    }

    public getRubricPointsPerc(rubric: Rubric): number {
        var perc = 0;

        var maxscore = 0;
        maxscore = this.getMaxScore(rubric);
        if (maxscore != 0) {
            perc = rubric.score / maxscore;
        }

        perc = perc * 100;

        return perc;
    }


    public AddRubric() {

        console.log("Adding new rubric");

        var numRubric = this.rubrics.length + 1;

        // Create a new rubric
        this.selectedRubric = new Rubric();
        this.showAlert = false;

        this.rubricDetailForm = this.formBuilder.group({
            intent: ['Rubric'],
            level: ['Beginner'],
            number: [numRubric],
            priority: ['Low'],
            question: [''],
            questionFormat: ['Binary'],
            type: ['Q']
        });

        this.showRubricDetail = true;

    }


    ShowRubricDetail(item) {
        this.selectedRubric = item;
        this.showAlert = false;

        this.rubricDetailForm = this.formBuilder.group({
            intent: [this.selectedRubric.intent],
            level: [this.selectedRubric.level],
            number: [this.selectedRubric.number],
            priority: [this.GetRubricPriorityString(this.selectedRubric.priority)],
            question: [this.selectedRubric.question],
            questionFormat: [this.selectedRubric.questionFormat],
            type: [this.selectedRubric.type]
        });

        this.showRubricDetail = true;

    }

    GetRubricPriorityString(value: number) {
        if (value == 1) {
            return "Low";
        } else if (value == 2) {
            return "Medium";
        } else {
            return "High";
        }
    }

    GetSelectString(value: number) {
        if (value == 1) {
            return "Yes";
        } else {
            return "No";
        }
    }

    GetSelectValue(value: string) {

        // Convert the string to a number

        if (value == "Yes") {
            return 1;
        } else {
            return 0;
        }
    }

    GetRubricPriorityValue(value: string) {
        if (value == "Low") {
            return 1;
        } else if (value == "Medium") {
            return 2;
        } else {
            return 3;
        }
    }



    AddNewItem() {
        console.log("Adding new scenario");

        // Create a new scenario
        this.selectedItem = new Scenario();
        this.showAlert = false;

        this.selectedItem.id = NIL_UUID; // Of format '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
        this.selectedItem.estEngagement = 1.0;
        this.selectedItem.estTimeInvestment = 1.0;
        this.selectedItem.estMoneyInvestment = 1.0;
        this.selectedItem.estEnergyInvestment = 1.0;
        this.selectedItem.scenarioType = "video";
        this.selectedItem.format = "Scenario";
        this.selectedItem.difficulty = "Basic";
        this.selectedItem.indexed = false;
        this.selectedItem.userLevel = "All";

        this.selectedItem.practiceRequired = 1;
        this.selectedItem.uC_PublicSpeaking = 1;
        this.selectedItem.uC_Interviews = 1;
        this.selectedItem.uC_Teaching_and_training = 1;
        this.selectedItem.uC_Sales_marketing = 1;
        this.selectedItem.uC_Social_and_Networking = 1;
        this.selectedItem.notes = "";

        this.scenarioDetailForm = this.formBuilder.group({
            name: ['New scenario'],
            title: ['New_Scenario_Title'],
            description: ['New scenario description'],
            scenarioIntent: ['New scenario intent'],
            transcript: [''],
            format: [this.selectedItem.format],
            difficulty: [this.selectedItem.difficulty],
            estEngagement: [this.selectedItem.estEngagement],
            estTimeInvestment: [this.selectedItem.estTimeInvestment],
            estMoneyInvestment: [this.selectedItem.estMoneyInvestment],
            estEnergyInvestment: [this.selectedItem.estEnergyInvestment],
            indexed: [this.selectedItem.indexed],
            id: [this.selectedItem.id],
            sequence: [this.selectedItem.sequence],
            userLevel: [this.selectedItem.userLevel],
            practiceRequired: ['Yes'],
            pubspeak: ['No'],
            ucInterviews: ['Yes'],
            ucPublicSpeaking: ['Yes'],
            ucTeaching: ['Yes'],
            ucSalesMarketing: ['Yes'],
            ucSocialNetworking: ['Yes'],
            notes: [this.selectedItem.notes],
        });

        this.scenarioVideoForm = this.formBuilder.group({
            fileInput: new FormControl(),
        });

        this.showItemDetail = true;

    }

    DeleteItem() {
        console.log("Deleting scenario " + this.selectedItem.name);

        // Delete the scenario
        var scenarioId= this.selectedItem.id;
        this.smService.DeleteScenario(scenarioId).subscribe(result => {

            if (result == null || result == false || result == undefined) {
                console.log("Unable to delete scenario.");
                this.alert = {
                    type: 'error',
                    message: 'Unable to define scenario.  Please try again.'
                };
            }

            // Remove scenario from list
            this.scenarios.splice(this.scenarios.indexOf(this.selectedItem), 1);

            // Update the table
            this.dataSource = new MatTableDataSource<Scenario>(this.scenarios);
            this.alert = {
                type: 'success',
                message: 'Updated scenario successfully.'
            };
            this.showAlert = true;

        }, error => console.error(error));

    }

    SaveItem() {
        this.showAlert = false;
        console.log("Saving scenario " + this.selectedItem.name);

        // Update the selectedItem with the form values
        this.selectedItem.name = this.scenarioDetailForm.value.name;
        this.selectedItem.description = this.scenarioDetailForm.value.description;
        this.selectedItem.scenarioIntent = this.scenarioDetailForm.value.scenarioIntent;
        this.selectedItem.transcript = this.scenarioDetailForm.value.transcript;
        this.selectedItem.title = this.scenarioDetailForm.value.title;
        this.selectedItem.format = this.scenarioDetailForm.value.format;
        this.selectedItem.difficulty = this.scenarioDetailForm.value.difficulty;
        this.selectedItem.estEngagement = this.scenarioDetailForm.value.estEngagement;
        this.selectedItem.estTimeInvestment = this.scenarioDetailForm.value.estTimeInvestment;
        this.selectedItem.estMoneyInvestment = this.scenarioDetailForm.value.estMoneyInvestment;
        this.selectedItem.estEnergyInvestment = this.scenarioDetailForm.value.estEnergyInvestment;
        this.selectedItem.indexed = this.scenarioDetailForm.value.indexed;
        this.selectedItem.scenarioType = this.selectedItem.format;
        this.selectedItem.numRelationships = 0;
        this.selectedItem.sequence = 1; // deprecated
        this.selectedItem.userLevel = this.scenarioDetailForm.value.userLevel;
        this.selectedItem.practiceRequired = this.GetSelectValue(this.scenarioDetailForm.value.practiceRequired);
        this.selectedItem.uC_PublicSpeaking = this.GetSelectValue(this.scenarioDetailForm.value.ucPublicSpeaking);
        this.selectedItem.uC_Interviews = this.GetSelectValue(this.scenarioDetailForm.value.ucInterviews);
        this.selectedItem.uC_Teaching_and_training = this.GetSelectValue(this.scenarioDetailForm.value.ucTeaching);
        this.selectedItem.uC_Sales_marketing = this.GetSelectValue(this.scenarioDetailForm.value.ucSalesMarketing);
        this.selectedItem.uC_Social_and_Networking = this.GetSelectValue(this.scenarioDetailForm.value.ucSocialNetworking);
        this.selectedItem.notes = this.scenarioDetailForm.value.notes;

        if (this.selectedItem.scenarioNum == undefined || this.selectedItem.scenarioNum == null) {
            this.selectedItem.scenarioNum = (this.scenarios.length + 5).toString();
        }

        var scenarioFile = null;
        if (this.selectedFiles != undefined) {
            scenarioFile = this.selectedFiles[0];
        }

        // Save the scenario
        this.smService.UpdateScenario(this.selectedItem, scenarioFile, this.thumbnailData).subscribe(result => {

            if (result == null) {
                console.log("Unable to update scenario.");
                this.alert = {
                    type: 'error',
                    message: 'Unable to update scenario.  Please try again.'
                };
            } else {

                this.alert = {
                    type: 'success',
                    message: 'Saved Scenario successfully.'
                };
            }

            // Add the new scenario to the list
            this.selectedItem = result;
            this.scenarios.push(result);

            this.ReloadScenarios();

            // Add the new scenario to the list
            /*this.selectedItem = result;
            this.scenarios.push(result);

            // Update the table
            this.dataSource = new MatTableDataSource<Scenario>(this.scenarios);*/
            this.showAlert = true;


        }, error => console.error(error));

    }


    SaveRubric() {
        console.log("Saving rubric " + this.selectedRubric.question);

        // Update the selectedRubric with the form values
        this.selectedRubric.intent = this.rubricDetailForm.value.intent;
        this.selectedRubric.level = this.rubricDetailForm.value.level;
        this.selectedRubric.number = this.rubricDetailForm.value.number;
        this.selectedRubric.priority = this.GetRubricPriorityValue(this.rubricDetailForm.value.priority);
        this.selectedRubric.question = this.rubricDetailForm.value.question;
        this.selectedRubric.questionFormat = this.rubricDetailForm.value.questionFormat;
        this.selectedRubric.type = this.rubricDetailForm.value.type;
        this.selectedRubric.scenarioId = this.selectedItem.id;
        this.selectedRubric.feedbackType = "Text";
        this.selectedRubric.handler = "UserResponseTextAnalysis";
        this.selectedRubric.scenarioName = this.selectedItem.name;
        this.selectedRubric.scenarioNum = Number.parseInt(this.selectedItem.scenarioNum);

        // Save the rubric
        this.smService.UpdateRubric(this.selectedRubric).subscribe(result => {

            if (result == null) {
                console.log("Unable to update rubric.");
                this.alert = {
                    type: 'error',
                    message: 'Unable to update rubric.  Please try again.'
                };
            }

            // Remove the old rubric from the list based on its id
            var itemindex = this.rubrics.findIndex(x => x.id == this.selectedRubric.id);

            if (itemindex != -1) {
                this.rubrics.splice(itemindex, 1);
            }

            // Add the new rubric to the list            
            this.selectedRubric = result.rubric;
            this.rubrics.push(this.selectedRubric);

            // sort the rubrics
            this.rubrics.sort((a, b) => a.number - b.number);

            // Update the table
            this.rubricsDataSource = new MatTableDataSource<Rubric>(this.rubrics);
            this.alert = {
                type: 'success',
                message: 'Updated rubric successfully.'
            };
            this.showAlert = true;


        }, error => console.error(error));

    }

    DeleteRubric() {
        console.log("Deleting rubric " + this.selectedRubric.name);

        // Delete the rubric
        var scenarioId = this.selectedItem.id;
        var rubricId = this.selectedRubric.id;
        this.smService.DeleteRubric(scenarioId, rubricId).subscribe(result => {

            if (result == null || result == false || result == undefined) {
                console.log("Unable to delete rubric.");
                this.alert = {
                    type: 'error',
                    message: 'Unable to delete rubric.  Please try again.'
                };
            }

            // Remove rubric from list
            this.rubrics.splice(this.rubrics.indexOf(this.selectedRubric), 1);

            // Update the table
            this.rubricsDataSource = new MatTableDataSource<Rubric>(this.rubrics);
            this.alert = {
                type: 'success',
                message: 'Deleted rubric successfully.'
            };
            this.showAlert = true;

        }, error => console.error(error));

    }


    private blobToFile(theBlob, fileName) {
        return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
    }

    SetThumbnail() {
        /*console.log("Setting Thumbnail for scenario video " + this.selectedItem.name);

        var filename = this.selectedItem.resourceURL;

        // Get the thumbnail for the video
        this.videoService.generateThumbnail(null, filename, false).then(thumbnailData => {
            var thumbnail = thumbnailData;
            console.log("Thumbnail : " + thumbnail);
        })*/

        // Send the scenario to the server with the thumbnail info included
        var scenarioId = this.selectedItem.id;
        this.smService.SetThumbnail(scenarioId, this.thumbnailData).subscribe(result => {

            if (result == null || result == undefined) {
                console.log("Unable to save thumbnail.");
                this.alert = {
                    type: 'error',
                    message: 'Unable to set thumbnail for scenario.  Please try again.'
                };
            }

            // Replace the item in the scenarios list
            this.scenarios[this.scenarios.indexOf(this.selectedItem)] = result;
            this.selectedItem = result;

            // Update the table
            this.dataSource = new MatTableDataSource<Scenario>(this.scenarios);
            this.showAlert = true;

        }, error => console.error(error));

    }



    IndexItem() {
        this.showAlert = false;
        console.log("Indexing scenario " + this.selectedItem.name);

        // Index the scenario
        var scenarioId = this.selectedItem.id;
        this.smService.IndexScenario(scenarioId).subscribe(result => {

            if (result == null || result == undefined) {
                console.log("Unable to index scenario.");
                this.alert = {
                    type: 'error',
                    message: 'Unable to index scenario.  Please try again.'
                };
            } else {
                this.alert = {
                    type: 'success',
                    message: 'Index Scenario successfully.'
                };
            }

            // Add the new scenario to the list
            this.selectedItem = result;
            this.scenarios.push(result);

            // Update the table
            this.dataSource = new MatTableDataSource<Scenario>(this.scenarios);
            this.showAlert = true;

            // For test purposes
            this.GetTranscript();

        }, error => console.error(error));

    }



    selectFiles(event: any): void {

        console.log("Called selectFiles()");

        this.message = [];
        this.progressInfos = [];
        this.selectedFileNames = [];
        this.selectedFiles = event.target.files;

        this.previews = [];
        this.hasPreview = false;
        this.showSelectedFile = false;

        if (this.selectedFiles && this.selectedFiles[0]) {
            const numberOfFiles = this.selectedFiles.length;
            for (let i = 0; i < numberOfFiles; i++) {
                const reader = new FileReader();

                reader.onload = (e: any) => {
                    console.log(e.target.result);
                    this.previews.push(e.target.result);
                    this.hasPreview = true;
                };

                reader.readAsDataURL(this.selectedFiles[i]);

                this.selectedFileNames.push(this.selectedFiles[i].name);
            }
            this.showSelectedFile = true;
        }
    }


    onFileSelected(event) {
        this.showAlert = false;
        this.showPreview = false;

        this.uploadfile = event.target.files[0];

        if (this.uploadfile) {

            // Create and store a file from the blob
            var filename = this.smService.GetCurrentUser().username + "_user_response_" + ".mp4";
            this.fileName = filename;
            this.displayFileName = this.uploadfile.name;

            this.videoFile = this.blobToFile(this.uploadfile, filename);
            this.showPreview = true;

            // Create an URL from the video file
            this.ProcessUploadRec(this.videoFile);

            // TODO - this is a hack to send the video preview url to the video viewer component
            this.type = this.videoPreviewUrl;

            this.selectedFileNames = [];
            this.selectedFiles = event.target.files;
            this.selectedFileNames.push(this.selectedFiles[0].name);


        } else {
            this.alert = {
                type: 'error',
                message: 'Error uploading file.  Please try again with a proper video file in .MP4 format.'
            };
            this.showAlert = true;

        }
    }

    private ProcessUploadRec(videoFile) {

        // Get the thumbnail for the video
        this.videoService.generateThumbnail(videoFile, null, true).then(thumbnailData => {
            this.thumbnailData = thumbnailData;
            console.log("Thumbnail Data: " + this.thumbnailData);
        })

        this.videoPreviewUrl = URL.createObjectURL(this.videoFile);

        this.smService.VideoSource.next(this.videoPreviewUrl);



    }

    public getScenarioThumbUrl(scenariorec) {

        // Get the thumbnail url for the scenario rec
        var thumbUrl = scenariorec.resourceThumbURL;
        if (thumbUrl == undefined || thumbUrl == null || thumbUrl == "") {
            thumbUrl = "assets/images/no_img_available.png";
        }
        //console.log("ThumbUrl: " + thumbUrl);
        return thumbUrl;
    }

    public UpdateScenarioURL() {

        var scenarioId = this.selectedItem.id;
        this.smService.UpdateScenarioURLs(scenarioId).subscribe(result => {

            this.alert = {
                type: 'success',
                message: 'Updated Scenario URLs successfully.'
            };
            this.showAlert = true;



        }, error => console.error(error));
    }

    public ClearResources() {

        var scenarioId = this.selectedItem.id;
        this.smService.ClearScenarioResources(scenarioId).subscribe(result => {

            this.alert = {
                type: 'success',
                message: 'Cleared Scenario Resources successfully.'
            };
            this.showAlert = true;

        }, error => console.error(error));
    }

}

            
