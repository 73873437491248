<div class="profilePanel">

    <div class="">

        <div class="flex flex-col w-full bg-slate-50 pb-4">
            <ng-container>
                <div class="flex flex-row items-start">
                    <div>
                        <div class="text-xl md:text-2xl font-semibold tracking-tight leading-7 md:leading-snug truncate mb-2">{{PageTitle}}</div>
                    </div>
                </div>
            </ng-container>

            <div class="flex flex-auto w-full">

                <!-- Profile Overview Panel -->
                <div class="flex flex-col mt-4 bg-card shadow-sm rounded-lg overflow-hidden w-50 bg-blue-50">

                    <div class="p-4 flex flex-row">

                        <div class="flex min-w-0 flex-col">
                            <div class="font-semibold">About this Profile</div>
                            <div class="">We believe everyone is unique.  Cogsolo is a highly personalized system.  We use this profile to determine the best scenarios and appropriate feedback for you.  You can certainly modify these inputs at any time.</div>
                        </div>
                    </div>
                    
                </div>

            </div>

            <!-- Basic Info Panel -->
            <div class="flex flex-col w-full mt-4 mr-1 rounded-lg" *ngIf="ShowProfileForm()">

                <form id="profileDetailForm" [formGroup]="profileDetailForm" class="w-full">

                    <!-- Basic Info -->
                    <mat-expansion-panel [expanded]="true" class="rounded-lg mb-4 w-50" *ngIf="!isOnboarding || showBasicInfo">
                        <mat-expansion-panel-header class="expansionPanel overflow-hidden w-full">
                            <mat-panel-title>

                                <p class="sectionHeader">Basic Info</p>

                            </mat-panel-title>
                            <mat-panel-description>

                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div class="flex flex-col shadow-sm overflow-hidden w-full">
                            <div class="responseSectionPanel">
                                <div class="flex-auto grid grid-cols-6">

                                    <div class="col-span-5 sm:col-span-5 flex flex-col items-left justify-top">
                                        <p class="">Your profile attributes are unique to you.  These attributes help the CogSolo system determine <em>the next best </em> recommendations for you.</p>
                                    </div>

                                </div>

                            </div>
                            <mat-divider></mat-divider>
                            <div class="p-4 w-full">

                                <!-- Industry field -->
                                <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                    <!--<span class="font-semibold mb-1 gt-xs:text-right gt-xs:min-w-32 gt-xs:mr-4 gt-xs:mb-0">Industry</span>-->
                                    <mat-form-field class="fuse-mat-dense flex-auto">
                                        <mat-label class="text-lg font-semibold mt-5">What is your industry?</mat-label>
                                        <input id="'industry'"
                                               matInput
                                               [formControlName]="'industry'">
                                    </mat-form-field>
                                </div>


                                <!-- Role field -->
                                <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                    <!--<span class="font-semibold mb-1 gt-xs:text-right gt-xs:min-w-32 gt-xs:mr-4 gt-xs:mb-0">Job Function</span>-->
                                    <mat-form-field class="fuse-mat-dense flex-auto">
                                        <mat-label class="text-lg font-semibold mt-5">What is your job function or role?</mat-label>
                                        <input id="'jobfunction'"
                                               matInput
                                               [formControlName]="'jobfunction'">
                                    </mat-form-field>
                                </div>


                                <!-- Personality Type -->
                                <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                    <!--<span class="font-semibold mb-1 gt-xs:text-right gt-xs:min-w-32 gt-xs:mr-4 gt-xs:mb-0">Personality Type</span>-->
                                    <mat-form-field class="fuse-mat-dense flex-auto">
                                        <mat-label class="text-lg font-semibold mt-5">Choose a personality type that you think describes you:</mat-label>
                                        <mat-select formControlName="personalityType">
                                            <mat-option value="Introvert">Introvert - I tend not to speak up enough</mat-option>
                                            <mat-option value="Extrovert">Extrovert - I'm comfortable speaking up</mat-option>
                                            <mat-option value="Ambivert">Ambivert - I'm somewhere in between</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <!-- Prior Experience -->
                                <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline mt-4">
                                    <!--<span class="font-semibold mb-1 gt-xs:text-right gt-xs:min-w-32 gt-xs:mr-4 gt-xs:mb-0">Years of Working Experience</span>-->
                                    <mat-label class="text-lg font-semibold mt-2">How many years of work experience do you have?</mat-label>
                                    <div class="text-md">This could impact the choice of recommended scenarios.</div>
                                    <div class="fuse-mat-dense flex-auto">
                                        <mat-slider min="1" max="100" step="1" showTickMarks discrete [displayWith]="formatLabel" class="slider">
                                            <input matSliderThumb formControlName="priorExperience">
                                        </mat-slider><span class="text-sm">Currently: {{profiledetail.engPriorExperience}} Years</span>
                                    </div>
                                </div>

                                <!-- Judging Preference -->
                                <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline mt-4">
                                    <!--<span class="font-semibold mb-1 gt-xs:text-right gt-xs:min-w-32 gt-xs:mr-4 gt-xs:mb-0">Judging Preference</span>-->
                                    <mat-label class="mt-2">
                                        <div class="text-lg font-semibold">How strictly should Cogsolo evaluate you?</div>
                                        <div class="text-md">This will impact how Cogsolo scores your responses.</div>
                                    </mat-label>

                                    <mat-form-field class="fuse-mat-dense flex-auto">
                                        <mat-select formControlName="judgingType">
                                            <mat-option value="LenientJudge">Lenient - Cogsolo's AI judge will be relatively lenient and gentle with its feedback.</mat-option>
                                            <mat-option value="NeutralJudge">Neutral - This AI judge will be neutral giving some gentle but mostly direct feedback.</mat-option>
                                            <mat-option value="ToughJudge">Tough - This judge will give feedback that's very direct.</mat-option>
                                            <mat-option value="DemandingJudge">Demanding - This judge will really push you to excel.  Don't expect any niceties.</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>


                            </div>

                            <mat-divider></mat-divider>

                            <div class="flex items-center justify-left mt-4 mb-4">
                                <button class="px-2 ml-3" *ngIf="showContinueButton && isOnboarding"
                                        mat-flat-button
                                        type="button"
                                        [color]="'primary'"
                                        (click)="BasicContinue()">
                                    <mat-icon>cloud_upload</mat-icon><span class="ml-2">Step 1 of 3 - Save & Continue</span>
                                </button>

                            </div>
                        </div>


                    </mat-expansion-panel>


                    <!-- Usage Modes -->
                    <mat-expansion-panel [expanded]="true" class="rounded-lg mb-4 w-50" *ngIf="!isOnboarding || showUsageModes">
                        <mat-expansion-panel-header class="expansionPanel overflow-hidden w-full">
                            <mat-panel-title>

                                <p class="sectionHeader">Usage</p>

                            </mat-panel-title>
                            <mat-panel-description>

                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div class="flex flex-col shadow-sm overflow-hidden w-full">
                            <div class="responseSectionPanel">
                                <div class="flex-auto grid grid-cols-6">

                                    <div class="col-span-5 sm:col-span-5 flex flex-col items-left justify-top">
                                        <p class="">How you use the lessons you learn is vital to building long-term skills.  This helps Cogsolo keep you on track with the right level of difficulty and practice.</p>
                                    </div>

                                </div>

                            </div>
                            <mat-divider></mat-divider>
                            <div class="p-4 w-full">

                                <!-- Use Cases -->
                                <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline mt-4">
                                    <!--<span class="font-semibold mb-1 gt-xs:text-right gt-xs:min-w-32 gt-xs:mr-4 gt-xs:mb-0">Judging Preference</span>-->
                                    <mat-label class="mt-2">
                                        <div class="text-lg font-semibold">What is your primary motivation for using Cogsolo?</div>
                                        <div class="text-md">Don't worry, many of our lessons apply to multiple use cases.</div>
                                    </mat-label>
                                    <mat-form-field class="fuse-mat-dense flex-auto">
                                        <mat-select formControlName="primaryMotivation">
                                            <mat-option value="CareerAdvancement">Career Advancement: Ability to demonstrate skills and get an edge to advance in my career.</mat-option>
                                            <mat-option value="SkillBuilding">Skill Building: Practical skills I can use on the job day to day.</mat-option>
                                            <mat-option value="TeamLeadership">Team Leadership: Lessons to better interact with and lead other team members.</mat-option>
                                            <mat-option value="ProfessionalPresence">Professional Presence: Lessons to better interact with executives and clients.</mat-option>
                                            <mat-option value="PersonalGrowth">Personal Growth: Building my own confidence and reducing anxiety.</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <!-- Time Available for Program -->
                                <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                    <!--<span class="font-semibold mb-1 gt-xs:text-right gt-xs:min-w-32 gt-xs:mr-4 gt-xs:mb-0">Total Time Available for Program</span>-->
                                    <mat-label class="mt-2">
                                        <div class="text-lg font-semibold">How much time can you allocate?</div>
                                        <div class="text-md">This will impact the pace and level of difficulty of your recommended lessons.</div>
                                    </mat-label>
                                    <mat-form-field class="fuse-mat-dense flex-auto">
                                        <mat-select formControlName="timeAvailableForProgram">
                                            <mat-option value="Bootcamp">Bootcamp: 0-3 Days - I need to train fast</mat-option>
                                            <mat-option value="Workshop">Workshop: 4-10 Days - I want to train at a reasonable pace</mat-option>
                                            <mat-option value="Ongoing">Ongoing: 11-30 Days - I have more time</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <!-- Time Available per Day -->
                                <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                    <!--<span class="font-semibold mb-1 gt-xs:text-right gt-xs:min-w-32 gt-xs:mr-4 gt-xs:mb-0">Minutes Available per Day</span>-->
                                    <mat-label class="text-lg font-semibold mt-2">How much time can you devote daily?</mat-label>
                                    <div class="text-md">This will impact the pace and level of difficulty of your recommended lessons.</div>
                                    <div class="fuse-mat-dense flex-auto">
                                        <mat-slider min="10" max="1000" step="5" showTickMarks discrete [displayWith]="formatLabel" class="slider">
                                            <input matSliderThumb formControlName="timeAvailablePerDay">
                                        </mat-slider><span class="text-sm">Currently: {{profiledetail.engTimePerScenario}} Min / Day</span>
                                    </div>
                                </div>


                            </div>

                            <mat-divider></mat-divider>

                            <div class="flex items-center justify-left mt-4 mb-4">
                                <button class="px-2 ml-3" *ngIf="showContinueButton && isOnboarding"
                                        mat-flat-button
                                        type="button"
                                        [color]="'primary'"
                                        (click)="UsageModesContinue()">
                                    <mat-icon>cloud_upload</mat-icon><span class="ml-2">Step 2 of 3 - Save & Continue</span>
                                </button>

                            </div>
                        </div>


                    </mat-expansion-panel>


                    <!-- Learning Objectives -->
                    <!--<mat-expansion-panel [expanded]="true" class="rounded-lg mb-4 w-50" *ngIf="!isOnboarding || showLearningObjectives">
    <mat-expansion-panel-header class="expansionPanel overflow-hidden w-full">
        <mat-panel-title>

            <p class="sectionHeader">Learning Objectives</p>

        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="flex flex-col shadow-sm overflow-hidden w-full">
        <div class="responseSectionPanel">
            <div class="flex-auto grid grid-cols-6">

                <div class="col-span-5 sm:col-span-5 flex flex-col items-left justify-top">
                    <p class="">Your learning objectives helps CogSolo determine which parts of the Program are most relevant to you.</p>
                </div>

            </div>

        </div>
        <mat-divider></mat-divider>
        <div class="p-8 pb-4 w-full">
    -->
                    <!-- coverageWeight -->
                    <!--        <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                <span class="font-semibold mb-1 gt-xs:text-right gt-xs:min-w-32 gt-xs:mr-4 gt-xs:mb-0">Coverage</span>
                <span class="text-md mb-1 gt-xs:text-right gt-xs:min-w-32 gt-xs:mr-4 gt-xs:mb-0">Provides more variety of scenarios while ensuring all major skill areas within the program are covered.</span>
                <div class="fuse-mat-dense flex-auto">
                    <mat-slider min="0" max="1" step="0.1" showTickMarks discrete [displayWith]="formatLabel" class="slider">
                        <input matSliderThumb formControlName="coverageWeight">
                    </mat-slider><span class="text-sm">Currently: {{recconfig.coverageWeight}}</span>
                </div>
            </div>
    -->
                    <!-- masteryWeight -->
                    <!--        <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline mt-4">
                        <span class="font-semibold mb-1 gt-xs:text-right gt-xs:min-w-32 gt-xs:mr-4 gt-xs:mb-0">Mastery</span>
                        <span class="text-md mb-1 gt-xs:text-right gt-xs:min-w-32 gt-xs:mr-4 gt-xs:mb-0">Provides more depth of scenarios in certain skill areas.  Increasing the mastery value will add more number of scenarios to your program.</span>
                        <div class="fuse-mat-dense flex-auto">
                            <mat-slider min="0" max="1" step="0.1" showTickMarks discrete [displayWith]="formatLabel" class="slider">
                                <input matSliderThumb formControlName="masteryWeight">
                            </mat-slider><span class="text-sm">Currently: {{recconfig.masteryWeight}}</span>
                        </div>
                    </div>


        </div>

                        <mat-divider></mat-divider>

                        <div class="flex items-center justify-left mt-4 mb-4">
                            <button class="px-2 ml-3" *ngIf="showContinueButton && isOnboarding"
                                    mat-flat-button
                                    type="button"
                                    [color]="'primary'"
                                    (click)="LearningObjectivesContinue()">
                                <mat-icon>East</mat-icon><span class="ml-2">Save & Continue</span>
                            </button>

                        </div>
    </div>


                        </mat-expansion-panel>
    -->

                    <!-- Aspects of Interest -->
                    <mat-expansion-panel [expanded]="true" class="rounded-lg mb-4 w-50" *ngIf="!isOnboarding || showAspectsOfInterest">
                        <mat-expansion-panel-header class="expansionPanel overflow-hidden w-full">
                            <mat-panel-title>

                                <p class="sectionHeader">Aspects of Interest</p>

                            </mat-panel-title>
                            <mat-panel-description>

                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div class="flex flex-col shadow-sm overflow-hidden w-full">
                            <div class="responseSectionPanel">
                                <div class="flex-auto grid grid-cols-6">

                                    <div class="col-span-5 sm:col-span-5 flex flex-col items-left justify-top">
                                        <p class="">Knowing your specific interests helps CogSolo determine which scenarios and lessons will be most relevant to you.
                                            0 = Not Interested, 1 = Very Interested.  You can adjust these at any time.
                                        </p>
                                    </div>

                                </div>

                            </div>
                            <mat-divider></mat-divider>
                            <div class="p-4 w-full">

                                <!-- Aspect Groups -->
                                <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">


                                    <div *ngFor="let pref of aspectPreferences; let i = index">
                                        <mat-label class="text-lg font-semibold mt-5">{{pref.aspectGroupName}}</mat-label>
                                        <div class="text-sm">{{pref.aspectGroupDescription}}</div>
                                        <div class="text-sm">Setting: {{pref.preferenceLevel}}</div>
                                        <mat-slider showTickMarks discrete [displayWith]="formatLabel" class="slider" min="0" max="{{ 1 }}" step="0.1">
                                            <input matSliderThumb formControlName={{pref.aspectGroupName}}>
                                        </mat-slider>
                                    </div>


                                    <!--<ng-container *ngFor="let pref of aspectPreferences; let i = index;">
                                        <mat-slider (input)="onInputChange($event)" min="0" max="{{ 100 }}" step="1" value="1" id="{{ 'myslider' + i }}"></mat-slider>
                                    </ng-container>-->

                                    <!--<div class="fuse-mat-dense flex-auto">
                                        <mat-slider min="10" max="1000" step="5" showTickMarks discrete [displayWith]="formatLabel" class="slider">
                                            <input matSliderThumb formControlName="timeAvailablePerDay">
                                        </mat-slider><span class="text-sm">Currently: {{profiledetail.engTimePerScenario}} Min / Day</span>
                                    </div>-->

                                </div>


                            </div>

                            <mat-divider></mat-divider>

                            <div class="flex items-center justify-left mt-4 mb-4">
                                <button class="px-2 ml-3" *ngIf="showContinueButton && isOnboarding"
                                        mat-flat-button
                                        type="button"
                                        [color]="'primary'"
                                        (click)="AspectsOfInterestContinue()">
                                    <mat-icon>cloud_upload</mat-icon><span class="ml-2">Step 3 of 3 - Save & Create Program</span>
                                </button>

                            </div>
                        </div>


                    </mat-expansion-panel>

                    <mat-divider></mat-divider>

                    <div class="flex items-center justify-left mt-8 mb-8" *ngIf="!isOnboarding || showToolbar">
                        <button class="px-6 ml-3"
                                mat-flat-button
                                type="button"
                                [color]="'primary'"
                                (click)="SaveProfile()">
                            <mat-icon>cloud_upload</mat-icon><span class="ml-2">Save</span>
                        </button>

                        <!-- Alerts -->
                        <fuse-alert class="ml-4"
                                    *ngIf="showAlert"
                                    [appearance]="'outline'"
                                    [showIcon]="true"
                                    [type]="alert.type">
                            <img src="../../../../../../assets/images/avatars/cogsolo__mobile_logo_1.png" mr-4 class="cogsologuy" fuseAlertIcon rounded-full />
                            {{alert.message}}
                        </fuse-alert>

                        <fuse-alert class="ml-4"
                                    *ngIf="showSavedAlert"
                                    [appearance]="'outline'"
                                    [showIcon]="true"
                                    [type]="savedalert.type">
                            <img src="../../../../../../assets/images/avatars/cogsolo__mobile_logo_1.png" mr-4 class="cogsologuy" fuseAlertIcon rounded-full />
                            {{savedalert.message}}
                        </fuse-alert>

                    </div>

                </form>

            </div>

            <!-- Profile Image Panel -->
            <div class="flex flex-col w-full" *ngIf="!isOnboarding && showAvatarPanel">

                <mat-expansion-panel [expanded]="true" class="rounded-lg mb-4 w-50" *ngIf="!isOnboarding || showAspectsOfInterest">
                    <mat-expansion-panel-header class="expansionPanel overflow-hidden w-full">
                        <mat-panel-title>

                            <div class="sectionHeader w-full">Personal Avatar (optional)</div>

                        </mat-panel-title>
                        <mat-panel-description>

                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="flex flex-col shadow-sm overflow-hidden w-full">
                        <div class="responseSectionPanel">
                            <div class="flex-auto grid grid-cols-6">

                                <div class="col-span-5 sm:col-span-5 flex flex-col items-left justify-top">
                                    <p class="">Upload an image to use for your profile and avatar.  Be sure to smile!</p>
                                </div>

                            </div>

                        </div>
                        <mat-divider></mat-divider>
                        <div class="p-4 w-full">

                            <!-- Profile Image -->
                            <div class="p-4 flex flex-row">

                                <div class="flex-initial" *ngIf="hasProfileImage && !hasPreview">
                                    <img [src]="profiledetail.imageURL" class="profileImage" />
                                </div>

                                <div class="flex-initial" *ngIf="!hasProfileImage && !hasPreview">
                                    <img src="assets/images/avatars/Avatar_placeholder.png" class="w-full h-full rounded-full profileImage" />
                                </div>

                                <div *ngIf="hasPreview">
                                    <img *ngFor="let preview of previews" [src]="preview" class="rounded-full preview" />
                                </div>

                                <div class="grow">

                                    <form id="profileImageForm" [formGroup]="profileImageForm" class="w-full">

                                        <div class="flex-auto">
                                            <div class="formFieldClass w-full">

                                                <div class="flex-initial">
                                                    <button class="px-6 ml-3 w-40"
                                                            mat-flat-button
                                                            [color]="'primary'"
                                                            type="button"
                                                            (click)="fileInput.click()">
                                                        <mat-icon>add_photo_alternate</mat-icon><span class="ml-2">Change Image</span>
                                                    </button>


                                                    <button class="px-6 ml-3 w-40"
                                                            mat-flat-button
                                                            [color]="'primary'"
                                                            type="button"
                                                            [disabled]="!selectedFiles"
                                                            (click)="uploadFiles()">
                                                        <mat-icon>upload_file</mat-icon><span class="ml-2">Upload Image</span>
                                                    </button>


                                                    <input type="file" hidden
                                                           #fileInput
                                                           id="fileInput"
                                                           name="fileInput"
                                                           formControlName="fileInput"
                                                           accept="image/*"
                                                           (change)="selectFiles($event)" />

                                                </div>

                                            </div>


                                            <div *ngIf="message.length" class="message">
                                                <ul *ngFor="let msg of message; let i = index">
                                                    <li>{{ msg }}</li>
                                                </ul>
                                            </div>

                                        </div>
                                    </form>

                                </div>

                            </div>

                        </div>

                    </div>


                </mat-expansion-panel>



            </div>

        </div>
    </div>

</div>
