import { NgModule } from '@angular/core';
import { ProfileDetailComponent } from 'app/modules/userprofile/profiledetail/profiledetail.component';
import { SharedModule } from '../../../shared/shared.module';
import { ProfileDetailRoutingModule } from './profiledetail.routing';

@NgModule({
    declarations: [
        ProfileDetailComponent
    ],
    imports     : [
        SharedModule,
        ProfileDetailRoutingModule
    ],
    exports: [
        ProfileDetailComponent,
    ]
})

export class ProfileDetailModule
{
}
