import { IScoringSummary } from './IScoringSummary';


export class ScoringSummary implements IScoringSummary {
    engFactorName: string;
    displayName: string;
    points: number;
    description: string;
    id: string;
    name: string;
}
