<div class="text-2xl md:text-5xl font-semibold tracking-tight leading-7 md:leading-snug truncate">{{PageTitle}}</div>

<!-- Main Scenarios List -->
<div >

    <div class="profilePanel mx-auto w-full h-full">

        <div class="bg-card">
            <div class="flex flex-col bg-slate-50 pb-8">
                <ng-container>

                    <div class="flex flex-row items-center mt-4 mb-4">
                        <div class="toolbarRow">
                            <mat-form-field>
                                <mat-label>Filter</mat-label>
                                <mat-select [(ngModel)]="filterType" (ngModelChange)="onFilterChange(filterType)">
                                    <mat-option *ngFor="let type of selectFilterType" [value]="type.value">
                                        {{type.text}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <button class="px-3 mx-3 mb-5"
                                    mat-flat-button
                                    [color]="'primary'" (click)="AddNewItem()">
                                <mat-icon>add</mat-icon>
                                <span>Add New Scenario</span>
                            </button>

                            <div><span class="">{{filteredscenariosLength}} Total Items</span></div>
                        </div>

                    </div>
                </ng-container>

                <div class="flex flex-auto grid grid-cols-6 scenariolist w-full" *ngIf="!showItemDetail">

                    <div class="flex flex-col col-span-6 p-2 bg-card shadow-sm rounded-md">
                        <div class="flex items-start justify-between">
                            <div class="text-lg font-bold tracking-tight leading-6 truncate mb-2">Scenarios</div>
                        </div>
                        <div class="">
                            <mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                                <ng-container matColumnDef="ASLid">
                                    <mat-header-cell *matHeaderCellDef> # </mat-header-cell>
                                    <mat-cell *matCellDef="let element"><div class="flex flex-row font-semibold text-2xl">{{element.scenarioNum}}</div></mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="ASLname">
                                    <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                                    <mat-cell *matCellDef="let element">{{element.name}} </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="ASLdescription">
                                    <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
                                    <!--<mat-cell *matCellDef="let element"></mat-cell>-->
                                    <mat-cell *matCellDef="let element">
                                        <div class="flex flex-col items-start">
                                            <div class="mb-2 text-sm">Id {{element.id}}</div>
                                            <div class="mb-2 text-sm">Desc: {{element.description}}</div>
                                            <div class="text-sm">Intent: {{element.scenarioIntent}}</div>
                                        </div>
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="ASLresourceURL">
                                    <mat-header-cell *matHeaderCellDef> URL </mat-header-cell>
                                    <mat-cell *matCellDef="let element">
                                        <div class="flex flex-col items-start">
                                            <img class="scenarioMicroThumb" [src]="getScenarioThumbUrl(element)" />
                                            <div class="mt-1 mb-2 text-sm text-center">Created: {{element.timestamp | date }}</div>
                                            <a [href]="element.resourceURL" class="text-sm">
                                                {{element.resourceURL}}
                                            </a>
                                        </div>
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="ASLformat">
                                    <mat-header-cell *matHeaderCellDef> Format </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.format}} </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="ASLindexed">
                                    <mat-header-cell *matHeaderCellDef> Indexed </mat-header-cell>
                                    <mat-cell *matCellDef="let element">
                                        <div *ngIf="element.indexed!=true" class="text-red-600 font-semibold">
                                            <mat-icon class="red-icon">warning</mat-icon>
                                        </div>
                                        <div *ngIf="element.indexed==true" class="text-green-600 font-semibold">
                                            <mat-icon class="green-icon">check</mat-icon> {{element.indexed}}
                                        </div>
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="ASLnumRelationships">
                                    <mat-header-cell *matHeaderCellDef> # Edges </mat-header-cell>
                                    <mat-cell *matCellDef="let element">
                                        <div *ngIf="element.numRelationships==0" class="text-red-600 font-semibold">
                                            <mat-icon class="red-icon">warning</mat-icon> {{element.numRelationships}}
                                        </div>
                                        <div *ngIf="element.numRelationships>0">
                                            {{element.numRelationships}}
                                        </div>
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="ASLhasTranscript">
                                    <mat-header-cell *matHeaderCellDef> Transcript?</mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.hasTranscript}} </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="ASLrubrics">
                                    <mat-header-cell *matHeaderCellDef> # Rubrics</mat-header-cell>
                                    <mat-cell *matCellDef="let element">
                                        <div *ngIf="element.numRubrics==0" class="text-red-600 font-semibold">
                                            <mat-icon class="red-icon">warning</mat-icon> {{element.numRubrics}}
                                        </div>
                                        <div *ngIf="element.numRubrics>0">
                                            {{element.numRubrics}}
                                        </div>
                                    </mat-cell>
                                </ng-container>

                                <!-- Detail Column -->
                                <ng-container matColumnDef="ASLdetails">
                                    <mat-header-cell *matHeaderCellDef> Details </mat-header-cell>
                                    <mat-cell *matCellDef="let element">
                                        <button class="px-3 mx-3"
                                                mat-flat-button
                                                [color]="'primary'" (click)="ShowItemDetail(element)">
                                            <span>Details</span>
                                        </button>
                                    </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumns;">
                                </mat-row>
                            </mat-table>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>

</div>

<!-- Scenario Details -->
<ng-container #ItemDetail *ngIf="showItemDetail">

    <div class="flex flex-auto">
        <form id="scenarioDetailForm" [formGroup]="scenarioDetailForm" class="w-full">

            <div class="flex flex-col mt-8 bg-card shadow rounded overflow-hidden mr-2">

                <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                    <span class="text-2xl font-semibold mx-4 mt-4 text-red-800">{{selectedItem.name}}</span>
                </div>

                <mat-expansion-panel [expanded]="true" class="mx-2 mt-8">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Edit Basic Info
                        </mat-panel-title>
                        <mat-panel-description>

                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <!-- Edit Scenario Form -->
                    <div class="p-2 bg-zinc-100" *ngIf="showItemDetailForm">

                        <div class="flex-auto grid grid-cols-6">

                            <div class="col-span-2 sm:col-span-2 flex flex-col items-left justify-top p-4">
                                <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                    <mat-form-field class="">
                                        <mat-label class="font-semibold mt-5">Name</mat-label>
                                        <input id="name"
                                               matInput
                                               [formControlName]="'name'">
                                    </mat-form-field>
                                </div>

                                <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                    <mat-form-field class="">
                                        <mat-label class="font-semibold mt-5">Title</mat-label>
                                        <input id="title"
                                               matInput
                                               [formControlName]="'title'">
                                    </mat-form-field>
                                </div>

                                <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                    <mat-form-field class="">
                                        <mat-label class="font-semibold mt-5">Description</mat-label>
                                        <input id="description"
                                               matInput
                                               [formControlName]="'description'">
                                    </mat-form-field>
                                </div>

                                <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                    <mat-form-field class="">
                                        <mat-label class="font-semibold mt-5">Intent</mat-label>
                                        <input id="scenarioIntent"
                                               matInput
                                               [formControlName]="'scenarioIntent'">
                                    </mat-form-field>
                                </div>

                                <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                    <mat-form-field class="">
                                        <mat-label class="font-semibold mt-5">Transcript</mat-label>
                                        <textarea id="transcript"
                                                  matInput
                                                  rows="12"
                                                  [formControlName]="'transcript'"></textarea>
                                    </mat-form-field>
                                </div>

                                <div class="flex flex-col items-start">
                                    <img class="scenarioThumb" [src]="getScenarioThumbUrl(selectedItem)" />
                                    <div class="mt-1 mb-2 text-sm text-center">Created: {{selectedItem.timestamp | date }}</div>
                                </div>


                            </div>

                            <div class="col-span-2 sm:col-span-2 flex flex-col items-left justify-top p-4">
                                <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                    <mat-form-field class="">
                                        <mat-label class="font-semibold mt-5">Format</mat-label>
                                        <mat-select formControlName="format">
                                            <mat-option value="Scenario">Full Scenario</mat-option>
                                            <mat-option value="Tip">Short Tip</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                    <mat-form-field class="">
                                        <mat-label class="font-semibold mt-5">Difficulty</mat-label>
                                        <mat-select formControlName="difficulty">
                                            <mat-option value="Tip">Tip</mat-option>
                                            <mat-option value="VeryBasic">Very Basic</mat-option>
                                            <mat-option value="Basic">Basic</mat-option>
                                            <mat-option value="MediumLow">Medium Low</mat-option>
                                            <mat-option value="Intermediate">Intermediate</mat-option>
                                            <mat-option value="MediumHigh">Medium High</mat-option>
                                            <mat-option value="Advanced">Advanced</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                    <span class="font-semibold mt-5 mb-1 gt-xs:text-right gt-xs:min-w-32 gt-xs:mr-4 gt-xs:mb-0">Est Engagement</span>
                                    <div class="fuse-mat-dense flex-auto">
                                        <mat-slider min="1" max="1000" step="5" showTickMarks discrete class="slider">
                                            <input matSliderThumb formControlName="estEngagement">
                                        </mat-slider>Currently: ${{selectedItem.estEngagement}}
                                    </div>
                                </div>

                                <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                    <span class="font-semibold mt-5 mb-1 gt-xs:text-right gt-xs:min-w-32 gt-xs:mr-4 gt-xs:mb-0">Est Time Investment</span>
                                    <div class="fuse-mat-dense flex-auto">
                                        <mat-slider min="0" max="1000" step="5" showTickMarks discrete class="slider">
                                            <input matSliderThumb formControlName="estTimeInvestment">
                                        </mat-slider>Currently: {{selectedItem.estTimeInvestment}} Hours
                                    </div>
                                </div>

                                <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                    <span class="font-semibold mt-5 mb-1 gt-xs:text-right gt-xs:min-w-32 gt-xs:mr-4 gt-xs:mb-0">Est Money Investment</span>
                                    <div class="fuse-mat-dense flex-auto">
                                        <mat-slider min="0" max="500" step="1" showTickMarks discrete class="slider">
                                            <input matSliderThumb formControlName="estMoneyInvestment">
                                        </mat-slider>Currently: {{selectedItem.estMoneyInvestment}}
                                    </div>
                                </div>

                                <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                    <span class="font-semibold mt-5 mb-1 gt-xs:text-right gt-xs:min-w-32 gt-xs:mr-4 gt-xs:mb-0">Est Energy Investment</span>
                                    <div class="fuse-mat-dense flex-auto">
                                        <mat-slider min="0" max="50" step="1" showTickMarks discrete class="slider">
                                            <input matSliderThumb formControlName="estEnergyInvestment">
                                        </mat-slider>Currently: {{selectedItem.estEnergyInvestment}}
                                    </div>
                                </div>

                            </div>

                            <div class="col-span-2 sm:col-span-2 flex flex-col items-left justify-top p-4">
                                <!--<div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                    <mat-form-field class="">
                                        <mat-label class="font-semibold mt-5">Sequence</mat-label>
                                        <input id="sequence"
                                               matInput
                                               [formControlName]="'sequence'">
                                    </mat-form-field>
                                </div>-->

                                <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                    <mat-form-field class="">
                                        <mat-label class="font-semibold mt-5">User Level</mat-label>
                                        <mat-select formControlName="userLevel">
                                            <mat-option value="Basic">Basic</mat-option>
                                            <mat-option value="Intermediate">Intermediate</mat-option>
                                            <mat-option value="Advanced">Advanced</mat-option>
                                            <mat-option value="All">All</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                    <mat-form-field class="">
                                        <mat-label class="font-semibold mt-5">Practice Required</mat-label>
                                        <mat-select formControlName="practiceRequired">
                                            <mat-option value="Yes">Yes</mat-option>
                                            <mat-option value="No">No</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                    <mat-form-field class="w-full">
                                        <mat-label class="font-semibold mt-5">Public Speaking (Use Case)</mat-label>
                                        <mat-select formControlName="ucPublicSpeaking">
                                            <mat-option value="Yes">Yes</mat-option>
                                            <mat-option value="No">No</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                    <mat-form-field class="w-full">
                                        <mat-label class="font-semibold mt-5">Interviews (Use Case)</mat-label>
                                        <mat-select formControlName="ucInterviews">
                                            <mat-option value="Yes">Yes</mat-option>
                                            <mat-option value="No">No</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                    <mat-form-field class="w-full">
                                        <mat-label class="font-semibold mt-5">Teaching (Use Case)</mat-label>
                                        <mat-select formControlName="ucTeaching">
                                            <mat-option value="Yes">Yes</mat-option>
                                            <mat-option value="No">No</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                    <mat-form-field class="w-full">
                                        <mat-label class="font-semibold mt-5">Sales and Marketing (Use Case)</mat-label>
                                        <mat-select formControlName="ucSalesMarketing">
                                            <mat-option value="Yes">Yes</mat-option>
                                            <mat-option value="No">No</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                    <mat-form-field class="w-full">
                                        <mat-label class="font-semibold mt-5">Social and Networking (Use Case)</mat-label>
                                        <mat-select formControlName="ucSocialNetworking">
                                            <mat-option value="Yes">Yes</mat-option>
                                            <mat-option value="No">No</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                    <mat-form-field class="w-full">
                                        <mat-label class="font-semibold mt-5">Notes</mat-label>
                                        <input id="notes"
                                               matInput
                                               [formControlName]="'notes'">
                                    </mat-form-field>
                                </div>

                            </div>
                        </div>

                    </div>

                    <mat-divider></mat-divider>

                </mat-expansion-panel>

                <mat-expansion-panel [expanded]="false" class="mx-2 mt-8">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Uploads
                        </mat-panel-title>
                        <mat-panel-description>

                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <!-- Scenario File Uploader -->
                    <ng-template [ngTemplateOutlet]="ScenarioUpload"></ng-template>
                    <mat-divider></mat-divider>

                </mat-expansion-panel>

                <mat-expansion-panel [expanded]="false" class="mx-2 mt-8">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Indexing
                        </mat-panel-title>
                        <mat-panel-description>

                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <!-- Scenario File Indexer -->
                    <ng-template [ngTemplateOutlet]="ScenarioIndexer"></ng-template>
                    <mat-divider></mat-divider>

                </mat-expansion-panel>

                <mat-expansion-panel [expanded]="false" class="mx-2 mt-8">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Training
                        </mat-panel-title>
                        <mat-panel-description>

                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <!-- Train Scenario -->
                    <ng-template [ngTemplateOutlet]="ScenarioTrainer"></ng-template>

                    <mat-divider></mat-divider>

                </mat-expansion-panel>

                <!-- Form Toolbar -->
                <div class="flex items-center justify-left mt-8 mb-8">
                    <button class="px-6 ml-3"
                            mat-flat-button
                            type="button"
                            [color]="'primary'"
                            (click)="SaveItem()">
                        <mat-icon>save</mat-icon>
                        Save Item
                    </button>

                    <button class="px-6 ml-3"
                            mat-flat-button
                            type="button"
                            [color]="'warn'"
                            (click)="DeleteItem()">
                        <mat-icon>delete</mat-icon>
                        Delete Item
                    </button>

                    <button class="px-6 ml-3"
                            mat-flat-button
                            type="button"
                            [color]="'primary'"
                            (click)="CloseItem()">
                        <mat-icon>close</mat-icon>
                        Close
                    </button>

                    <!-- Alert -->
                    <fuse-alert class="mt-8"
                                *ngIf="showAlert"
                                [appearance]="'outline'"
                                [showIcon]="false"
                                [type]="alert.type"
                                [@shake]="alert.type === 'error'">
                        {{alert.message}}
                    </fuse-alert>
                </div>

            </div>

        </form>
    </div>

</ng-container>

<!-- Scenario Upload Form -->
<ng-template #ScenarioUpload>
    <div class="flex flex-auto w-full">

        <div class="p-2 w-full">
            <form id="scenarioVideoForm" [formGroup]="scenarioVideoForm" class="w-full">

                <div class="flex-auto grid grid-cols-6">
                    <div class="col-span-3 sm:col-span-3 flex flex-col items-left justify-top p-2">
                        <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                            <span class="font-semibold mb-1">Scenario Video</span>
                            <p class="text-secondary">Upload a video file.</p>
                        </div>

                        <div *ngIf="showSelectedFile">
                            <span>Selected Scenario File: {{selectedFiles[0].name}}</span>

                            <!-- Video Viewer Component -->
                            <div class="w-full">

                                <div class="flex flex-col">

                                    <div class="flex flex-row flex-grow">
                                        <app-videoviewer-player [type]="type"></app-videoviewer-player>
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div>
                            <span>Current Scenario ID: {{selectedItem.id}}</span>
                        </div>

                        <div class="">
                            <div>Selected File Name: {{displayFileName || "No file uploaded yet."}}</div>
                        </div>

                        <div>
                            <span>Current Resource URL: <a [href]="selectedItem.resourceURL">{{selectedItem.resourceURL}}</a></span>
                        </div>

                        <div class="flex-initial">
                            <p class="text-secondary">Clear resource files - video, thumbnail, and transcript files</p>
                            <button class="px-6 ml-3 w-40"
                                    mat-flat-button
                                    [color]="'primary'"
                                    type="button"
                                    (click)="ClearResources()">
                                Clear Resources
                            </button>

                            <p class="text-secondary">Select a local file to upload</p>
                            <button class="px-6 ml-3 w-40"
                                    mat-flat-button
                                    [color]="'primary'"
                                    type="button"
                                    (click)="fileInput.click()">
                                Select Video
                            </button>

                            <input type="file" hidden
                                   #fileInput
                                   id="fileInput"
                                   name="fileInput"
                                   formControlName="fileInput"
                                   accept="video/*"
                                   (change)="onFileSelected($event)" />


                            <p class="text-secondary">Update scenario resource url to match url to scenario id.</p>
                            <button class="px-6 ml-3 w-40"
                                    mat-flat-button
                                    [color]="'primary'"
                                    type="button"
                                    (click)="UpdateScenarioURL()">
                                Update URL
                            </button>

                        </div>

                        <div *ngIf="message.length" class="message">
                            <ul *ngFor="let msg of message; let i = index">
                                <li>{{ msg }}</li>
                            </ul>
                        </div>

                    </div>

                    <div class="col-span-3 sm:col-span-3 flex flex-col items-left justify-top p-2">
                        <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                            <span class="font-semibold mb-1">Scenario Thumbnail</span>
                            <p class="text-secondary">Click to generate and set the thumbnail</p>
                        </div>

                        <div class="flex flex-col items-start">
                            <img class="scenarioThumb" [src]="getScenarioThumbUrl(selectedItem)" />
                            <span>Current Resource Thumb URL: <a [href]="selectedItem.resourceThumbURL">{{selectedItem.resourceThumbURL}}</a></span>
                        </div>

                        <div class="flex-initial">

                            <button class="px-6 ml-3 w-40"
                                    mat-flat-button
                                    [color]="'primary'"
                                    type="button"
                                    (click)="SetThumbnail()">
                                Set Thumbnail for Video
                            </button>

                        </div>

                    </div>

                </div>

            </form>

        </div>



    </div>
</ng-template>

<!-- Scenario Indexer Form -->
<ng-template #ScenarioIndexer>
    <div class="flex flex-auto w-full bg-green-100">

        <div class="p-8 pb-4">

            <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                <span class="font-semibold mb-1">Index Video</span>
                <p class="text-secondary">
                    Transcribe the video file and add it to the scenario index.  The file must first be saved with a URL.
                    This process could be somewhat time-consuming.
                </p>
            </div>

            <div>File Name: {{selectedItem.name}}</div>
            <div>File URL: {{selectedItem.resourceURL}}</div>

            <div><span>Transcript: <a [href]="selectedItem.transcriptURL">{{selectedItem.transcriptURL}}</a></span></div>

            <div *ngIf="selectedItem.indexed!=true" class="text-red-600 font-semibold">
                <mat-icon class="red-icon">warning</mat-icon> Index state: {{selectedItem.indexed}}
            </div>
            <div *ngIf="selectedItem.indexed==true" class="text-green-600 font-semibold">
                <mat-icon class="green-icon">check</mat-icon> Index state: {{selectedItem.indexed}}
            </div>


            <div>

                <button class="px-6 ml-3 w-40"
                        mat-flat-button
                        [color]="'primary'"
                        type="button"
                        [disabled]="selectedItem.indexed==true"
                        (click)="IndexItem()">
                    Index Scenario
                </button>

            </div>
        </div>
    </div>
</ng-template>

<!-- Scenario Trainer Form -->
<ng-template #ScenarioTrainer>
    <div class="flex flex-auto w-full bg-orange-100">
        <div class="p-8 pb-4">

            <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                <span class="font-semibold mb-1">Train Scenario</span>
                <p class="text-secondary">
                    Generate a synthetic response to the given scenario using an AI model.
                    The scenario must be indexed first and have a valid transcript.
                </p>
            </div>

            <div class="flex items-center justify-left mt-8 mb-8">
                <button class="px-6 ml-3"
                        mat-flat-button
                        type="button"
                        [color]="'primary'"
                        (click)="GetTranscript()">
                    <mat-icon>Description</mat-icon>
                    Get Transcript
                </button>
            </div>

            <div>Transcript Text: {{transcript}}</div>

            <div class="flex items-center justify-left mt-8 mb-8">
                <button class="px-6 ml-3"
                        mat-flat-button
                        type="button"
                        [color]="'primary'"
                        (click)="GenResponse()">
                    <mat-icon>Psychology</mat-icon>
                    Generate Response
                </button>
            </div>

            <div>Synthetic Response: {{syntheticResponse}}</div>

            <div class="flex items-center justify-left mt-8 mb-8" *ngIf="!syntheticResponse == ''">
                <button class="px-6 ml-3"
                        mat-flat-button
                        type="button"
                        [color]="'primary'"
                        (click)="TestResponse()">
                    <mat-icon>Labs</mat-icon>
                    Test Response
                </button>

                <!-- <button class="px-6 ml-3"
                        mat-flat-button
                        type="button"
                        [color]="'primary'"
                        (click)="SaveEvaluations()">
                    <mat-icon>Save</mat-icon>
                    Save Evaluations
                </button>-->
            </div>

            <div>Test Results</div>

            <!-- Display Feedback Outputs -->
            <div class="flex-auto grid grid-cols-6 gap-4 mt-6">

                <div class="col-span-6 sm:col-span-6 flex flex-col py-2 px-2 rounded-2xl bg-gray-100 text-secondary dark:bg-white dark:bg-opacity-5">

                    <div *ngFor="let fbo of feedbackOutputs">

                        <ng-container>

                            <div class="flex items-center mx-6 sm:mx-8 mt-4 mb-4">
                                <div class="flex flex-col">
                                    <span class="text-lg font-bold leading-none">{{fbo.name | titlecase}}</span>
                                    <span class="text-sm text-secondary leading-none mt-1">{{fbo.dateTimeOffset | date}}</span>
                                </div>
                            </div>

                            <!-- Feedback Rubrics -->
                            <div class="flex flex-row flex-auto mx-2 sm:mx-8 mt-4 mb-4">

                                <mat-table class="mat-elevation-z8" [dataSource]="getDataSource(fbo)">

                                    <mat-header-row *matHeaderRowDef="evalDisplayedColumns"></mat-header-row>

                                    <ng-container matColumnDef="question">
                                        <mat-header-cell *matHeaderCellDef> Criteria </mat-header-cell>
                                        <mat-cell *matCellDef="let element"> {{element.question}} </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="response">
                                        <mat-header-cell *matHeaderCellDef> Response </mat-header-cell>
                                        <mat-cell *matCellDef="let element"> {{element.response}} </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="score">
                                        <mat-header-cell *matHeaderCellDef> Points </mat-header-cell>
                                        <mat-cell *matCellDef="let element">

                                            <mat-progress-bar class="mt-3 mx-2 rounded-full progressBar"
                                                              [color]="'primary'"
                                                              [mode]="'determinate'"
                                                              [value]="getRubricPointsPerc(element)"></mat-progress-bar>

                                            <div class="mx-1">{{element.score}} / {{getMaxScore()}} Points</div>
                                        </mat-cell>
                                    </ng-container>

                                    <mat-row *matRowDef="let row; columns: evalDisplayedColumns;">
                                    </mat-row>
                                </mat-table>

                            </div>

                        </ng-container>


                    </div>


                </div>


            </div>

        </div>
    </div>

</ng-template>

<!-- Scenario Rubrics Form -->
<ng-container #RubricsList *ngIf="showItemDetail">
    <div class="flex flex-auto">

        <div class="flex flex-col mt-8 bg-card shadow rounded overflow-hidden mr-2 w-full">

            <div class="p-8 pb-4">

                <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                    <span class="font-semibold mb-1">Scenario Rubrics</span>
                    <p class="text-secondary">Rubrics to assess user response and give feedback.</p>
                </div>


                <div class="w-full">

                    <mat-table [dataSource]="rubricsDataSource">

                        <!-- Id Column -->
                        <ng-container matColumnDef="rubricnumber">
                            <mat-header-cell *matHeaderCellDef> Number </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.number}} </mat-cell>
                        </ng-container>

                        <!-- Question Column -->
                        <ng-container matColumnDef="rubricquestion">
                            <mat-header-cell *matHeaderCellDef> Question </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.question}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="rubricintent">
                            <mat-header-cell *matHeaderCellDef> Intent </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.intent}} </mat-cell>
                        </ng-container>


                        <ng-container matColumnDef="rubriclevel">
                            <mat-header-cell *matHeaderCellDef> Level </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.level}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="rubricpriority">
                            <mat-header-cell *matHeaderCellDef> Priority </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.priority}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="rubricquestionFormat">
                            <mat-header-cell *matHeaderCellDef> Question Format </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.questionFormat}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="rubrictype">
                            <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.questionFormat}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="rubricdetails">
                            <mat-header-cell *matHeaderCellDef> Details </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <button class="px-3 mx-3"
                                        mat-flat-button
                                        [color]="'primary'" (click)="ShowRubricDetail(element)">
                                    <span>Details</span>
                                </button>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="rubricDisplayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: rubricDisplayedColumns;">
                        </mat-row>
                    </mat-table>

                    <mat-divider></mat-divider>

                    <div class="flex items-center justify-left mt-8 mb-8" *ngIf="!showRubricDetail">
                        <button class="px-6 ml-3"
                                mat-flat-button
                                type="button"
                                [color]="'primary'"
                                (click)="AddRubric()">
                            <mat-icon>Add</mat-icon>
                            Add Rubric
                        </button>

                    </div>

                    <ng-template [ngTemplateOutlet]="RubricDetail" *ngIf="showRubricDetail"></ng-template>

                </div>

            </div>

        </div>

    </div>
</ng-container>

<!-- Rubric Detail -->
<ng-template #RubricDetail>

    <div class="flex flex-auto">
        <form id="rubricDetailForm" [formGroup]="rubricDetailForm" class="w-full">

            <div class="flex flex-col mt-8 bg-card rounded overflow-hidden">

                <div class="p-8 pb-4 bg-zinc-100">

                    <div class="flex-auto grid grid-cols-6">

                        <div class="col-span-2 sm:col-span-2 flex flex-col items-left justify-top p-4">
                            <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                <mat-form-field class="">
                                    <mat-label class="font-semibold mt-5">Question</mat-label>
                                    <input id="question"
                                           matInput
                                           [formControlName]="'question'">
                                </mat-form-field>
                            </div>

                            <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                <mat-form-field class="">
                                    <mat-label class="font-semibold mt-5">Intent</mat-label>
                                    <mat-select formControlName="intent">
                                        <mat-option value="Support">Support</mat-option>
                                        <mat-option value="Rubric">Rubric</mat-option>
                                        <mat-option value="Follow Up">Follow Up</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="col-span-2 sm:col-span-2 flex flex-col items-left justify-top p-4">
                            <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                <mat-form-field class="">
                                    <mat-label class="font-semibold mt-5">Level</mat-label>
                                    <mat-select formControlName="level">
                                        <mat-option value="Beginner">Beginner</mat-option>
                                        <mat-option value="Intermediate">Intermediate</mat-option>
                                        <mat-option value="Advanced">Advanced</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                <mat-form-field class="">
                                    <mat-label class="font-semibold mt-5">Priority</mat-label>
                                    <mat-select formControlName="priority">
                                        <mat-option value="Low">Low</mat-option>
                                        <mat-option value="Medium">Medium</mat-option>
                                        <mat-option value="High">High</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="col-span-2 sm:col-span-2 flex flex-col items-left justify-top p-4">
                            <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                <mat-form-field class="">
                                    <mat-label class="font-semibold mt-5">Question Format</mat-label>
                                    <mat-select formControlName="questionFormat">
                                        <mat-option value="Binary">Binary</mat-option>
                                        <mat-option value="Reflective Feedback">Reflective Feedback</mat-option>
                                        <mat-option value="Area of Improvement">Area of Improvement</mat-option>
                                        <mat-option value="Quantitative">Quantitative</mat-option>
                                        <mat-option value="Qualitative">Qualitative</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                <mat-form-field class="">
                                    <mat-label class="font-semibold mt-5">Type</mat-label>
                                    <mat-select formControlName="type">
                                        <mat-option value="Q">Q</mat-option>
                                        <mat-option value="Feedback">Feedback</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>


                </div>
                <mat-divider></mat-divider>

                <div class="flex items-center justify-left mt-8 mb-8">
                    <button class="px-6 ml-3"
                            mat-flat-button
                            type="button"
                            [color]="'primary'"
                            (click)="SaveRubric()">
                        <mat-icon>save</mat-icon>
                        Save Rubric
                    </button>

                    <button class="px-6 ml-3"
                            mat-flat-button
                            type="button"
                            [color]="'warn'"
                            (click)="DeleteRubric()">
                        <mat-icon>delete</mat-icon>
                        Delete Rubric
                    </button>

                    <button class="px-6 ml-3"
                            mat-flat-button
                            type="button"
                            [color]="'primary'"
                            (click)="CloseRubric()">
                        <mat-icon>close</mat-icon>
                        Close Rubric
                    </button>

                    <!-- Alert -->
                    <fuse-alert class="mt-8"
                                *ngIf="showAlert"
                                [appearance]="'outline'"
                                [showIcon]="false"
                                [type]="alert.type"
                                [@shake]="alert.type === 'error'">
                        {{alert.message}}
                    </fuse-alert>
                </div>

            </div>

        </form>

    </div>

</ng-template>
