<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>




<!-- Wrapper -->
<div class="flex flex-col flex-auto h-screen">

    <!-- Header -->
    <mat-toolbar>
        <ng-container *ngIf="!isScreenSmall">
            <!-- Logo -->
            <div class="flex items-center justify-center">
                <img class="w-30"
                     src="assets/images/logo/cogsolo_logo_1.png">
            </div>

            <div class="flex flex-0 items-center justify-center h-8 pr-6 pl-2 mt-4 mb-4">
                <div class="ml-1 text-lg text-secondary">
                    <span>Beta Version 0.8</span>
                </div>
            </div>

            <!-- Horizontal navigation -->
            <!--<fuse-horizontal-navigation
    class="mr-2"
    [name]="'mainNavigation'"
    [navigation]="navigation.horizontal"></fuse-horizontal-navigation>-->
        </ng-container>
        <!-- Navigation toggle button -->
        <!--<ng-container *ngIf="isScreenSmall">
            <button
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>
        </ng-container>-->
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <!--<languages></languages>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <search [appearance]="'bar'"></search>
            <shortcuts></shortcuts>-->
            <!--<messages></messages>
            <notifications></notifications>-->
            <!--<button class="lg:hidden"
                    mat-icon-button
                    (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
            </button>-->
            <div class="text-center mr-2">
                <button (click)="toggleLogin()" *ngIf="showLogin"
                        mat-flat-button
                        [color]="'primary'"
                        class="btn btn-md text-white mb-0 px-2 items-center justify-center">
                    <mat-icon svgIcon="mat_outline:logout" class="inline-icon"></mat-icon><span class="inline-button-text text-lg">Hide Login</span>
                </button>
                <button (click)="toggleLogin()" *ngIf="!showLogin"
                        mat-flat-button
                        [color]="'primary'"
                        class="btn btn-md text-white mb-0 px-2 items-center justify-center">
                    <mat-icon svgIcon="mat_outline:login" class="inline-icon"></mat-icon><span class="inline-button-text text-lg">  Login  </span>
                </button>
            </div>
            <div class="text-center mr-2 ">
                <button (click)="JoinNow()"
                        mat-flat-button
                        [color]="'primary'"
                        class="btn btn-md text-white mb-0 px-2 items-center justify-center">
                    <mat-icon svgIcon="mat_outline:app_registration" class="inline-icon"></mat-icon><span class="inline-button-text text-lg">Join Now</span>
                </button>
                <!--<div class="mt-8 text-xl font-bold tracking-tight leading-tight text-white" *ngIf="showStartJoin">Setting up a quick trial for you ...</div>-->
            </div>

            <!-- User -->
            <div class="flex flex-col items-center w-full p-2" *ngIf="hasProfileImage">
                <div class="relative w-10 h-10">

                    <img *ngIf="hasProfileImage" [src]="userprofile.imageURL" class="w-full h-full rounded-full" />

                </div>
                <div class="flex flex-col items-center justify-center w-full mt-2">
                    <div class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
                        {{userprofile.name}}
                    </div>
                    <div class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
                        {{userprofile.userName}}
                    </div>
                </div>
            </div>

            <!--<user></user>-->
        </div>


    </mat-toolbar>

    <!-- MAIN PORTION OF THE APPLICATION -->
    <div class="flex flex-col sm:flex-row flex-auto min-w-0">
        <div class="flex flex-auto h-screen">
            <router-outlet></router-outlet>
        </div>

        <div class="md:flex md:justify-end w-full h-screen sm:w-auto md:h-full py-8 px-4 sm:p-12 md:p-16 md:pt-24 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none sm:bg-card" *ngIf="showLogin">

            <auth-sign-in></auth-sign-in>

        </div>
    </div>

    <auth-starttrial></auth-starttrial>


    <!-- Footer -->
    <!--<div class="relative flex flex-0 items-center w-full h-14 sm:h-20 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div>-->
    <!-- Footer -->
    <div class="flex items-center justify-start w-full h-14 px-4 md:px-6 z-49 text-white dark:bg-transparent print:hidden bg-black">
        <div class="flex-none w-14 h-14"></div>
        <div class="flex grow font-medium text-secondary items-center justify-center">
            IdeaGPS, Inc. &copy; {{currentYear}}
        </div>
        <div class="flex-none w-14 h-14"></div>
    </div>

</div>


