    <!-- Conversation -->
<div class="grow h-full">

    <div class="flex flex-col-reverse bg-white h-full">
        <div class="flex flex-col flex-auto dark:bg-transparent h-full">
            <ng-container>

                <div class="text-lg font-bold tracking-tight leading-6 truncate text-center mx-4 mt-4">Messages</div>

                <!-- Messages go here -->
                <div *ngFor="let msg of programstream.messages">

                    <app-programmessage [msg]="msg"
                                        [coachMode]="coachMode"
                                        (msgEvent)="AddNewMessage($event)"
                                        (removeMsgEvent)="RemoveMessage($event)">

                    </app-programmessage>

                </div>

            </ng-container>

            <!-- Load more -->
            <!--<div class="flex items-center h-11 my-px ml-4">
                <button mat-flat-button color="primary" (click)="LoadMoreProgramStream()" *ngIf="!isOnboarding">Load More</button>
            </div>-->
        </div>
    </div>


</div>
