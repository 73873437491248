import { Component, Input, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import { SMService } from '../../../../../services/sm.service';
import { environment } from '../../../../../../environments/environment';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { Location } from '@angular/common';
import { FuseNavigationService } from '@fuse/components/navigation';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-trialstepper',
  templateUrl: './trialstepper.component.html'
})
export class TrialStepperComponent implements OnInit {
    baseUrl = '';
    public theURL: string;
    public thehttp: HttpClient;
    public currentUser: string = "";
    public userType: string = ""; // User, TrialUser, Admin
    public isOnboarding: boolean = false;
    public stepIndex: number = 0;
    public stepOne: boolean = false;
    public stepTwo: boolean = false;
    public stepThree: boolean = false;
    public stepFour: boolean = false;
    public stepFive: boolean = false;

    firstFormGroup = this.formBuilder.group({
        firstCtrl: [''],
    });
    secondFormGroup = this.formBuilder.group({
        secondCtrl: [''],
    });
    thirdFormGroup = this.formBuilder.group({
        thirdCtrl: [''],
    });
    fourthFormGroup = this.formBuilder.group({
        fourthCtrl: [''],
    });
    fifthFormGroup = this.formBuilder.group({
        fifthCtrl: [''],
    });

  constructor(
    http: HttpClient,
    private formBuilder: FormBuilder,
      private smService: SMService  )
  {
    this.baseUrl = environment.BASE_URL;
    this.theURL = this.baseUrl;
    this.thehttp = http;
  }


   ngOnInit(): void {

       // Test purposes
       //this.isOnboarding = true;

       // Production purposes
       var y = this.smService.userType.subscribe(response => {
           if (this.smService.IsTrialUser()) {
               this.isOnboarding = true;
           }
       });

       // Based on the step index, set the appropriate form controls to completed
       var y = this.smService.onboardingStep.subscribe(response => {
           this.stepIndex = response;
           this.SetFormControls();
       });

       this.DisableFormControls();
    }

    SetFormControls() {
        switch (this.stepIndex) {
            case 0:
                this.stepOne = false;
                this.stepTwo = false;
                this.stepThree = false;
                this.stepFour = false;
                this.stepFive = false;
                break;
            case 1:
                this.stepOne = true;
                break;
            case 2:
                this.stepOne = true;
                this.stepTwo = true;
                break;
            case 3:
                this.stepOne = true;
                this.stepTwo = true;
                this.stepThree = true;
                break;
            case 4:
                this.stepOne = true;
                this.stepTwo = true;
                this.stepThree = true;
                this.stepFour = true;
                break;
            case 5:
                this.stepOne = true;
                this.stepTwo = true;
                this.stepThree = true;
                this.stepFour = true;
                this.stepFive = true;
                break;
        }

    }

    DisableFormControls() {

        this.firstFormGroup.disable();
        this.secondFormGroup.disable();
        this.thirdFormGroup.disable();
        this.fourthFormGroup.disable();
        this.fifthFormGroup.disable();

    }

}

