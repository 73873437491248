import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { SharedModule } from '../../../../../shared/shared.module';
import { MessageVideoComponent } from './messagevideo.component';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';


/*const exampleRoutes: Route[] = [
    {
        path     : '',
        component: MessageVideoComponent
    }
];*/

@NgModule({
    declarations: [
        MessageVideoComponent
    ],
    imports     : [
        //RouterModule.forChild(exampleRoutes),
        SharedModule,

        // Video player - videogular
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
    ],
    exports: [
        MessageVideoComponent
    ]
})
export class MessageVideoModule
{
}
