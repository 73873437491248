import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../shared/shared.module';
import { ProgramStreamModule } from '../../programstream/programstream.module';
import { ProgramSummaryListComponent } from './programsummarylist.component';
import { ProgramSummaryListRoutingModule } from './programsummarylist.routing';

@NgModule({
    declarations: [
        ProgramSummaryListComponent,
    ],
    imports: [
        SharedModule,
        ProgramSummaryListRoutingModule,
        ProgramStreamModule
    ],
    exports: [
        ProgramSummaryListComponent,
    ]
})

export class ProgramSummaryListModule
{
}
