import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../shared/shared.module';
import { ProgramScenarioDetailComponent } from './programscenariodetail.component';
import { ProgramScenarioDetailRoutingModule } from './programscenariodetail.routing';
import { TrialStepperModule } from './trialstepper/trialstepper.module';

@NgModule({
    declarations: [
        ProgramScenarioDetailComponent,
    ],
    imports: [
        SharedModule,
        ProgramScenarioDetailRoutingModule,
        TrialStepperModule
    ],
    exports: [
        ProgramScenarioDetailComponent
    ]
})

export class ProgramScenarioDetailModule
{
}
