import { IAspect } from './IAspect';
import { IBaseAspectsGraph } from './IBaseAspectsGraph';
import { IEdge } from './IEdge';
import { IScenario } from './IScenario';

export class BaseAspectsGraph implements IBaseAspectsGraph {
    aspects: IAspect[];
    edges: IEdge[];
    scenarios: IScenario[];
}
