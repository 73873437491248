import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertType } from '@fuse/components/alert';
import { AuthService } from 'app/core/auth/auth.service';
import { forEach } from 'lodash';
import { user } from '../../../mock-api/common/user/data';
import { SMService } from '../../../services/sm.service';

@Component({
    selector     : 'auth-sign-in',
    templateUrl  : './sign-in.component.html',
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class AuthSignInComponent implements OnInit
{
    public smService: SMService;

    @ViewChild('signInNgForm') signInNgForm: NgForm;

    alert: { type: FuseAlertType; message: string } = {
        type   : 'success',
        message: ''
    };
    signInForm: UntypedFormGroup;
    showAlert: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _authService: AuthService,
        private _formBuilder: UntypedFormBuilder,
        private _router: Router,
        sms: SMService,
    )
    {
        this.smService = sms;

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Create the form
        this.signInForm = this._formBuilder.group({
            name : [''],
            password  : ['', Validators.required],
            rememberMe: [''],
            userType: ['']
        });

        // If the user is already registered but not yet logged in, request log in for first time
        if (this._authService.registeredNotLoggedIn) {
            // Set the alert
            this.alert = {
                type: 'info',
                message: 'Registration successful.  Please sign in.'
            };

            // Show the alert
            this.showAlert = true;
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign in
     */
    signIn(): void
    {
        // Return if the form is invalid
        if ( this.signInForm.invalid )
        {
            return;
        }

        // Disable the form
        this.signInForm.disable();

        // Hide the alert
        this.showAlert = false;

        // Sign in
        this._authService.signIn(this.signInForm.value)
            .subscribe(
                (response) => {

                    if (response.hasError) {
                        this.handleErrors();
                    } else {

                        // Redirect based on the user type
                        var url = "";
                        var userType = this.getUserType(response);
                        console.log("Sign In User Type: " + userType);

                        // Set the user type as an active user
                        // TODO - user could be a trial user and still login in normally after registration.
                        this.smService.SetUserType('ActiveUser');
                        this.smService.SetOnboarding(false);

                        if (userType == "Admin") {
                            url = 'admintest/layout/adminprofile'

                        } else if (userType == "Coach") {
                            url = 'admintest/layout/coachprofile';

                        } else if (userType == "User") {

                            if (response.isOnboarding) {
                                url = 'admintest/layout/userprofile/programscenariodetail/viewresponse';

                                // Set the user type as a trial user
                                this.smService.SetUserType('ActiveUser');
                                this.smService.SetOnboarding(true);

                                // load the first scenario from the trial
                                this.LoadTrialScenario();

                            } else {
                                url = 'admintest/layout/userprofile/programsummarylist';

                            }
                        }

                        if (this.showAlert == false) {
                            console.log("Sign In Success: Navigating to: " + url);
                            this._router.navigateByUrl(url);
                        }


                    }
                },
                () => {
                    this.handleErrors();
                }
            );

    }

    LoadTrialScenario() {
        this.smService.LoadTrialScenario().subscribe(result => {

            // Reset any current scenario 
            this.smService.CurrentScenarioRec.next(result);
            //this.smService.CurrentResponse.next(null);
            //this.smService.VideoSource.next(null);
            //this.smService.VideoSourceThumb.next(null);
            //this.smService.NewResponse.next(null);
            //this.smService.VideoSource.next(result.resourceURL);
            //this.smService.VideoSourceThumb.next(result.resourceThumbURL);

            this.smService.isTrialRegistration.next(false);

            console.log("Reloading Trial Scenario for Registered User");

        }, error => console.error(error));
    }


    getUserType(response) {

        var userTypes = response.userTypes;
        if (userTypes != null) {

            if (userTypes.indexOf('Admin') > -1) {
                return 'Admin';
            }

            if (userTypes.indexOf('Coach') > -1) {
                return 'Coach';
            }
        }

        // Default type is user
        return 'User';
    }

    handleErrors() {
        console.log("Error on Login");
        // Re-enable the form
        this.signInForm.enable();

        // Reset the form
        this.signInNgForm.resetForm();

        // Set the alert
        this.alert = {
            type: 'error',
            message: 'Wrong username or password.  Please try again.'
        };

        // Show the alert
        this.showAlert = true;
     }

}
