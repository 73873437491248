<div class="profilePanel">

    <div class="bg-card">
        <div class="flex flex-col w-full max-w-screen-xl mx-auto bg-slate-50">
            <ng-container>
                <div class="text-2xl md:text-4xl font-semibold tracking-tight leading-7 md:leading-snug truncate mb-6">{{PageTitle}}</div>
                <div class="text-xl md:text-xl tracking-tight leading-7 md:leading-snug truncate mb-6"></div>
            </ng-container>

            <div class="flex flex-auto">
                <!-- The Profile Edit Form -->
                <form #f="ngForm" (ngSubmit)="SaveRecConfig()" [formGroup]="recConfigForm" class="w-full">
                    <div class="flex items-center justify-left mt-8">
                        <button *ngIf="editMode == true"
                                class="px-6 ml-3"
                                mat-flat-button
                                [color]="'primary'">
                            Save
                        </button>
                    </div>

                    <div class="flex flex-col mt-8 bg-card shadow-sm rounded-lg overflow-hidden">
                        <div class="sectionPanel">
                            <p class="text-2xl font-semibold">Learning Objectives</p>
                            <p class="text-secondary">Your learning objectives helps CogSolo determine which parts of the Program are most relevant to you.</p>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="p-8 pb-4">

                            <!-- coverageWeight -->
                            <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                <span class="font-semibold mb-1 gt-xs:text-right gt-xs:min-w-32 gt-xs:mr-4 gt-xs:mb-0">Coverage</span>
                                <span class="text-sm mb-1 gt-xs:text-right gt-xs:min-w-32 gt-xs:mr-4 gt-xs:mb-0">Provides more variety of scenarios while ensuring all major skill areas within the program are covered.</span>
                                <div class="fuse-mat-dense flex-auto">
                                    <mat-slider min="0" max="1" step="0.1" showTickMarks discrete [displayWith]="formatLabel" class="slider">
                                        <input matSliderThumb formControlName="coverageWeight">
                                    </mat-slider>Currently: {{recconfig.coverageWeight}}
                                </div>
                            </div>

                            <!-- masteryWeight -->
                            <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                                <span class="font-semibold mb-1 gt-xs:text-right gt-xs:min-w-32 gt-xs:mr-4 gt-xs:mb-0">Mastery</span>
                                <span class="text-sm mb-1 gt-xs:text-right gt-xs:min-w-32 gt-xs:mr-4 gt-xs:mb-0">Provides more depth of scenarios in certain skill areas.  Increasing the mastery value will add more number of scenarios to your program.</span>
                                <div class="fuse-mat-dense flex-auto">
                                    <mat-slider min="0" max="1" step="0.1" showTickMarks discrete [displayWith]="formatLabel" class="slider">
                                        <input matSliderThumb formControlName="masteryWeight">
                                    </mat-slider>Currently: {{recconfig.masteryWeight}}
                                </div>
                            </div>


                        </div>
                    </div>

                </form>


            </div>
        </div>
    </div>




</div>
