import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TrialStepperComponent } from './trialstepper.component';

const routes: Routes = [
    {
        path: '',
        component: TrialStepperComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TrialStepperRoutingModule { }


