import { Component, Inject, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SMService } from '../../../services/sm.service';
import { ILoadResult } from '../../../models/ILoadResult';
import { RecConfig } from '../../../models/RecConfig';
import { ProgramMessage } from '../../../models/ProgramMessage';


@Component({
  selector: 'app-recconfig',
  templateUrl: './recconfig.component.html'
})
export class RecConfigComponent implements OnInit {
    baseUrl = '';
    public theURL: string;
    public thehttp: HttpClient;
    public recConfigForm: FormGroup;
    public loadResults: ILoadResult[];
    public currentResult: string;
    public currentUser: string = "";
    public programMessage: ProgramMessage;
    //@Input() recconfig: RecConfig;
    public recconfig: RecConfig;
    editMode: boolean = true;
    public PageTitle: string = "Advanced Configuration";

  constructor(
    http: HttpClient,
    private formBuilder: FormBuilder,
    private smService: SMService
  )
  {
    this.baseUrl = environment.BASE_URL;
    this.theURL = this.baseUrl;
    this.thehttp = http;
  }

  ngOnInit(): void {
      this.recconfig = this.smService.recconfig;

      if (this.recconfig == null) {
          this.UpdateRecConfig();
      }

      // Subscribe to programsteam message events
      /*this.smService.messageReceived.subscribe(result => {

          // Set the programstream to the result
          this.programMessage = result;

          // Update the RecConfig
          this.UpdateRecConfig();

      }, error => console.error(error));*/

      this.recConfigForm = this.formBuilder.group({
          coverageWeight: [this.recconfig.coverageWeight],
          masteryWeight: [this.recconfig.masteryWeight]
      });
      
    }

    private UpdateRecConfig() {

        this.smService.GetRecConfig().subscribe(result => {

            // Set the user profile info 
            this.recconfig = result.userProfile.recConfig;

            console.log("Loaded Recommendation Configuration for User.");

        }, error => console.error(error));
    }

    public SaveRecConfig()
    {
        /*this.recconfig.aspectGraphWeight = this.recConfigForm.get("aspectGraphWeight").value;
        this.recconfig.engagementWeight = this.recConfigForm.get("engagementWeight").value;
        this.recconfig.usageIntentionOneWeight = this.recConfigForm.get("usageIntentionOneWeight").value;
        this.recconfig.usageIntentionTwoWeight = this.recConfigForm.get("usageIntentionTwoWeight").value;
        this.recconfig.usageIntentionThreeWeight = this.recConfigForm.get("usageIntentionThreeWeight").value;
        this.recconfig.priorExperienceWeight = this.recConfigForm.get("priorExperienceWeight").value;
        this.recconfig.timeAvailableWeight = this.recConfigForm.get("timeAvailableWeight").value;
        this.recconfig.timePerScenarioWeight = this.recConfigForm.get("timePerScenarioWeight").value;
        this.recconfig.personalityTypeWeight = this.recConfigForm.get("personalityTypeWeight").value;*/
        this.recconfig.coverageWeight = this.recConfigForm.get("coverageWeight").value;
        this.recconfig.masteryWeight = this.recConfigForm.get("masteryWeight").value;
        /*this.recconfig.progressWeight = this.recConfigForm.get("progressWeight").value;
        this.recconfig.discoverWeight = this.recConfigForm.get("discoverWeight").value;
        this.recconfig.strengthsWeight = this.recConfigForm.get("strengthsWeight").value;
        this.recconfig.journeyWeight = this.recConfigForm.get("journeyWeight").value;
        this.recconfig.priorityWeight = this.recConfigForm.get("priorityWeight").value;*/

        // Save the profile
        this.smService.SubmitConfigInfo(this.recconfig).subscribe(result => {

            if (result == false) {
                console.log("Unable to update user recommendation configuration.");
            } else {

                console.log("Successfully updated user recommendation configuration.");

                // also reset the form's edit status
                //this.editMode = false;
            }

        }, error => console.error(error));
    }


    formatLabel(value: number): string {
        if (value >= 1000) {
            return Math.round(value / 1000) + 'k';
        }

        return `${value}`;
    }

 }

