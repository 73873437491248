import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admintest',
  templateUrl: './admintest.component.html',
  styleUrls: ['./admintest.component.scss']
})
export class AdmintestComponent {

    constructor(private router: Router) {


    }

    showadminchild() {
        this.router.navigate(['admintest', 'adminchild']);
    }

    showadminsecondchild() {
        this.router.navigate(['admintest', 'adminsecondchild']);
    }

    showlayout() {
        this.router.navigate(['admintest', 'layout']);
    }

}
