        <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
            <!-- Logo -->
            <div class="w-48">
                <img class="" src="assets/images/logo/Cogsolo_Logo_2.png" />
            </div>

            <!-- Title -->
            <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight">Sign in</div>
            <!--<div class="flex items-baseline mt-0.5 font-medium">
                <div>Don't have an account?</div>
                <a class="ml-1 text-primary-500 hover:underline"
                   [routerLink]="['/sign-up']">
                    Sign up
                </a>
            </div>-->

            <!--<div class="flex items-baseline mt-0.5 font-medium">
                <div>Want to Try? - Start a Trial</div>
                <a class="ml-1 text-primary-500 hover:underline"
                   [routerLink]="['/starttrial']">
                    Start Trial
                </a>
            </div>-->

            <!-- Alert -->
            <fuse-alert class="mt-8"
                        *ngIf="showAlert"
                        [appearance]="'outline'"
                        [showIcon]="false"
                        [type]="alert.type"
                        [@shake]="alert.type === 'error'">
                {{alert.message}}
            </fuse-alert>

            <!-- Sign in form -->
            <form class="mt-8"
                  [formGroup]="signInForm"
                  #signInNgForm="ngForm">

                <!-- User Name field -->
                <mat-form-field class="w-full">
                    <mat-label>User Name</mat-label>
                    <input id="name"
                           matInput
                           [formControlName]="'name'">
                    <mat-error *ngIf="signInForm.get('name').hasError('required')">
                        User Name is required
                    </mat-error>
                    <mat-error *ngIf="signInForm.get('name').hasError('name')">
                        Please enter a valid User Name
                    </mat-error>
                </mat-form-field>

                <!-- Password field -->
                <mat-form-field class="w-full">
                    <mat-label>Password</mat-label>
                    <input id="password"
                           matInput
                           type="password"
                           [formControlName]="'password'"
                           #passwordField>
                    <button mat-icon-button
                            type="button"
                            (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
                            matSuffix>
                        <mat-icon class="icon-size-5"
                                  *ngIf="passwordField.type === 'password'"
                                  [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                        <mat-icon class="icon-size-5"
                                  *ngIf="passwordField.type === 'text'"
                                  [svgIcon]="'heroicons_solid:eye-off'"></mat-icon>
                    </button>
                    <mat-error>
                        Password is required
                    </mat-error>
                </mat-form-field>

                <!-- Actions -->
                <div class="inline-flex items-center justify-between w-full mt-1.5">
                    <!--<mat-checkbox class="-ml-2"
              [color]="'primary'"
              [formControlName]="'rememberMe'">
                Remember me
            </mat-checkbox>-->
                    <a class="text-md font-medium text-primary-500 hover:underline"
                       [routerLink]="['/forgot-password']">
                        Forgot password?
                    </a>
                </div>

                <!-- Submit button -->
                <button class="fuse-mat-button-large w-full mt-6"
                        mat-flat-button
                        [color]="'primary'"
                        [disabled]="signInForm.disabled"
                        (click)="signIn()">
                    <mat-icon svgIcon="login" class="inline-icon"></mat-icon>
                    <span *ngIf="!signInForm.disabled" class="ml-2 text-lg">
                        Sign in
                    </span>
                    <mat-progress-spinner *ngIf="signInForm.disabled"
                                          [diameter]="24"
                                          [mode]="'indeterminate'"></mat-progress-spinner>
                </button>

            </form>
        </div>

