import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { AdminProfileComponent } from 'app/modules/adminprofile/adminprofile.component';
import { AdminAspectMapModule } from './adminaspectmap/adminaspectmap.module';
import { AdminAspectsModule } from './adminaspects/adminaspects.module';
import { AdminScenarioMapModule } from './adminscenariomap/adminscenariomap.module';
import { AdminScenariosModule } from './adminscenarios/adminscenarios.module';
import { AdminProfileRoutingModule } from './adminprofile.routing';
import { AdminCurriculumModule } from './admincurriculum/admincurriculum.module';



@NgModule({
    declarations: [
        AdminProfileComponent
    ],
    imports: [
        SharedModule,
        AdminAspectMapModule,
        AdminAspectsModule,
        AdminScenarioMapModule,
        AdminScenariosModule,
        AdminProfileRoutingModule,
        AdminCurriculumModule
    ],
    exports: [
        AdminProfileComponent
    ]
})
export class AdminProfileModule
{
}
