import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SMService } from '../../services/sm.service';
import { ILoadResult } from '../../models/ILoadResult';
import { UserProfile } from '../../models/UserProfile';
import { ProgramMessage } from '../../models/ProgramMessage';
import { Dashboard } from '../../models/Dashboard';
import { RecConfig } from '../../models/RecConfig';


@Component({
  selector: 'app-coachprofile',
  templateUrl: './coachprofile.component.html'
})
export class CoachProfileComponent implements OnInit {
    baseUrl = '';
    public theURL: string;
    public thehttp: HttpClient;
    public loadResults: ILoadResult[];
    public currentResult: string;
    public currentUser: string = "";
    public programMessage: ProgramMessage;
    public userprofile: UserProfile;
    public recconfig: RecConfig;
    public dashboard: Dashboard;
    public profileLoaded: boolean;
    public smService: SMService;
    visibleTab: string = "";
    public selectedUser: UserProfile;
    public coachMode: boolean = false;

    public users: UserProfile[];
    public showUserDetail: boolean = false;

    constructor(
        http: HttpClient,
        private formBuilder: FormBuilder,
        sms: SMService
        )
    {
        this.baseUrl = environment.BASE_URL;
        this.theURL = this.baseUrl;
        this.thehttp = http;
        this.smService = sms;
    }

    ngOnInit(): void {

        // Update the UserProfile
        this.smService.GetUserProfile().subscribe(result => {
            this.userprofile = result.userProfile;
            this.recconfig = result.userProfile.recConfig;
            this.dashboard = result.dashboard;
            this.profileLoaded = true;
            console.log("Loaded Coach Profile");

            this.LoadUsers();

        }, error => console.error(error));
    }

    GetNumCoaches(user: UserProfile) {
        var num = 0;
        if (user.coaches != null && user.coaches != undefined) {
            num = user.coaches.length;
        }
        return num;
    }

    LoadUserStream(selectedUser: UserProfile): void {
        // Load the program stream for the selected user by setting the value
        // and passing it to the ProgramStream component
        console.log("Loading User Stream for: " + selectedUser.name);
        this.coachMode = false;
        this.selectedUser = selectedUser;
        this.coachMode = true;
    }

    LoadUsers(): void {

        // Get list of assigned users for the coach 
        this.smService.GetUsersForCoach(this.userprofile).subscribe(result => {

            this.userprofile.users = result.users;
            this.users = result.users;
            console.log("Loaded Users");

        }, error => console.error(error));

    }

    LoadScenariosForUser(): void {

        // Get the list of scenarios for the selected user
        this.smService.GetScenariosForUser(this.selectedUser).subscribe(result => {

            console.log("Retrieved scenarios for user.");

        }, error => console.error(error));

    }


    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    SelectUser(user) {
        console.log("Selected User: " + user.id);
        this.selectedUser = user;
        if (this.selectedUser != null) {
            console.log("Selected User is: " + this.selectedUser.name);
        }
        this.LoadUserStream(user);
    }


    public changedTab($event) {
        let clickedIndex = $event.index;

        switch (clickedIndex) {
            case 0:
                this.visibleTab = "Stream";
                break;
            case 1:
                this.visibleTab = "Profile";
                break;
            case 2:
                this.visibleTab = "Configuration";
                break;
        }

        this.smService.CurrentView = this.visibleTab;

        console.log("Current Tab is: " + this.visibleTab);
    }


}

