<div class="bg-card">

    <div class="flex flex-col w-full max-w-screen-xl mx-auto bg-slate-50">
        <ng-container>
            <div class="text-2xl md:text-5xl font-semibold tracking-tight leading-7 md:leading-snug truncate">{{PageTitle}}</div>
        </ng-container>

        <div class="flex flex-auto">
            <!-- The Program Settings Form -->
            <form id="programSettingsForm" [formGroup]="programSettingsForm" class="w-full">


                <div class="flex flex-col mt-8 bg-card shadow rounded overflow-hidden">
                    <div class="sectionPanel">
                        <p class="text-2xl font-semibold">Program Settings</p>
                        <p class="text-secondary">These program settings help the CogSolo system determine the best recommendations for you based on your areas of interest.  Many scenarios impact more than one area.  You can always adjust these settings later as well.</p>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="p-8 pb-4">

                        <!-- TODO add aspect group form fields here -->

                    </div>
                    <mat-divider></mat-divider>
                    <div class="flex items-center justify-left mt-8 mb-8">
                        <button 
                                class="px-6 ml-3"
                                mat-flat-button
                                type="button"
                                [color]="'primary'"
                                (click)="SaveProgramSettings()">
                            Save
                        </button>
                    </div>

                </div>

            </form>

        </div>
    </div>
</div>
