import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout.component';

const routes: Route[] = [
    {
        path: '',
        component: LayoutComponent,
        data: { layout: 'classy', userType: 'User' },
        children: [
            {
                path: 'home',
                loadChildren: () => import("app/modules/home/home.module").then(m => m.HomeModule)
            }
        ]
    },
    {
        path: '',
        component: LayoutComponent,
        data: { layout: 'classy', userType: 'User' },
        children: [
            {
                path: 'userprofile',
                loadChildren: () => import("app/modules/userprofile/userprofile.module").then(m => m.UserProfileModule)
            }
        ]
    },
    {
        path: '',
        component: LayoutComponent,
        data: { layout: 'classy', userType: 'TrialUser' },
        children: [
            {
                path: 'trialuser',
                loadChildren: () => import("app/modules/userprofile/userprofile.module").then(m => m.UserProfileModule)
            }
        ]
    },
    {
        path: '',
        component: LayoutComponent,
        data: { layout: 'classy', userType: 'Admin' },
        children: [
            {
                path: 'adminprofile',
                loadChildren: () => import("app/modules/adminprofile/adminprofile.module").then(m => m.AdminProfileModule)
            }
        ]
    },

];




@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class LayoutRoutingModule { }
