import { Aspect } from './Aspect';
import { AspectPreferencesDto } from './AspectPreferencesDto';
import { Dashboard } from './Dashboard';
import { IEngagement } from './IEngagement';
import { IGoal } from './IGoal';
import { IRecConfig } from './IRecConfig';
import { IUserProfile } from './IUserProfile';
import { RecConfig, RecConfigDto } from './RecConfig';
import { Scenario } from './Scenario';

export class UserProfile implements IUserProfile {
    id: string;
    name: string;
    description: string;
    entityType: string;
    userName: string;
    normalizedUserName: string;
    industry: string;
    jobFunction: string;

    goals: IGoal[];

    engTotal: number;
    engSurprise: number;
    engSurpriseDate: string;
    engProgress: number;
    engProgressDate: string;

    engPersonalityType: string;  // introvert, extrovert, ambivert
    engPriorExperience: number;
    engPriorExperienceDate: string;
    engCoachingPreference: string;  // self, coach, both
    engTimeAvailable: string; // bootcamp, workshop, ongoing
    engTimePerScenario: number;
    engJudgingType: string;  // lenient, neutral, tough, demanding

    // Program specific engagement values
    engPrimaryMotivation: string;  // primary motivation for joining the program - career advancement, team leadership, on-the-job presence, personal growth, etc.

    engReminderFrequency: string;  // daily, weekly, monthly, yearly
    engReminderDays: string;  // monday, tuesday, wednesday, thursday, friday, saturday, sunday
    engReminderWeeks: string;  // first, second, third, fourth, last
    engReminderMonths: string;  // january, february, march, april, may, june, july, august, september, october, november, december

    engExpressInterest: number;
    engExpressInterestDate: string;
    engViewRead: number;
    engViewReadDate: string;
    engAnswerAssessment: number;
    engAnswerAssessmentDate: string;
    engReadStrategy: number;
    engReadStrategyDate: string;
    engPracticeStrategy: number;
    engPracticeStrategyDate: string;
    engDevelopHabit: number;
    engDevelopHabitDate: string;
    engImplementResource: number;
    engImplementResourceDate: string;
    engCoachRating: number;
    engCoachRatingDate: string;
    engCommunityParticipation: number;
    engCommunityParticipationDate: string;
    engUpdateAspect: number;
    engUpdateAspectDate: string;
    engUpdateScenario: number;
    engUpdateScenarioDate: string;
    engAdoptProgram: number;
    engAdoptProgramDate: string;
    engFeedback: number;
    engFeedbackDate: string;
    engSubmitResponse: number;
    engSubmitResponseDate: string;
    engReminder: number;
    engReminderDate: string;

    engTotalBudget: number;
    recConfigID: string;
    recConfig: IRecConfig;
    programScenarioCount: number;
    programScenarioCompletedCount: number;
    lastRoundId: string;
    
    coaches: IUserProfile[];
    users: IUserProfile[];
    numCoaches: number;
    numUsers: number;
    requestedHumanReview: boolean;

    imageURL: string; // Url of the user's profile image

    userStatus: string; // Status of the user's profile (onboarding, active, inactive, etc.)
    isOnboarding: boolean; // Indicates if a trial or active user is onboarding

    basicProfileComplete: boolean;
    programSettingsComplete: boolean;
    firstMessageComplete: boolean;
    firstRecommendationComplete: boolean;
    programStartDate: Date;
    engagementType: string;

}

export class UserProfileDto {
    id: string;
    name: string;
    description: string;
    userName: string;
    entityType: string;

    goals: IGoal[];

    normalizedUserName: string;
    engTotal: number;
    engSurprise: number;
    engSurpriseDate: string;
    engProgress: number;
    engProgressDate: string;

    engPersonalityType: string;  // introvert, extrovert, ambivert
    engPriorExperience: number;
    engPriorExperienceDate: string;
    engCoachingPreference: string;  // self, coach, both
    engTimeAvailable: string; // bootcamp, workshop, ongoing
    engTimePerScenario: number;
    engJudgingType: string;  // lenient, neutral, tough, demanding

    // Program specific engagement values
    engPrimaryMotivation: string;  // primary motivation for joining the program - career advancement, team leadership, on-the-job presence, personal growth, etc.


    engReminderFrequency: string;  // daily, weekly, monthly, yearly
    engReminderDays: string;  // monday, tuesday, wednesday, thursday, friday, saturday, sunday
    engReminderWeeks: string;  // first, second, third, fourth, last
    engReminderMonths: string;  // january, february, march, april, may, june, july, august, september, october, november, december

    engExpressInterest: number;
    engExpressInterestDate: string;
    engViewRead: number;
    engViewReadDate: string;
    engAnswerAssessment: number;
    engAnswerAssessmentDate: string;
    engReadStrategy: number;
    engReadStrategyDate: string;
    engPracticeStrategy: number;
    engPracticeStrategyDate: string;
    engDevelopHabit: number;
    engDevelopHabitDate: string;
    engImplementResource: number;
    engImplementResourceDate: string;
    engCoachRating: number;
    engCoachRatingDate: string;
    engCommunityParticipation: number;
    engCommunityParticipationDate: string;
    engUpdateAspect: number;
    engUpdateAspectDate: string;
    engUpdateScenario: number;
    engUpdateScenarioDate: string;
    engAdoptProgram: number;
    engAdoptProgramDate: string;
    engFeedback: number;
    engFeedbackDate: string;
    engSubmitResponse: number;
    engSubmitResponseDate: string;
    engReminder: number;
    engReminderDate: string;

    engTotalBudget: number;
    recConfigID: string;
    recConfig: IRecConfig;
    programScenarioCount: number;
    programScenarioCompletedCount: number;
    lastRoundId: string;

    coaches: IUserProfile[];
    users: IUserProfile[];
    numCoaches: number;
    numUsers: number;

    requestedHumanReview: boolean;
    imageURL: string; // Url of the user's profile image
    userStatus: string;  // Status of the user's profile (trial, active, paid, inactive)
    isOnboarding: boolean; // Indicates if a trial or active user is onboarding
    basicProfileComplete: boolean;
    programSettingsComplete: boolean;
    firstMessageComplete: boolean;
    firstRecommendationComplete: boolean;
    engagementType: string;
    programStartDate: Date;

    industry: string;
    jobFunction: string;

}

export class SimpleProfileUpdateDto {
    userProfileDto: UserProfileDto;
    recConfigDto: RecConfigDto;
    aspectPreferencesDto: AspectPreferencesDto;
    userName: string;
    accessToken?: string;
}


export class UpdateProfileDto {
    userProfile: UserProfile;
    accessToken?: string;
}

export class SubmitProfileDto {
    userProfile: UserProfile;
    dashboard: Dashboard;
    accessToken?: string;
}

export class AdminProfileDto {
    userName: string;
    userProfile: UserProfile;
    dashboard: Dashboard;
    accessToken?: string;
    users: UserProfile[];
    coaches: UserProfile[];
    aspects: Aspect[];
    scenarios: Scenario[];
}


