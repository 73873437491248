import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { environment } from '../../../environments/environment';
import { GenericString, LoginDto, SMService} from '../../services/sm.service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class AuthService
{
    private _authenticated: boolean = false;
    baseURL: string = environment.API_URL;
    registeredNotLoggedIn: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private smService: SMService,
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('accessToken') ?? '';
    }

    /**
     * Setter & getter for user name in local storage
     */
    set csUserName(user: string) {
        localStorage.setItem('csUser', user);
    }

    get csUserName(): string {
        var localuser = localStorage.getItem('csUser') ?? '';
        return localuser;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any>
    {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any>
    {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
 * Start Trial
 *
 * @param credentials
 */
    startTrial(credentials: { name: string; password: string; userType: string }): Observable<any> {

        console.log("AuthService StartTrial -------------------- ");

        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        var model = new LoginDto();
        model.username = credentials.name;
        model.name = credentials.name;
        model.password = credentials.password;
        model.userType = credentials.userType;


        var addurl = this.baseURL + 'api/neo/registertrialuser';
        console.log("AuthService BaseURL: " + this.baseURL);

        /*return this._httpClient.post<UserDto>(addurl, model).pipe(
            switchMap((response: any) => {
                console.log("AuthService response: " + of(response));
                return of(response);
            })
        );*/

        return this._httpClient.post<UserDto>(addurl, model).pipe(

            switchMap((response: any) => {

                var haserror = response.hasError;

                if (haserror == false) {
                    // Store the access token in the local storage
                    this.accessToken = response.accessToken;

                    // Set the authenticated flag to true
                    this._authenticated = true;

                    // Store the user on the user service
                    this._userService.user = response.user;

                    // Also store the user on the local storage
                    this.csUserName = response.user;

                    // Track trial user info
                    this.smService.trialUserDto.next(response);

                    // Return a new observable with the response
                    return of(response);
                } else {
                    console.log("AuthService haserror: " + haserror);
                    console.log("AuthService error: " + response.error);

                    // Return a new observable with the response
                    return of(response);
                }
            })
        );

        //console.log("AuthService newretval: " + newretval);

        //return of(retval);
    }


    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { name: string; password: string; userType: string }): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            this.signOut();
        }

        var model = new LoginDto();
        model.username = credentials.name;
        model.name = credentials.name;
        model.password = credentials.password;
        model.userType = credentials.userType;


        var addurl = this.baseURL + 'api/neo/login';
        return this._httpClient.post<UserDto>(addurl, model).pipe(

            switchMap((response: any) => {

                var haserror = response.hasError;

                if (haserror == false) {
                    // Store the access token in the local storage
                    this.accessToken = response.accessToken;

                    // Set the authenticated flag to true
                    this._authenticated = true;

                    // Store the user on the user service
                    this._userService.user = response.user;

                    // Also store the user on the local storage
                    this.csUserName = response.user;

                    // Return a new observable with the response
                    return of(response);
                } else {
                    console.log("AuthService haserror: " + haserror);
                    console.log("AuthService error: " + response.error);

                    // Return a new observable with the response
                    return of(response);
                }
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
        // Sign in using the token
        // AR: Changed api from api/auth/sign-in-with-token to api/neo/loginwithtoken
        var addurl = this.baseURL + 'api/neo/loginwithtoken';

        var genericDto = new GenericString();

        var currentuser = "";
        this._userService.user$.subscribe(
            val => {
                if (val != undefined && val != null) {
                    currentuser = val.name;
                }
            }
        );

        // Also store the user on the local storage
        if (currentuser == "") {
            currentuser = this.csUserName;
        }

        genericDto.username = currentuser;
        genericDto.continuationToken = this.accessToken;

        //return this._httpClient.post<string>(addurl, {
        return this._httpClient.post<LoginDto[]>(addurl, genericDto).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: any) => {

                // Replace the access token with the new one if it's available on
                // the response object.
                //
                // This is an added optional step for better security. Once you sign
                // in using the token, you should generate a new one on the server
                // side and attach it to the response object. Then the following
                // piece of code can replace the token with the refreshed one.
                if ( response.accessToken )
                {
                    this.accessToken = response.accessToken;
                }

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Also store the user on the local storage
                this.csUserName = response.user;

                // Set the redirect url.
                // The '/signed-in-redirect' is a dummy url to catch the request and redirect the user
                // to the correct page after a successful sign in. This way, that url can be set via
                // routing file and we don't have to touch here.
                //const redirectURL = this._activatedRoute.snapshot.queryParamMap.get('redirectURL') || '/signed-in-redirect';

                // Navigate to the redirect url
                //this._router.navigateByUrl(redirectURL);

                // Return true
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(regmodel: LoginDto)
    {
        // Need to check if user is converting from trial to active user and add some additional attributes
        if (this.smService.trialUserDto.value != null) {

            var usrdto = this.smService.trialUserDto.value;

            if (usrdto.isTrialUser == true) {
                regmodel.isTrialUser = true;
                regmodel.trialUserId = usrdto.trialUserId;
                regmodel.trialUserName = usrdto.trialUserName;
            }

        }


        var addurl = this.baseURL + 'api/neo/register';
        return this._httpClient.post<LoginDto>(addurl, regmodel).pipe(
            switchMap((response: LoginDto) => {

                // Don't set an access token right now, wait till the user actually logs in
                // Store the access token in the local storage
                //this.accessToken = response.accessToken;

                // Don't set authenticated flag just yet
                // Set the authenticated flag to true
                //this._authenticated = true;
                this.registeredNotLoggedIn = true;

                // Store the user on the user service
                // this._userService.user = response.user;

                // Also store the user on the local storage
                // this.csUserName = response.user;

                // Return a new observable with the response
                return of(response);

            })
        );


    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }

        // Valid token exists and it didn't expire so user is authorized but not logged in
        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }
}

export class UserDto {
    username: string;
    token: string;
    haserror: boolean;
    error: string;
}
