import { IUserResponse } from "./IUserResponse";

export class UserResponse implements IUserResponse {
    description: string;
    entityType: string;
    programId: string;
    programName: string;

    roundId: string;
    roundNum: number;
    userId: string;
    userName: string;

    aspectId: string;
    aspectName: string;

    scenarioId: string;
    scenarioName: string;

    scenarioRecommendationId: string;

    responseFileName: string;
    responseFileThumb: string;
    feedbackStatus: string;
    id: string;
    name: string;
    responseType: string;
    timestamp: Date;

}
