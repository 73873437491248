<div class="bg-card" *ngIf="dashboard != null">
    <div class="flex flex-col w-full bg-slate-50 pb-8">
        <ng-container>
            <div class="text-2xl md:text-5xl font-semibold tracking-tight leading-7 md:leading-snug truncate mb-6">Simulation</div>
        </ng-container>

        <!--Dashboard Panel-->
        <div class="flex flex-auto bg-card shadow rounded overflow-hidden w-full bg-slate-100 p-8">



                <!--<div class="flex items-start mt-6 sm:mt-0 sm:ml-2 space-x-3">-->
                <!--<div class="grid grid-cols-3 gap-3 bg-slate-50">-->
                <!-- Program -->
                <div class="flex-auto p-6 bg-card rounded-2xl overflow-hidden mr-3 ml-3">
                    <div class="flex justify-center">
                        <div class="text-2xl font-semibold tracking-tight leading-6 truncate mb-6">Program</div>
                    </div>
                    <div class="flex flex-col items-start mt-2 font-medium">
                        <div class="leading-6 ">{{dashboard.programName}}</div>
                        <div class="leading-6 ">Goal: {{dashboard.userGoalDescription}}</div>
                        <div class="leading-6" *ngIf="numCoaches > 0">
                            Assigned Coach(es):

                            <div *ngFor="let coach of dashboard.coaches">
                                <div>{{coach.name}}</div>
                            </div>
                            <div class="text-sm font-semibold text-red-600 dark:text-red-500" *ngIf="dashboard.requestedHumanReview">Human Coach Review Requested</div>
                        </div>
                        <div class="flex items-baseline">Start Date: {{dashboard.programStartDate | date}}</div>
                    </div>
                </div>



                <!-- Scenarios Done -->
                <div class="flex-auto bg-card rounded-2xl overflow-hidden p-6 mr-3 ml-3">
                    <div class="flex justify-center">
                        <div class="text-2xl font-semibold tracking-tight leading-6 truncate">Progress</div>
                    </div>
                    <div class="flex flex-col flex-auto mt-6 h-18">
                        <apx-chart class="flex flex-auto items-center justify-center w-full h-full"
                                   [chart]="scenariosDone.chart"
                                   [colors]="scenariosDone.colors"
                                   [labels]="scenariosDone.labels"
                                   [plotOptions]="scenariosDone.plotOptions"
                                   [series]="scenariosDone.series"
                                   [states]="scenariosDone.states">
                        </apx-chart>
                    </div>
                    <div class="flex flex-col items-center mt-2 font-medium">
                        <div class="">{{dashboard.programScenarioCompletedCount}} of {{dashboard.programScenarioCount}} Scenarios Done</div>
                        <div class="flex items-baseline leading-6 truncate">{{GetPercentComplete() | number: '1.2-2' }}% of Program</div>
                    </div>
                </div>


                <!-- Engagement -->
                <div class="flex-auto p-6 bg-card rounded-2xl overflow-hidden mr-3 ml-3">
                    <div class="flex justify-center">
                        <div class="text-2xl font-semibold tracking-tight leading-6 truncate mb-6">Score</div>
                    </div>
                    <div class="flex flex-col items-center mt-2">
                        <div class="text-7xl sm:text-8xl font-bold tracking-tight leading-none text-red-500">{{dashboard.engTotal}}</div>
                        <div class="text-lg font-medium text-red-600 dark:text-red-500">Points</div>
                        <div class="flex items-baseline justify-center w-full mt-5 font-medium">Since Program Start</div>
                    </div>
                </div>


        </div>

    </div>
</div>


