import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SMService } from '../../services/sm.service';
import { ILoadResult } from '../../models/ILoadResult';
import { UserProfile } from '../../models/UserProfile';
import { ProgramMessage } from '../../models/ProgramMessage';
import { Dashboard } from '../../models/Dashboard';
import { RecConfig } from '../../models/RecConfig';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseNavigationItem, FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { ProgramSummary } from '../../models/ProgramSummary';
import { ScenarioRecommendation } from '../../models/ScenarioRecommendation';
import { IScenarioRecommendation } from '../../models/IScenarioRecommendation';
import { UserResponse } from '../../models/UserResponse';
import { Navigation } from '../../core/navigation/navigation.types';
import { User } from '../../core/user/user.types';
import { compactNavigation, defaultNavigation, futuristicNavigation, horizontalNavigation } from '../../layout/layouts/vertical/classy/defaultnavigation';
import { NavigationService } from '../../core/navigation/navigation.service';
import { UserService } from '../../core/user/user.service';
import { cloneDeep } from 'lodash-es';
import { AuthService } from '../../core/auth/auth.service';

@Component({
  selector: 'app-userprofile',
    templateUrl: './userprofile.component.html',
    encapsulation: ViewEncapsulation.None
})
export class UserProfileComponent implements OnInit {
    baseUrl = '';
    public theURL: string;
    public thehttp: HttpClient;
    public loadResults: ILoadResult[];
    public currentResult: string;
    public currentUser: string = "";
    public programMessage: ProgramMessage;    
    public userprofile: UserProfile;
    public userType: string; // indicates trial user or regular user
    public recconfig: RecConfig;
    public dashboard: Dashboard;
    public profileLoaded: boolean;
    public isOnboarding: boolean = false;
    public isBasicProfileComplete: boolean = false;
    public isProgramSettingsComplete: boolean = false;
    public smService: SMService;
    visibleTab: string = "";
    navposition: string = 'end';

    drawerMode: 'over' | 'side' = 'side';
    drawerOpened: boolean = true;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    public programsummary = new ProgramSummary();
    private continuationToken: string = "";
    public scenariorecs: ScenarioRecommendation[];
    public userresponses: UserResponse[];
    private _navigation: FuseNavigationItem[];
    private _navComponent: FuseVerticalNavigationComponent;
    //private _navComponent: FuseHorizontalNavigationComponent;

    public hasProfileImage: boolean = false;
    public showSidebar: boolean = true;

    isScreenSmall: boolean;
    navigation: Navigation;
    user: User;

    private readonly _compactNavigation: FuseNavigationItem[] = compactNavigation;
    private readonly _defaultNavigation: FuseNavigationItem[] = defaultNavigation;
    private readonly _futuristicNavigation: FuseNavigationItem[] = futuristicNavigation;
    private readonly _horizontalNavigation: FuseNavigationItem[] = horizontalNavigation;


    constructor(
        http: HttpClient,
        private formBuilder: FormBuilder,
        sms: SMService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private route: ActivatedRoute,
        private router: Router,
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _fuseNavigationService: FuseNavigationService,
        private _authService: AuthService
        )
    {
        this.baseUrl = environment.BASE_URL;
        this.theURL = this.baseUrl;
        this.thehttp = http;
        this.smService = sms;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */

    PreLoad() {
        this.hasProfileImage = false;

        // Subscribe to navigation data
        /*this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });*/

        // Subscribe to the user service
        this._userService.user$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((user: User) => {
                this.user = user;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        console.log("Classy Component: userType: " + this.userType);

        // Load User Profile for Navigation
        this.userprofile = new UserProfile();

        var usr = this.smService.profiledetail.subscribe(profile => {

            if (profile != undefined) {
                this.userprofile = profile;
                console.log("User Profile: " + this.userprofile.userName + " - " + this.userprofile.userStatus);

                if (this.userprofile.imageURL != null && this.userprofile.imageURL != "") {
                    this.hasProfileImage = true;
                }

            } else {
                this.LoadUserProfile();
            }

        });

        var k = this.smService.isOnboarding.subscribe(response => {

            console.log("Classy Component: isOnboarding detected as: " + response);

            if (response) {
                this.showSidebar = false;
                this.userprofile.userStatus = "Trial User";
                this.userprofile.imageURL = "";
                this.userprofile.userName = this._authService.csUserName;
                this.isOnboarding = true;
                console.log("isOnboarding set to : " + this.isOnboarding + " for userStatus: " + this.userprofile.userStatus);
                console.log("Recreating Navigation");
                this.navigation = this.CreateNavigation();
            } else {
                this.showSidebar = true;
                this.userprofile.userStatus = "Active User";
                this.isOnboarding = false;
                console.log("isOnboarding set to : " + this.isOnboarding + " for userStatus: " + this.userprofile.userStatus);
            }

        });


        var y = this.smService.userType.subscribe(response => {
            /*if (this.smService.IsTrialUser()) {
                this.userprofile.userStatus = "Trial User";
                this.userprofile.imageURL = "";
                this.userprofile.userName = this._authService.csUserName;
            } else {
                this.userprofile.userStatus = "Active User";
            }*/

            this.userType = response;
        });

        this.navigation = this.CreateNavigation();



    }


    getProfileImage(): string {
        if (this.hasProfileImage) {
            var profile = this.smService.profiledetail.getValue();

            console.log("Profile Image URL: " + profile.imageURL);
            return profile.imageURL;
        }
        else {
            console.log("Profile Image URL: " + "assets/images/avatars/Avatar_placeholder.png");
            return "assets/images/avatars/Avatar_placeholder.png";
        }
    }


    CreateNavigation(): Navigation {

        // Fill compact navigation children using the default navigation
        this._compactNavigation.forEach((compactNavItem) => {
            this._defaultNavigation.forEach((defaultNavItem) => {
                if (defaultNavItem.id === compactNavItem.id) {
                    compactNavItem.children = cloneDeep(defaultNavItem.children);
                }
            });
        });

        // Fill futuristic navigation children using the default navigation
        this._futuristicNavigation.forEach((futuristicNavItem) => {
            this._defaultNavigation.forEach((defaultNavItem) => {
                if (defaultNavItem.id === futuristicNavItem.id) {
                    futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                }
            });
        });

        // Fill horizontal navigation children using the default navigation
        this._horizontalNavigation.forEach((horizontalNavItem) => {
            this._defaultNavigation.forEach((defaultNavItem) => {
                if (defaultNavItem.id === horizontalNavItem.id) {
                    horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                }
            });
        });

        var navig =
        {
            compact: cloneDeep(this._compactNavigation),
            default: cloneDeep(this._defaultNavigation),
            futuristic: cloneDeep(this._futuristicNavigation),
            horizontal: cloneDeep(this._horizontalNavigation)
        };

        // Set flag that navigation has been created.
        this.smService.navigationCreated.next(true);

        // Return the response
        return navig;

    }

    ngOnInit(): void {

        //this.PreLoad();
        this.LoadUserProfile();

        var y = this.smService.userType.subscribe(response => {
            this.userType = response;
        });

        this.visibleTab = this.smService.CurrentView;
        if (this.visibleTab == null) {
            this.visibleTab = "Dashboard";
        }

        var y = this.smService.isOnboarding.subscribe(response => {

            this.isOnboarding = response;

            console.log("UserProfile: Onboarding set to: " + this.isOnboarding);

            // Recreate the Navigation
            this.navigation = this.CreateNavigation();
            this.AddNavigation();

        });



        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {

                // Set the drawerMode and drawerOpened
                if (matchingAliases.includes('lg')) {
                    this.drawerMode = 'side';
                    this.drawerOpened = true;
                }
                else {
                    this.drawerMode = 'over';
                    this.drawerOpened = false;
                }
            });

    }

    LoadProgramSummary() {

        this.smService.GetProgramSummary(this.userprofile, this.continuationToken).subscribe(result => {

            // Set the programsummary to the result
            this.programsummary = result;
            this.smService.programName = this.programsummary.programName;
            this.userresponses = this.programsummary.userResponses;

            // Sort the scenarios by round
            this.scenariorecs = this.programsummary.scenarioRecommendations;

            // Get the user responses from the program summary and match them to the scenario recommendations
            // Loop through the responses and match them to the scenario recs
            this.userresponses.forEach(response => {

                // Loop through all scenario recs and print out all the scenario recs to the console with scenario name and scenario rec id
                //this.scenariorecs.forEach(s => {

                    // Convert all the scenario recs to json format
                    //console.log("Scenario Name: " + s.scenarioId + " Scenario Rec ID: " + s.id);
                    //console.log(JSON.stringify(s));
                //});

                // Find the scenario rec that matches the response
                var scenariorec = this.scenariorecs.find(s => s.id == response.scenarioRecommendationId);

                // If found, add the response to the scenario rec
                if (scenariorec != undefined) {
                    scenariorec.responseURL = response.responseFileName;
                    scenariorec.responseFileName = response.responseFileName;
                    scenariorec.responseThumbURL = response.responseFileThumb;
                    scenariorec.feedbackStatus = response.feedbackStatus;

                    // replace the scenario rec in the list of scenariorecs
                    this.scenariorecs.forEach(s => {

                        // Get the scenario rec that matches the response
                        if (s != undefined && scenariorec != undefined) {
                            if (s.id == scenariorec.id) {
                                s = scenariorec;
                            }
                        }
                    });
                }

            });


            this.SortScenarioRecs();

            // Store the program Summary for use throughout the app
            this.programsummary.scenarioRecommendations = this.scenariorecs;
            this.smService.programSummary = this.programsummary;

        }, error => console.error(error));

    }

    public SortScenarioRecs(): void {

        // Sort the scenario recs by roundNum so the first message is on top
        this.scenariorecs.sort((a, b) => (a.roundNum < b.roundNum) ? 1 : -1);
    }

    AddNavigation() {

        var isNavigCreated = this.smService.navigationCreated.getValue();
        console.log("Navigation Created: " + isNavigCreated);

        // Get the component -> navigation data -> item
        this._navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>("defaultNavigation");
        //this._navComponent = this._fuseNavigationService.getComponent<FuseHorizontalNavigationComponent>("defaultNavigation");

        // Return if the navigation component does not exist
        if (!this._navComponent) {
            return null;
        }

        // Get the navigation item, update the badge and refresh the component
        this._navigation = this._navComponent.navigation;

        // Create the required navigation items for this section

        var progtitle = "";
        console.log("Program Name: " + this.programsummary.programName);
        if (this.programsummary.programName == null || this.programsummary.programName == '' || this.programsummary.programName == undefined) {
            progtitle = "Presentation Program";
        } else {
            progtitle = "Program";
        }

        console.log("Adding navigation disabled onboarding status set to: " + this.isOnboarding);

        var navProgram: FuseNavigationItem = {
            id: 'program',
            title: progtitle,
            subtitle: this.programsummary.programName,
            type: 'basic',
            function: (item: FuseNavigationItem) => this.showProgram(),
            link: '/admintest/layout/userprofile/programsummarylist',
            icon: 'view_list',
            children: [],
            disabled: this.isOnboarding
        };

        var navProfile: FuseNavigationItem = {
            id: 'profile',
            title: 'Profile',
            subtitle: 'User Configuration',
            type: 'basic',
            function: (item: FuseNavigationItem) => this.showProfile(),
            link: '/admintest/layout/userprofile/profiledetail',
            icon: 'manage_accounts',
            disabled: this.isOnboarding
        };


        // Add the navigation items
        if (this._fuseNavigationService != undefined) {
            const skillsgroup = this._fuseNavigationService.getItem("skills", this._navigation);
            skillsgroup.children = [];
            skillsgroup.children.push(navProgram);

            const configgroup = this._fuseNavigationService.getItem("config", this._navigation);
            configgroup.children = [];
            configgroup.children.push(navProfile);

        }

        // Refresh the navigation component
        this._navComponent.navigation = this._navigation;
        this._navComponent.refresh();
        this.navigation.default = this._navigation;

        var x = 0;
    }

    AddScenariosToNavigation(programgroup: FuseNavigationItem): FuseNavigationItem {

        // Create a navigation item for each scenario rec
        this.scenariorecs.forEach(scenario => {

            // Create the navigation item
            var navScenario: FuseNavigationItem = {
                id: scenario.scenarioId,
                title: scenario.title,
                type: 'basic',
                function: (item: FuseNavigationItem) => this.ShowScenarioDetail(item),
                children: [],
                meta: scenario,
                icon: 'video_label'
            };

            // Create sub links for each scenario, response, and feedback screens
            //navScenario = this.AddScenarioLinksToNavigation(navScenario, scenario);
            programgroup.children.push(navScenario);

        });
        return programgroup;
    }

    ShowScenarioDetail(menuItem: FuseNavigationItem): void {
        // Set the current scenario
        if (menuItem != null) {
            this.smService.CurrentScenarioRec.next(menuItem.meta);

            // Navigate the router to the appropriate screen
            this.router.navigate(['admintest', 'layout', 'userprofile', 'programscenariodetail', 'viewscenario']);
        }
    }

    // Adding the scenario, response, and feedback screens to the navigation made it too noisy.
    AddScenarioLinksToNavigation(navScenario: FuseNavigationItem, scenario: IScenarioRecommendation): FuseNavigationItem {

        // Add children to the navScenario for each scenario, response, and feedback screens
        var navScenView: FuseNavigationItem = {
            id: "viewscenario-" + scenario.scenarioId,
            title: 'Scenario',
            type: 'basic',
            icon: 'ondemand_video',
            function: (item: FuseNavigationItem) => this.ShowItemDetail(item),
            meta: scenario
        };

        var navResponse: FuseNavigationItem = {
            id: "viewresponse-" + scenario.scenarioId,
            title: 'Response',
            type: 'basic',
            icon: 'video_camera_front',
            function: (item: FuseNavigationItem) => this.ShowItemDetail(item),
            meta: scenario
        };

        var navFeedback: FuseNavigationItem = {
            id: "viewfeedback-" + scenario.scenarioId,
            title: 'Feedback',
            type: 'basic',
            icon: 'feedback',
            function: (item: FuseNavigationItem) => this.ShowItemDetail(item),
            meta: scenario
        };

        navScenario.children.push(navScenView);
        navScenario.children.push(navResponse);
        navScenario.children.push(navFeedback);

        return navScenario;

    }

    ShowItemDetail(menuItem: FuseNavigationItem) {


        // Set the current scenario
        if (menuItem != null) {
            this.smService.CurrentScenarioRec.next(menuItem.meta);

            // Navigate the router to the appropriate screen
            if (menuItem.title == "Scenario") {
                this.router.navigate(['admintest', 'layout', 'userprofile', 'programscenariodetail', 'viewscenario'])
            } else if (menuItem.title == "Response") {
                this.router.navigate(['admintest', 'layout', 'userprofile', 'programscenariodetail', 'viewresponse'])
            } else if (menuItem.title == "Feedback") {
                this.router.navigate(['admintest', 'layout', 'userprofile', 'programscenariodetail', 'viewfeedback'])
            }
        }
    }

    private LoadUserProfile() {
        // Update the UserProfile
        this.smService.GetUserProfile().subscribe(result => {
            this.userprofile = result.userProfile;
            this.recconfig = result.userProfile.recConfig;
            this.dashboard = result.dashboard;
            this.profileLoaded = true;

            this.smService.dashboard = this.dashboard;
            this.smService.recconfig = this.recconfig;

            // Set the profile detail observable
            this.smService.profiledetail.next(this.userprofile);


            if (this.userprofile.userStatus != "Onboarding") {
                if (this.userprofile.imageURL != null && this.userprofile.imageURL != "") {
                    this.hasProfileImage = true;
                }
            } else {
                this.smService.isOnboarding.next(true);
            }

            this.isBasicProfileComplete = this.userprofile.basicProfileComplete;
            this.isProgramSettingsComplete = this.userprofile.programSettingsComplete;

            console.log("Loaded UserProfile");
            console.log("UserProfile is: " + this.userprofile.userName + " - " + this.userprofile.userStatus + " - " + this.userprofile.imageURL);

            // Recreate the Navigation
            this.navigation = this.CreateNavigation();
            this.AddNavigation();

            // Load the program summary
            this.LoadProgramSummary();

        }, error => console.error(error));
    }

    showDashboard() {
        this.router.navigate(['admintest', 'layout', 'userprofile', 'fulldashboard'])
            .then(nav => {
                console.log(nav); // true if navigation is successful
            }, err => {
                console.log(err) // when there's an error
            });

    }

    showProgram() {
        //this.Navigate("programsummarylist");

        // Reset any current scenario and response selections
        /*if (this.smService.CurrentScenarioRec != null) {
            this.smService.CurrentScenarioRec.next(null); 
        }

        if (this.smService.CurrentResponse != null) {
            this.smService.CurrentResponse.next(null);
        }

        if (this.smService.VideoSource != null) {
            this.smService.VideoSource.next(null);
        }*/

        const programItem = this._fuseNavigationService.getItem("program", this._navigation);
        programItem.active = true;
        const profileItem = this._fuseNavigationService.getItem("profile", this._navigation);
        profileItem.active = false;
        this._navComponent.refresh();

        this.router.navigate(['admintest', 'layout', 'userprofile', 'programsummarylist'])
            .then(nav => {
                console.log(nav); // true if navigation is successful
            }, err => {
                console.log(err) // when there's an error
            });
    }

    showProfile() {

        const programItem = this._fuseNavigationService.getItem("program", this._navigation);
        programItem.active = false;
        const profileItem = this._fuseNavigationService.getItem("profile", this._navigation);
        profileItem.active = true;
        this._navComponent.refresh();

        this.router.navigate(['admintest', 'layout', 'userprofile', 'profiledetail'])
            .then(nav => {
                console.log(nav); // true if navigation is successful
            }, err => {
                console.log(err) // when there's an error
            });
    }

    showAdvanced() {
        this.router.navigate(['admintest', 'layout', 'userprofile', 'recconfig'])
            .then(nav => {
                console.log(nav); // true if navigation is successful
            }, err => {
                console.log(err) // when there's an error
            });
    }
    
    private Navigate(tab: string) {
        /*this.router.navigate(['/home', tab])
            .then(nav => {
                console.log(nav); // true if navigation is successful
            }, err => {
                console.log(err);
            });*/
            


        /*this.router.navigate([{ outlets: { program: ['home/', tab] } }], { relativeTo: this.route.parent })
            .then(nav => {
                console.log(nav); // true if navigation is successful
            }, err => {
                console.log(err);
            });*/
    }

    public OnboardingStepDone($event) {

        if ($event == "BasicProfileComplete") {
            this.isBasicProfileComplete = true;
            this.LoadUserProfile();
        }

        if ($event == "ProgramSettingsComplete") {
            this.isProgramSettingsComplete = true;
            this.LoadUserProfile();
        }

        if ($event == "OnboardingComplete") {
            this.isOnboarding = false;
            this.LoadUserProfile();
        }

        if ($event == "FirstMessageSent") {
            // do something
        }

        if ($event == "FirstRecommendationReceived") {
            this.isOnboarding = false;
        }
    }

    toggleSideNav() {

        if (this.drawerOpened) {
            this.drawerOpened = false;
        }
        else {
            this.drawerOpened = true;
        }

    }

}

