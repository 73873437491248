import { Component, Inject, Input, OnInit, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApexOptions } from 'ng-apexcharts';
import { ILoadResult } from '../../../../models/ILoadResult';
import { ProgramMessage } from '../../../../models/ProgramMessage';
import { Dashboard } from '../../../../models/Dashboard';
import { SMService } from '../../../../services/sm.service';
import { environment } from '../../../../../environments/environment';
import { UserProfile } from '../../../../models/UserProfile';
import { MatTableDataSource } from '@angular/material/table';
import { Scenario } from '../../../../models/Scenario';
import { SelectionModel } from '@angular/cdk/collections';
import { FuseAlertType } from '@fuse/components/alert';
import { ProgramSummary } from '../../../../models/ProgramSummary';
import { ScenarioRecommendation } from '../../../../models/ScenarioRecommendation';
import { ActivatedRoute, Router } from '@angular/router';
import { UserResponse } from '../../../../models/UserResponse';
import { v4 as uuidv4 } from 'uuid';
import { formatPercent } from '@angular/common';
import { DefaultValues } from '../../../../models/DefaultValues';
import { ScoringSummary } from '../../../../models/ScoringSummary';

export enum SelectType {
    single,
    multiple
}


@Component({
  selector: 'app-programsummarylist',
  templateUrl: './programsummarylist.component.html'
})
export class ProgramSummaryListComponent implements OnInit {
    baseUrl = '';
    public theURL: string;
    public thehttp: HttpClient;
    public graphLoadForm: FormGroup;
    public loadResults: ILoadResult[];
    public currentResult: string;
    public currentUser: string = "";
    public currentProfile: UserProfile;
    
    private continuationToken: string = "";

    public programMessage: ProgramMessage;
    public testmessage: string = "Enter message...";


    public smService: SMService;
    @Input() streamForUser: UserProfile; // for coaching UX
    public programsummary: ProgramSummary = new ProgramSummary(); 

    public scenariorecs: ScenarioRecommendation[];
    public selectedItem: ScenarioRecommendation;
    public userresponses: UserResponse[];
    public showItemDetail: boolean = false;

    public isOnboarding: boolean = false;
    public showProgramSummary: boolean = true;
    public acceptedPoints: boolean = false;
    public showDetails: boolean = true;


    pointsColumns: string[] = [
        "displayName",
        "points"
    ];


    displayedColumns: string[] = [
        "roundNum",
        "title",
        "difficulty",
        //"estTimeInvestment",
        "resourceURL",
        "responseThumbURL",
        "feedbackStatus",
        "details"
    ];

    selectType = [
        { text: "Single", value: SelectType.single },
        { text: "Multiple", value: SelectType.multiple }
    ];

    pointsdataSource = new MatTableDataSource<ScoringSummary>();
    totalPoints: number = 0;

    dataSource = new MatTableDataSource<ScenarioRecommendation>();
    selection = new SelectionModel<ScenarioRecommendation>(true, []);
    displayType = SelectType.single;

    alert: { type: FuseAlertType; message: string } = {
        type: 'success',
        message: 'Getting new Recommendation.  This may take a few moments.  Please Wait...'
    };
    showAlert: boolean = false;

    // For testing purposes
    testalert: { type: FuseAlertType; message: string } = {
        type: 'success',
        message: 'Getting new Recommendation.  This may take a few moments.  Please Wait...'
    };

    chartNewVsReturning: ApexOptions;
    public showProgressChart: boolean = false;
    public progressChartData: any;

  constructor(
    http: HttpClient,
    private formBuilder: FormBuilder,
      private sms: SMService,
      private route: ActivatedRoute,
      private router: Router
  )
  {
    this.baseUrl = environment.BASE_URL;
    this.theURL = this.baseUrl;
    this.thehttp = http;
    this.smService = sms;
  }

    selectHandler(row: ScenarioRecommendation) {
        if (this.displayType == SelectType.single) {
            if (!this.selection.isSelected(row)) {
                this.selection.clear();
            }
        }
        this.selection.toggle(row);
    }

    onChange(typeValue: number) {
        this.displayType = typeValue;
        this.selection.clear();
    }

    ngOnInit(): void {

        // Get the program summary for the user
        this.LoadProgramSummary();

        // Attach SVG fill fixer to all ApexCharts
        window['Apex'] = {
            chart: {
                events: {
                    mounted: (chart: any, options?: any): void => {
                        this._fixSvgFill(chart.el);
                    },
                    updated: (chart: any, options?: any): void => {
                        this._fixSvgFill(chart.el);
                    }
                }
            }
        };

        var y = this.smService.isOnboarding.subscribe(isOnboarding => {
            if (isOnboarding) {
                this.isOnboarding = true;
            } else {
                this.isOnboarding = false;
            }
        });


        if (this.isOnboarding && !this.acceptedPoints) {
            this.showDetails = false;
            this.showProgramSummary = false;
        }

        // Show the alert if needed
        if (this.isOnboarding && !this.acceptedPoints) {
            this.alert = {
                type: 'success',
                message: 'The Cogsolo program consists of levels.  Each level has 10 scenarios.  You earn points by completing scenarios but also by engaging with the platform regularly and practicing.'
            };
            this.showAlert = true;
        }

    }

    public getProgramLevel(): number {
        var level = 0;
        if (this.programsummary != undefined) {
            level = this.programsummary.programLevel;
        }
        level = level + 1;
        return level;
    }


    public LoadProgramSummary(): void {

        // Reload the program summary from the sm service.  
        this.currentProfile = this.streamForUser;
        console.log("Getting Program Summary");
        this.smService.GetProgramSummary(this.currentProfile, this.continuationToken).subscribe(result => {

            // Set the programsummary to the result
            this.programsummary = result;
            if (this.programsummary == null) {
                console.log("No program summary found.");
                return;
            }

            this.smService.programName = this.programsummary.programName;
            this.scenariorecs = this.programsummary.scenarioRecommendations;

            //console.log("Retrieved Scenario Recommendations: " + this.scenariorecs.length);

            // Not all scenario recs have feedback points, so reset them all to 0
            this.scenariorecs.forEach(scenariorec => {
                scenariorec.feedbackPoints = 0;
            });

            // Only display the top 3 reasons for each scenario
            this.scenariorecs.forEach(scenariorec => {
                if (scenariorec.reasons != undefined && scenariorec.reasons != null) {

                    // Sort the reasons by descending order of reason Weight value
                    scenariorec.reasons.sort((a, b) => (a.reasonWeight < b.reasonWeight) ? 1 : -1);

                    // Retain only the top 3 reasons
                    var count = 0;
                    var topReasons = new Array();
                    scenariorec.reasons.forEach(reason => {
                        if (count < 3) {
                            topReasons.push(reason);
                            count++;
                        }
                    });
                    scenariorec.reasons = topReasons;

                }
            });

            // Get the user responses from the program summary and match them to the scenario recommendations
            // Loop through the responses and match them to the scenario recs
            this.userresponses = this.programsummary.userResponses;
            this.userresponses.forEach(response => {

                // Find the scenario rec that matches the response
                var scenariorec = this.scenariorecs.find(s => s.id == response.scenarioRecommendationId);

                // If found, add the response to the scenario rec
                if (scenariorec != undefined) {
                    scenariorec.responseURL = response.responseFileName;
                    scenariorec.responseFileName = response.responseFileName;
                    scenariorec.responseThumbURL = response.responseFileThumb;
                    scenariorec.feedbackStatus = response.feedbackStatus;
                    scenariorec.responseDate = response.timestamp;

                    // A single scenario could have multiple responses and takes
                    if (scenariorec.userResponses == null || scenariorec.userResponses == undefined) {
                        scenariorec.userResponses = [];
                    }
                    scenariorec.userResponses.push(response);

                    // replace the scenario rec in the list of scenariorecs
                    this.scenariorecs = this.scenariorecs.map(s => s.id == scenariorec.id ? scenariorec : s);

                }

            });

            // Sort each scenario rec's user responses by timestamp in ascending order
            this.scenariorecs.forEach(scenariorec => {
                if (scenariorec.userResponses != null && scenariorec.userResponses != undefined) {
                    scenariorec.userResponses.sort((a, b) => (a.timestamp > b.timestamp) ? 1 : -1);
                }
            });

            // loop through the feedbackoutputs and determine the one with the most recent timestamp
            // and set the feedback status on the scenario rec
            this.programsummary.feedbackOutputs.forEach(fbo => {
                var scenariorec = this.scenariorecs.find(s => s.id == fbo.scenarioRecommendationId);
                if (scenariorec != undefined) {
                    if (scenariorec.feedbackLastDate == undefined || scenariorec.feedbackLastDate == null) {
                        scenariorec.feedbackLastDate = fbo.timestamp;
                    } else {
                        if (fbo.timestamp > scenariorec.feedbackLastDate) {
                            scenariorec.feedbackLastDate = fbo.timestamp;
                        }
                    }
                }
            });

            // Set the feedback points on the scenario recs
            // Loop through the scenario recs and get all feedback outputs for each
            this.scenariorecs.forEach(scenariorec => {

                // Loop through the feedback outputs and get the points for each
                var feedbackOutputs = this.programsummary.feedbackOutputs.filter(fbo => fbo.scenarioRecommendationId == scenariorec.id);
                feedbackOutputs.forEach(fbo => {
                    if (fbo.feedbackScore != undefined && fbo.feedbackScore != null && fbo.feedbackLevel == 'Raw') {
                        scenariorec.feedbackPoints += fbo.feedbackScore;
                    }
                });

            });


            // Sort the scenarios by round
            this.SortScenarioRecs();

            // Store the program Summary for use throughout the app
            this.programsummary.scenarioRecommendations = this.scenariorecs;
            this.smService.programSummary = this.programsummary;

            // Set the data source for the table
            this.dataSource = new MatTableDataSource<ScenarioRecommendation>(this.scenariorecs);

            console.log("Data Source set to " + this.dataSource.data.length + " items.");

            // Set the points data source
            this.SetPointsDataSource();

        }, error => console.error(error));

    }

    SetPointsDataSource() {

        var pointSummaries = new Array<ScoringSummary>();

        var points = 0;
        var remainingPoints = 0;
        if (this.programsummary != undefined) {

            // loop through the scoringSummaries and create a subset to display
            if (this.programsummary.scoringSummaries != undefined && this.programsummary.scoringSummaries != null) {

                // First, sort the scoring summaries by points
                this.programsummary.scoringSummaries.sort((a, b) => (a.points < b.points) ? 1 : -1);

                // Then, take the top 4
                var count = 0;
                this.programsummary.scoringSummaries.forEach(ss => {
                    if (count < 3) {
                        if (ss.points > 0) {
                            pointSummaries.push(ss);
                        }
                        count++;
                    } else {

                        // Sum the remaining summaries by points
                        remainingPoints += ss.points;
                    }

                    points += ss.points;

                });

                // Create a new summary for the remaining points if needed
                if (remainingPoints > 0) {
                    var remainingSummary = new ScoringSummary();
                    remainingSummary.displayName = "Other Factors";
                    remainingSummary.description = "Points earned from other engagement.";
                    remainingSummary.points = remainingPoints;
                    pointSummaries.push(remainingSummary);
                }

            } else {
                points = this.programsummary.engTotal;
            }

            // Prepare the chart data
            this._prepareChartData();

        }

        // Set the total points
        this.totalPoints = points;

        // Set the data source
        this.pointsdataSource = new MatTableDataSource<ScoringSummary>(pointSummaries);


    }

    public AcceptPoints(): void {

        // Show the alert
        this.alert = {
            type: 'success',
            message: 'Your customized program is below.  Click on the Get Next Best Scenario button to request your next scenario.'
        };
        this.showDetails = false;
        this.showProgramSummary = true;
        this.acceptedPoints = true;
        this.showAlert = true;

    }

    public getCompleted(): number {
        var completed = 0;
        if (this.scenariorecs != undefined) {
            completed = this.scenariorecs.filter(s => s.feedbackStatus == "Posted").length;
        }
        return completed;
    }

    public getCompletion(): number {
        var total = 0;
        var perc = 0.0;
        if (this.programsummary != undefined) {
            total = this.programsummary.programScenarioCount;
        }

        var completed = 0;
        if (this.scenariorecs != undefined) {
            completed = this.scenariorecs.filter(s => s.feedbackStatus == "Posted").length;
        }

        //console.log("Total: " + total);
        //console.log("Completed: " + completed);

        if (total == 0) {
            perc = 0.0;
        } else {
            perc = (completed / total) * 100;
        }

        //console.log("Completion Perc is: " + perc + "%")

        return perc;
    }

    public getPoints(): number {

        return this.totalPoints;

    }

    public getItemPoints(scenariorec: ScenarioRecommendation): string {
        var pts = 0;
        if (scenariorec != undefined) {
            if (scenariorec.feedbackPoints != null) {
                pts = scenariorec.feedbackPoints;
            }
        }

        return pts + " pts";
    }

    public getFeedbackStatus(scenariorec: ScenarioRecommendation): string {

        var status = "No Response"; // default

        if (scenariorec != undefined) {
            if (scenariorec.feedbackStatus != null) {
                status = scenariorec.feedbackStatus;
            }
        }

        // Check if any responses have been posted for the scenario rec
        if (scenariorec.userResponses != undefined && scenariorec.userResponses != null) {
            var posted = scenariorec.userResponses.filter(ur => ur.feedbackStatus == "Posted");
            if (posted != undefined && posted != null && posted.length > 0) {
                status = "Posted";
            }
        }

        return status;
    }

    public SortScenarioRecs(): void {
        // Sort the scenario recs by roundNum so the first message is on top
        this.scenariorecs.sort((a, b) => (a.roundNum < b.roundNum) ? 1 : -1);
    }

    public getScenarioThumbUrl(scenariorec) {

        // Get the thumbnail url for the scenario rec
        var thumbUrl = scenariorec.resourceThumbURL;
        if (thumbUrl == undefined || thumbUrl == null || thumbUrl == "") {
            thumbUrl = "assets/images/no_img_available.png";
        }
        //console.log("ThumbUrl: " + thumbUrl);
        return thumbUrl;
    }

    public getLastResponseThumbUrl(scenariorec) {

        // Get the thumbnail url for the item
        var thumbUrl = scenariorec.responseThumbURL;

        if (scenariorec != undefined) {
            // Find the last response for the scenario rec
            var lastResponse = this.getLastResponse(scenariorec);
            thumbUrl = lastResponse.responseFileThumb;
        }

        if (thumbUrl == undefined || thumbUrl == null || thumbUrl == "") {
            thumbUrl = "assets/images/no_response_yet.png";
        }

        return thumbUrl;
    }

    public getLastResponseDate(scenariorec): Date {

        // Get the response date of the last response for the scenario rec

        var lastDate = new Date();
        if (scenariorec != undefined) {
            // Find the last response for the scenario rec
            var lastResponse = this.getLastResponse(scenariorec);
            lastDate = lastResponse.timestamp;
        }

        return lastDate;
    }

    public getLastResponse(scenariorec): UserResponse {

        // Get the response date of the last response for the scenario rec

        var lastResp = new UserResponse();
        if (scenariorec != undefined) {

            // Find the last response for the scenario rec
            if (scenariorec.userResponses != undefined && scenariorec.userResponses != null) {

                // Compare the timestamps of the responses to find the most recent
                lastResp = scenariorec.userResponses.reduce((prev, current) => (prev.timestamp > current.timestamp) ? prev : current);

            }
        }

        return lastResp;
    }




    ShowItemDetail(item) {
        this.selectedItem = item;

        // Reset any current scenario and response selections
        this.smService.CurrentScenarioRec.next(this.selectedItem);
        this.smService.CurrentResponse.next(null);
        this.smService.VideoSource.next(null);
        this.smService.VideoSourceThumb.next(null);
        this.smService.NewResponse.next(null);
        this.smService.VideoSource.next(this.selectedItem.resourceURL);
        this.smService.VideoSourceThumb.next(this.selectedItem.resourceThumbURL);

        // Clear any starting and talking points in smService
        this.smService.startingPoints = null;
        this.smService.talkingPoints = null;


        // Get the feedback outputs from the program summary corresponding to this item
        var fbos = this.programsummary.feedbackOutputs.filter(s => s.scenarioId == this.selectedItem.scenarioId && s.roundId == this.selectedItem.roundId);

        // If found, store the feedback outputs for use with the current scenario and user response
        this.smService.feedbackOutputs = [];
        if (fbos != undefined && fbos != null) {

            // Push each feedback output into the smService feedbackOutputs array
            fbos.forEach(fbo => {
                this.smService.feedbackOutputs.push(fbo);
            });

        }

        // Onboarding for a trial user ends here
        this.EndUserOnboarding();

        this.router.navigate(['admintest', 'layout', 'userprofile', 'programscenariodetail', 'viewscenario'])
            .then(nav => {
                //console.log(nav); // true if navigation is successful
            }, err => {
                console.log(err) // when there's an error
            });
            
    }

    EndUserOnboarding(): void {
        this.smService.EndUserOnboarding(this.currentProfile).subscribe(result => {

            // Toggle the isOnboarding value to its opposite
            this.isOnboarding = false;
            this.smService.SetOnboarding(this.isOnboarding);

            //this.smService.userType.next('Active User');
            this.currentProfile = result;

        },
            (result) => {

                // Create alert from the response errors
                this.alert = {
                    type: 'error',
                    message: 'Something went wrong, please try again.'
                };

                // Show the alert
                //this.showAlert = true;

            });
    }


    GetNewRecommendation(): void {

        // Show the alert
        this.alert = {
            type: 'success',
            message: 'Getting new Recommendation.  This may take a few moments.  Please Wait...'
        };
        this.showAlert = true;

        // Send to Server
        //console.log("Getting a New Recommendation: ");

        var progMessage = this.GetNewProgramMessage();
        progMessage.messageText = "Get New Recommendation";

        this.smService.GetNewRecommendation(progMessage).subscribe(result => {

            //console.log("Confirmed getting new recommendation.");

            // Reload the program summary
            // TODO - Refactor this to only pull the new recommendation and add it to the list
            this.LoadProgramSummary();

            // Show the alert if needed
            if (this.isOnboarding) {
                this.alert = {
                    type: 'success',
                    message: 'You have a new Recommendation.  Click on the Details Button to view it.'
                };
                this.showDetails = true;
                this.showAlert = true;
            } else {

                this.showAlert = false;
            }

        },
            (result) => {

                // Create alert from the response errors
                this.alert = {
                    type: 'error',
                    message: 'Something went wrong, please try again.'
                };

                // Show the alert
                this.showAlert = true;

            });

    }

    GetNewProgramMessage(): ProgramMessage {
        // Create a new message object and populate it with the message
        this.programMessage = new ProgramMessage();

        // Deprecated - no longer created on client
        this.programMessage.id = uuidv4(); // Of format '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'

        this.programMessage.name = "New User Message";
        this.programMessage.programId = this.programsummary.programId;


        // User Info
        var userInfo = this.smService.GetCurrentUser();

        this.programMessage.messageType = "UserMessage";
        this.programMessage.streamForUserName = userInfo.username;
        this.programMessage.userId = uuidv4();
        this.programMessage.userName = userInfo.username;
        this.programMessage.authorId = uuidv4();
        this.programMessage.authorName = userInfo.username;
        this.programMessage.messageDate = new Date();
        this.programMessage.messageNumber = this.GetNextMessageNum();
        this.programMessage.entityType = "ProgramMessage";
        this.programMessage.messageText = this.testmessage;
        this.programMessage.status = "Open";
        this.programMessage.engagementType = "NewRecommendation";

        /*if (this.currentUserProfile != null) {
            this.programMessage.imageURL = this.currentUserProfile.imageURL;
        }*/

        return this.programMessage;
    }

    // TODO - no need to use message numbering.  Refactor Get Recs to not use messaging infrastructure
    // Messaging will be just for the side chat going forward.
    GetNextMessageNum(): number {
        return this.programsummary.userResponses.length + 1;
    }

    /**
     * Fix the SVG fill references. This fix must be applied to all ApexCharts
     * charts in order to fix 'black color on gradient fills on certain browsers'
     * issue caused by the '<base>' tag.
     *
     * Fix based on https://gist.github.com/Kamshak/c84cdc175209d1a30f711abd6a81d472
     *
     * @param element
     * @private
     */
    private _fixSvgFill(element: Element): void {
        // Current URL
        const currentURL = this.router.url;

        // 1. Find all elements with 'fill' attribute within the element
        // 2. Filter out the ones that doesn't have cross reference so we only left with the ones that use the 'url(#id)' syntax
        // 3. Insert the 'currentURL' at the front of the 'fill' attribute value
        Array.from(element.querySelectorAll('*[fill]'))
            .filter(el => el.getAttribute('fill').indexOf('url(') !== -1)
            .forEach((el) => {
                const attrVal = el.getAttribute('fill');
                el.setAttribute('fill', `url(${currentURL}${attrVal.slice(attrVal.indexOf('#'))}`);
            });
    }

    /**
     * Prepare the chart data from the data
     *
     * @private
     */
    private _prepareChartData(): void {

        var completed = this.getCompletion();
        completed = Math.round(completed);
        var remaining = 100 - completed;
        remaining = Math.round(remaining);

        //console.log("Completed: " + completed);
        //console.log("Remaining: " + remaining);

        this.progressChartData = {
            labels: ['Done', 'Left in Level'],
            series: [completed, remaining]
        }


        // New vs. returning
        this.chartNewVsReturning = {
            chart: {
                animations: {
                    speed: 400,
                    animateGradually: {
                        enabled: false
                    }
                },
                fontFamily: 'inherit',
                foreColor: 'inherit',
                height: '100%',
                type: 'donut',
                sparkline: {
                    enabled: true
                }
            },
            colors: ['#0099DD', '#9ca3af'],
            labels: this.progressChartData.labels,
            plotOptions: {
                pie: {
                    customScale: 0.9,
                    expandOnClick: false,
                    donut: {
                        size: '70%'
                    }
                }
            },
            series: this.progressChartData.series,
            states: {
                hover: {
                    filter: {
                        type: 'none'
                    }
                },
                active: {
                    filter: {
                        type: 'none'
                    }
                }
            },
            tooltip: {
                enabled: true,
                fillSeriesColor: false,
                theme: 'dark',
                custom: ({
                    seriesIndex,
                    w
                }): string => `<div class="flex items-center h-8 min-h-8 max-h-8 px-3">
                                                    <div class="w-3 h-3 rounded-full" style="background-color: ${w.config.colors[seriesIndex]};"></div>
                                                    <div class="ml-2 text-md leading-none">${w.config.labels[seriesIndex]}:</div>
                                                    <div class="ml-2 text-md font-bold leading-none">${w.config.series[seriesIndex]}%</div>
                                                </div>`
            }
        };


        this.showProgressChart = true;
    }
 }

