import { IFeedbackOutput } from './IFeedbackOutput';
import { IProgramSummary } from './IProgramSummary';
import { IScenarioRecommendation } from './IScenarioRecommendation';
import { IScoringSummary } from './IScoringSummary';
import { IUserResponse } from './IUserResponse';

export class ProgramSummary implements IProgramSummary {
    id: string;
    name: string;
    partitionKey: string;
    rowKey: string;
    userId: string;
    userName: string;
    entityType: string;
    scenarioRecommendations: IScenarioRecommendation[];
    continuationToken: string;
    programId: string;
    programName: string;
    engSurprise: number;
    engSurpriseDate: string;
    engTotal: number;
    engAdoptProgram: number;
    engCoverage: number;
    engMastery: number;
    engProgress: number;
    engDiscover: number;
    engStrengths: number;
    engJourney: number;
    engPriority: number;
    usageIntentionOne: string;
    usageIntentionTwo: string;
    usageIntentionThree: string;
    engBudget: number;
    programStartDate: Date;
    programScenarioCount: number;
    programScenarioCompletedCount: number;
    programLevelScenarioCount: number;
    programLevelScenarioCompletedCount: number;
    numRecsMade: number;
    numRounds: number;
    roundId: string;
    roundNum: number;
    programLevel: number;
    userResponses: IUserResponse[];
    feedbackOutputs: IFeedbackOutput[];
    scoringSummaries: IScoringSummary[];
}
