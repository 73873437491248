import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { SharedModule } from '../../../../shared/shared.module';
import { VideoUploaderComponent } from './videouploader.component';



/*const exampleRoutes: Route[] = [
    {
        path     : '',
        component: ProgramMessageComponent
    }
];*/

@NgModule({
    declarations: [
        VideoUploaderComponent
    ],
    imports     : [
        //RouterModule.forChild(exampleRoutes),
        SharedModule,
    ],
    exports: [
        VideoUploaderComponent
    ]
})
export class VideoUploaderModule
{
}
