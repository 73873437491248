import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { UserProfileComponent } from './userprofile.component';

const routes: Route[] = [
    {
        path: '',
        component: UserProfileComponent,
        children: [
            { path: 'programsummarylist', loadChildren: () => import("./programsummary/programsummarylist/programsummarylist.module").then(m => m.ProgramSummaryListModule) },
            { path: 'programscenariodetail', loadChildren: () => import("./programsummary/programscenariodetail/programscenariodetail.module").then(m => m.ProgramScenarioDetailModule) },
            { path: 'profiledetail', loadChildren: () => import("./profiledetail/profiledetail.module").then(m => m.ProfileDetailModule) },
            { path: 'fulldashboard', loadChildren: () => import("./fulldashboard/fulldashboard.module").then(m => m.FullDashboardModule) },
            { path: 'recconfig', loadChildren: () => import("./recconfig/recconfig.module").then(m => m.RecConfigModule) },
        ]
    }

];




@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class UserProfileRoutingModule { }
