<div class="flex flex-col w-full overflow-hidden">
    <p class="text-2xl font-semibold">Trial Onboarding:</p>
    <mat-stepper [linear]="false" #stepper>
        <mat-step [completed]="stepOne">
            <form [formGroup]="firstFormGroup">
                <ng-template matStepLabel>Watch a Scenario</ng-template>
            </form>
        </mat-step>
        <mat-step [completed]="stepTwo">
            <form [formGroup]="secondFormGroup">
                <ng-template matStepLabel>Start video Response</ng-template>
            </form>
        </mat-step>

        <mat-step [completed]="stepThree">
            <form [formGroup]="thirdFormGroup">
                <ng-template matStepLabel>Register for Security</ng-template>
            </form>
        </mat-step>

        <mat-step [completed]="stepFour">
            <form [formGroup]="fourthFormGroup">
                <ng-template matStepLabel>Submit for Feedback</ng-template>
            </form>
        </mat-step>

        <mat-step [completed]="stepFive">
            <form [formGroup]="fifthFormGroup">
                <ng-template matStepLabel>Review Personal Feedback</ng-template>
            </form>
        </mat-step>

    </mat-stepper>
</div>



