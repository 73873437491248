import { NgModule } from '@angular/core';
import { ProgramSettingsComponent } from 'app/modules/userprofile/programsettings/programsettings.component';
import { SharedModule } from '../../../shared/shared.module';


/*const exampleRoutes: Route[] = [
    {
        path     : '',
        component: ProfileDetailComponent
    }
];*/

@NgModule({
    declarations: [
        ProgramSettingsComponent
    ],
    imports     : [
        //RouterModule.forChild(exampleRoutes),
        SharedModule,
    ],
    exports: [
        ProgramSettingsComponent
    ]
})
export class ProgramSettingsModule
{
}
