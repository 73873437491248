
<div class="profilePanel">
    <div class="bg-card">
        <div class="flex flex-col w-full bg-slate-50 pb-8">
            <ng-container>
                <div class="flex flex-row items-start">
                    <div>
                        <div class="text-xl md:text-2xl font-semibold tracking-tight leading-7 md:leading-snug truncate mb-2">Admin</div>
                    </div>
                    <!--<div>
                        <button class="px-6 ml-3"
                                mat-flat-button
                                [color]="'primary'" (click)="AssignUsersToCoaches()">
                            Assign User(s) to Coach(es)
                        </button>
                    </div>-->
                </div>
            </ng-container>

            <!-- Tabs -->
            <mat-tab-group (selectedTabChange)="changedTab($event)"
                           class="sm:px-2"
                           mat-stretch-tabs="false"
                           [animationDuration]="'0'">

                <!-- Scenarios Mgmt -->
                <mat-tab label="Scenarios">
                    <ng-template matTabContent>

                        <app-adminscenarios></app-adminscenarios>

                    </ng-template>
                </mat-tab>

                <!-- Scenario Aspect Mapping -->
                <mat-tab label="Scenario Aspect Map">
                    <ng-template matTabContent>

                        <app-adminscenariomap></app-adminscenariomap>

                    </ng-template>
                </mat-tab>

                <!-- User Mgmt -->
                <mat-tab label="Users">
                    <ng-template matTabContent>

                        <ng-template [ngTemplateOutlet]="UserList"></ng-template>

                    </ng-template>
                </mat-tab>

                <!-- Coach Mgmt -->
                <mat-tab label="Coaches">
                    <ng-template matTabContent>

                        <ng-template [ngTemplateOutlet]="CoachList"></ng-template>


                    </ng-template>
                </mat-tab>

                <!-- Aspects Mgmt -->
                <mat-tab label="Aspects">
                    <ng-template matTabContent>

                        <app-adminaspects></app-adminaspects>

                    </ng-template>
                </mat-tab>

                <!-- Aspect Aspect Group Mapping -->
                <mat-tab label="Aspect Aspect Group Map">
                    <ng-template matTabContent>

                        <app-adminaspectmap></app-adminaspectmap>

                    </ng-template>
                </mat-tab>

                <!-- Curriculum Builder -->
                <mat-tab label="Curriculum">
                    <ng-template matTabContent>

                        <app-admincurriculum></app-admincurriculum>

                    </ng-template>
                </mat-tab>


                <!-- Admin Mgmt -->
                <!--<mat-tab label="Admins">

        <ng-template matTabContent>

        </ng-template>
    </mat-tab>-->
                <!-- System Mgmt -->
                <!--<mat-tab label="System">

        <ng-template matTabContent>

        </ng-template>
    </mat-tab>-->

            </mat-tab-group>

        </div>
    </div>
</div>

<!-- User List -->
<ng-template #UserList>

    <div class="container">
        <mat-form-field>
            <mat-label>Select Type</mat-label>
            <mat-select [(ngModel)]="displayType" (ngModelChange)="onChange(displayType)">
                <mat-option *ngFor="let type of selectType" [value]="type.value">
                    {{type.text}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="selectHandler(row)"
                                  [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </mat-cell>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
            </ng-container>

            <!-- Id Column -->
            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
            </ng-container>

            <!-- Detail Column -->
            <ng-container matColumnDef="entityType">
                <mat-header-cell *matHeaderCellDef> Details </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <button class="px-3 mx-3"
                            mat-flat-button
                            [color]="'primary'" (click)="ShowUserDetail(element)">
                        <span>Details</span>
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;">
            </mat-row>
        </mat-table>

        <div>
            <h2>Selected :</h2>
            {{selection.selected.length}} Total Users.
        </div>

        <ng-template [ngTemplateOutlet]="UserDetail" *ngIf="showUserDetail"></ng-template>

    </div>

</ng-template>

<!-- Coach List -->
<ng-template #CoachList>

    <div class="container">
        <mat-form-field>
            <mat-label>Select Type</mat-label>
            <mat-select [(ngModel)]="displayType" (ngModelChange)="onChange(displayType)">
                <mat-option *ngFor="let type of selectType" [value]="type.value">
                    {{type.text}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-table [dataSource]="coachdataSource" class="mat-elevation-z8">

            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="coachselectHandler(row)"
                                  [checked]="coachselection.isSelected(row)">
                    </mat-checkbox>
                </mat-cell>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
            </ng-container>

            <!-- Id Column -->
            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
            </ng-container>

            <!-- Detail Column -->
            <ng-container matColumnDef="entityType">
                <mat-header-cell *matHeaderCellDef> Details </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <button class="px-3 mx-3"
                            mat-flat-button
                            [color]="'primary'" (click)="ShowCoachDetail(element)">
                        <span>Details</span>
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;">
            </mat-row>
        </mat-table>

        <div>
            <h2>Selected :</h2>
            {{coachselection.selected.length}} Total Coaches.
        </div>

        <ng-template [ngTemplateOutlet]="CoachDetail" *ngIf="showCoachDetail"></ng-template>
    </div>

</ng-template>

<!-- User Detail -->
<ng-template #UserDetail>

    <div class="container">
        <h2>User Details</h2>
        <div>User Name: {{selectedUser.name}}</div>

        <div *ngFor="let coach of selectedUser.coaches">

            <div>Assigned Coach Name: {{coach.name}}</div>

        </div>

    </div>

</ng-template>

<!-- Coach Detail -->
<ng-template #CoachDetail>

    <div class="container">
        <h2>Coach Details</h2>
        <div>User Name: {{selectedCoachUser.name}}</div>

        <div *ngFor="let user of selectedCoachUser.users">

            <div>Assigned User Name: {{user.name}}</div>

        </div>

    </div>

</ng-template>
