import { IAspect } from './IAspect';

export class Aspect implements IAspect {
    id: string;
    name: string;
    description: string;
    entityType: string;

    recIndexTot: number;
    moneyInvested: number;
    moneyBudget: number;
    hrsBudget: number;
    hrsInvested: number;

    engStart: number;
    engEarly: number;
    engTotal: number;
    numStrategies: number;
    numResources: number;

    aspectType: string;
    newAspect: boolean;

    constructor() {
        this.name = "";
        this.description = "";
        this.entityType = "";
        this.recIndexTot = 0;
        this.moneyInvested = 0;
        this.moneyBudget = 0;
        this.hrsBudget = 0;
        this.hrsInvested = 0;
        this.engStart = 0;
        this.engEarly = 0;
        this.engTotal = 0;
        this.numStrategies = 0;
        this.numResources = 0;
        this.aspectType = "";
        this.newAspect = true;
    }
}


