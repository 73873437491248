import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertType } from '@fuse/components/alert';
import { AuthService } from 'app/core/auth/auth.service';
import { forEach } from 'lodash';
import { user } from '../../../mock-api/common/user/data';
import { LoginDto, SMService, UserDto } from 'app/services/sm.service';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
    selector     : 'auth-starttrial',
    templateUrl  : './starttrial.component.html',
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class AuthStartTrialComponent implements OnInit
{
    public smService: SMService;
    baseURL: string = environment.API_URL;

    @ViewChild('startTrialNgForm') startTrialNgForm: NgForm;

    alert: { type: FuseAlertType; message: string } = {
        type   : 'success',
        message: ''
    };
    startTrialForm: UntypedFormGroup;
    showAlert: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _authService: AuthService,
        private _formBuilder: UntypedFormBuilder,
        private _router: Router,
        sms: SMService,
        private _httpClient: HttpClient,
    )
    {
        this.smService = sms;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Create the form
        this.startTrialForm = this._formBuilder.group({
            name: ['Guest'],
            password: ['Guest'],
            rememberMe: ['N'],
            userType: ['TrialUser']
        });

        // If the user is already registered but not yet logged in, request log in for first time
        /*if (this._authService.registeredNotLoggedIn) {
            // Set the alert
            this.alert = {
                type: 'info',
                message: 'Registration successful.  Please sign in.'
            };

            // Show the alert
            this.showAlert = true;
        }*/

        // Register the joinNow event listener
        this.smService.joinNow.subscribe(message => {
            console.log("JoinNow: " + message);
            if (message == "JoinNow") {
                this.startTrial();
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign in
     */
    startTrial(): void
    {
        // Reset the join now flag
        this.smService.joinNow.next("");

        // Return if the form is invalid
        if ( this.startTrialForm.invalid )
        {
            return;
        }

        // Disable the form
        this.startTrialForm.disable();

        // Hide the alert
        this.showAlert = false;

        console.log("STARTING TRIAL -------------------- ");

        // Create a new model object
        var model = new LoginDto();
        model.username = "Guest";
        model.name = "Guest";
        model.password = "Guest";
        model.userType = "TrialUser";

        var addurl = this.baseURL + 'api/neo/registertrialuser';
        console.log("AuthService BaseURL: " + this.baseURL);

        //var retval = this._httpClient.post<UserDto>(addurl, model);

        // retval is an observable
        /*retval.subscribe(response => {

            console.log("AuthService response: " + response);

        });

        console.log ("AuthService retval: " + retval);*/

        // Sign in
        this._authService.startTrial(this.startTrialForm.value)
            .subscribe(
                (response) => {

                    if (response.hasError) {
                        this.handleErrors();
                    } else {

                        // Set the user type as a trial user
                        this.smService.SetUserType('TrialUser');
                        this.smService.SetOnboarding(true);

                        // preload the trial scenario
                        this.LoadTrialScenario();

                    }
                },
                () => {
                    this.handleErrors();
                }
            );
            
    }

    LoadTrialScenario() {
        this.smService.LoadTrialScenario().subscribe(result => {

            // Reset any current scenario 
            this.smService.CurrentScenarioRec.next(result);
            this.smService.CurrentResponse.next(null);
            this.smService.VideoSource.next(null);
            this.smService.VideoSourceThumb.next(null);
            this.smService.NewResponse.next(null);
            this.smService.VideoSource.next(result.resourceURL);
            this.smService.VideoSourceThumb.next(result.resourceThumbURL);

            console.log("Start Trial: Loaded Trial Scenario");

            // Redirect based on the user type
            if (this.showAlert == false) {
                this._router.navigate(['admintest', 'layout', 'userprofile', 'programscenariodetail', 'viewscenario']);
            }

        }, error => console.error(error));
    }


    handleErrors() {
        console.log("Error on Login");
        // Re-enable the form
        this.startTrialForm.enable();

        // Reset the form
        this.startTrialNgForm.resetForm();

        // Set the alert
        this.alert = {
            type: 'error',
            message: 'Unable to start trial at this time.  Please try again later.'
        };

        // Show the alert
        this.showAlert = true;
     }

}
