import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { SharedModule } from '../../../../shared/shared.module';
import { MessageVideoModule } from './messagevideo/messagevideo.module';
import { ProgramMessageComponent } from './programmessage.component';



/*const exampleRoutes: Route[] = [
    {
        path     : '',
        component: ProgramMessageComponent
    }
];*/

@NgModule({
    declarations: [
        ProgramMessageComponent
    ],
    imports     : [
        //RouterModule.forChild(exampleRoutes),
        SharedModule,
        MessageVideoModule
    ],
    exports: [
        ProgramMessageComponent
    ]
})
export class ProgramMessageModule
{
}
