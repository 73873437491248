import { IEdge } from './IEdge';
import { FormControl, FormGroup, Validators } from '@angular/forms';


export class Edge implements IEdge {
    id: string;
    name: string;
    description: string;
    startNodeId: string;
    endNodeId: string;
    type: string;
    start: string;
    end: string;
    startId: string;
    endId: string;
    startNode: string;
    endNode: string;
    startNodeName: string;
    endNodeName: string;
    startNodePos: number;
    endNodePos: number;
    length: number;
    weight: number;
    entityType: string;


    constructor() {
        this.name = "";
        this.description = "";
        this.startNodeId = "";
        this.endNodeId = "";
        this.type = "";
        this.start = "";
        this.end = "";
        this.startId = "";
        this.endId = "";
        this.startNode = "";
        this.endNode = "";
        this.startNodeName = "";
        this.endNodeName = "";
        this.startNodePos = 0;
        this.endNodePos = 0;
        this.length = 0;
        this.weight = 0;
        this.entityType = "";
    }

    static asFormGroup(edge: Edge): FormGroup {
        const fg = new FormGroup({
            startNodeName: new FormControl(edge.startNodeName, Validators.required),
            endNodeName: new FormControl(edge.endNodeName, Validators.required),
            endId: new FormControl(edge.endId, Validators.required),
            type: new FormControl(edge.type, Validators.required),
            length: new FormControl(edge.length, Validators.required),
            weight: new FormControl(edge.weight, Validators.required),
            name: new FormControl(edge.name),
            description: new FormControl(edge.description),
            startNodeId: new FormControl(edge.startNodeId),
            endNodeId: new FormControl(edge.endNodeId),
            start: new FormControl(edge.start),
            end: new FormControl(edge.end),
            startId: new FormControl(edge.startId),
            startNode: new FormControl(edge.startNode),
            endNode: new FormControl(edge.endNode),
            entityType: new FormControl(edge.entityType),
            startNodePos: new FormControl(edge.startNodePos),
            endNodePos: new FormControl(edge.endNodePos),
            id: new FormControl(edge.id)
        });
        return fg;
    }
}


