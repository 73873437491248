<!--<p>admintest works!</p>

<div> Navigation Bar Comes HERE </div>
<div>
    <button class="primary" mat-button (click)="showadminchild()">Admin Child</button>

    <button class="primary" mat-button (click)="showadminsecondchild()">Admin Second Child</button>

    <button class="primary" mat-button (click)="showlayout()">Main Layout</button>

</div>-->


<router-outlet></router-outlet>

<!--<div>admin test footer</div>-->
