import { IScenario } from './IScenario';

export class Scenario implements IScenario {
    id: string;
    name: string;
    description: string;
    entityType: string;

    title: string;
    scenarioType: string;
    resourceURL: string;
    resourceThumbURL: string;
    scenarioNum: string;
    format: string;
    difficulty: string;
    sequence: number;
    userLevel: string;
    userRating: string;
    estEngagement: number;
    userEngagement: number;
    estTimeInvestment: number;
    estMoneyInvestment: number;
    estEnergyInvestment: number;
    energyFactorGrp: string;
    responseRequired: string;
    useCases: string;
    programs: string;
    preReqs: string;
    numRelationships: number;
    indexed: boolean;
    numRubrics: number;
    inRecModel: boolean;
    hasTranscript: boolean;
    transcript: string;
    transcriptURL: string;
    practiceRequired: number;
    uC_PublicSpeaking: number;
    uC_Interviews: number;
    uC_Teaching_and_training: number;
    uC_Sales_marketing: number;
    uC_Social_and_Networking: number;
    notes: string;
    scenarioIntent: string;
}
