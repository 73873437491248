import { NgModule } from '@angular/core';
import { AdminCurriculumComponent } from 'app/modules/adminprofile/admincurriculum/admincurriculum.component';
import { SharedModule } from '../../../shared/shared.module';
import { FuseCardModule } from '@fuse/components/card';
import { FuseAlertModule } from '@fuse/components/alert';


@NgModule({
    declarations: [
        AdminCurriculumComponent
    ],
    imports: [
        SharedModule,
        FuseCardModule,
        FuseAlertModule
    ],
    exports: [
        AdminCurriculumComponent
    ]
})
export class AdminCurriculumModule
{
}
