import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationItem, FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { compactNavigation, defaultNavigation, futuristicNavigation, horizontalNavigation } from './defaultnavigation';
import { cloneDeep } from 'lodash-es';
import { SMService } from '../../../../services/sm.service';
import { UserProfile } from '../../../../models/UserProfile';
import { AuthService } from '../../../../core/auth/auth.service';

@Component({
    selector     : 'classy-layout',
    templateUrl  : './classy.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ClassyLayoutComponent implements OnInit
{
    isScreenSmall: boolean;
    navigation: Navigation;
    user: User;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    private readonly _compactNavigation: FuseNavigationItem[] = compactNavigation;
    private readonly _defaultNavigation: FuseNavigationItem[] = defaultNavigation;
    private readonly _futuristicNavigation: FuseNavigationItem[] = futuristicNavigation;
    private readonly _horizontalNavigation: FuseNavigationItem[] = horizontalNavigation;

    public hasProfileImage: boolean = false;
    public userprofile: UserProfile;
    public showSidebar: boolean = true;
    public isOnboarding: boolean = false;

    public showLogin: boolean = false;
    public message: string = "JoinNow";
    public showStartJoin: boolean = false;


    @Input() userType: string;

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private smService: SMService,
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _authService: AuthService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.hasProfileImage = false;

        // Subscribe to navigation data
        /*this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });*/

        // Subscribe to the user service
        this._userService.user$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((user: User) => {
                this.user = user;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        console.log("Classy Component: userType: " + this.userType);
        this.smService.userType.next(this.userType);

        // Load User Profile for Navigation
        this.userprofile = new UserProfile();

        var usr = this.smService.profiledetail.subscribe(profile => {

            var prof = this.smService.profiledetail.getValue();
            var haveprof = false;

            if (prof != undefined) {
                if (prof.userName != undefined && prof.userName != "" && prof.userName != null) {
                    haveprof = true;
                }
            }

            if (haveprof) {
                this.userprofile = profile;
                console.log("User Profile: " + this.userprofile.userName + " - " + this.userprofile.userStatus);

                if (this.userprofile.imageURL != null && this.userprofile.imageURL != "") {
                    this.hasProfileImage = true;
                }

            } else {
                this.LoadUserProfile();
            }

        });

        var k = this.smService.isOnboarding.subscribe(response => {

            console.log("Classy Component: isOnboarding detected as: " + response);

            if (response) {                
                this.showSidebar = false;
                this.userprofile.userStatus = "Trial User";
                this.userprofile.imageURL = "";
                this.userprofile.userName = this._authService.csUserName;
                this.isOnboarding = true;
                console.log("isOnboarding set to : " + this.isOnboarding + " for userStatus: " + this.userprofile.userStatus);
                console.log("Recreating Navigation");
                this.navigation = this.CreateNavigation();
            } else {
                this.showSidebar = true;
                this.userprofile.userStatus = "Active User";
                this.isOnboarding = false;
                console.log("isOnboarding set to : " + this.isOnboarding + " for userStatus: " + this.userprofile.userStatus);
            }

        });


        /*var y = this.smService.userType.subscribe(response => {
            if (this.smService.IsTrialUser()) {
                this.userprofile.userStatus = "Trial User";
                this.userprofile.imageURL = "";
                this.userprofile.userName = this._authService.csUserName;
            } else {
                this.userprofile.userStatus = "Active User";
            }
        });*/

        //this.navigation = this.CreateNavigation();


    }

    private LoadUserProfile() {
        // Update the UserProfile
        this.smService.GetUserProfile().subscribe(result => {
            this.userprofile = result.userProfile;
            this.smService.profiledetail.next(this.userprofile);

            if (this.userprofile.userStatus != "Onboarding") {
                if (this.userprofile.imageURL != null && this.userprofile.imageURL != "") {
                    this.hasProfileImage = true;
                }
            } else {
                this.smService.isOnboarding.next(true);
            }

            console.log("Loaded User Profile: " + this.userprofile.userName + " - " + this.userprofile.userStatus);

        }, error => console.error(error));
    }


    getProfileImage(): string {
        if (this.hasProfileImage) {
            var profile = this.smService.profiledetail.getValue();

            console.log("Profile Image URL: " + profile.imageURL);
            return profile.imageURL;
        }
        else {
            console.log("Profile Image URL: " + "assets/images/avatars/Avatar_placeholder.png");
            return "assets/images/avatars/Avatar_placeholder.png";
        }
    }


    CreateNavigation(): Navigation {

        // Fill compact navigation children using the default navigation
        this._compactNavigation.forEach((compactNavItem) => {
            this._defaultNavigation.forEach((defaultNavItem) => {
                if (defaultNavItem.id === compactNavItem.id) {
                    compactNavItem.children = cloneDeep(defaultNavItem.children);
                }
            });
        });

        // Fill futuristic navigation children using the default navigation
        this._futuristicNavigation.forEach((futuristicNavItem) => {
            this._defaultNavigation.forEach((defaultNavItem) => {
                if (defaultNavItem.id === futuristicNavItem.id) {
                    futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                }
            });
        });

        // Fill horizontal navigation children using the default navigation
        this._horizontalNavigation.forEach((horizontalNavItem) => {
            this._defaultNavigation.forEach((defaultNavItem) => {
                if (defaultNavItem.id === horizontalNavItem.id) {
                    horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                }
            });
        });

        var navig = 
            {
                compact: cloneDeep(this._compactNavigation),
                default: cloneDeep(this._defaultNavigation),
                futuristic: cloneDeep(this._futuristicNavigation),
                horizontal: cloneDeep(this._horizontalNavigation)
            };

        // Set flag that navigation has been created.
        this.smService.navigationCreated.next(true);

        // Return the response
        return navig;

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void
    {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    toggleLogin() {

        this.showLogin = !this.showLogin;

    }

    JoinNow() {

        this.showStartJoin = !this.showStartJoin;

        // Call the start trial module's startTrial method via the service
        this.smService.joinNow.next(this.message);
        //console.log("JoinNow clicked");
    }


}
