import { Route, RouterModule } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { NgModule } from '@angular/core';
import { AdmintestComponent } from './layout/layouts/admintest/admintest.component';

export const appRoutes: Route[] = [

    // Empty Path
    //{ path: '', pathMatch: 'full', redirectTo: '/sign-in' },
    //{ path: '', pathMatch: 'full', redirectTo: '/home' },

    // Routes for superadmin - remove for production
    { path: 'superadmin', pathMatch: 'full', component: LayoutComponent, data: { layout: 'empty' }, loadChildren: () => import('app/modules/auth/superadmin/superadmin.module').then(m => m.SuperAdminModule) },

    // Auth routes for admin
    { path: 'admin-sign-up', pathMatch: 'full', component: LayoutComponent, data: { layout: 'empty' }, loadChildren: () => import('app/modules/auth/admin-sign-up/admin-sign-up.module').then(m => m.AuthAdminSignUpModule) },

    // Auth routes for coach
    { path: 'coach-sign-up', pathMatch: 'full', component: LayoutComponent, data: { layout: 'empty' }, loadChildren: () => import('app/modules/auth/coach-sign-up/coach-sign-up.module').then(m => m.AuthCoachSignUpModule) },


    // Auth routes for guests
    { path: 'confirmation-required', pathMatch: 'full', component: LayoutComponent, data: { layout: 'empty' }, loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule) },
    { path: 'forgot-password', pathMatch: 'full', component: LayoutComponent, data: { layout: 'empty' }, loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },
    { path: 'reset-password', pathMatch: 'full', component: LayoutComponent, data: { layout: 'empty' }, loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule) },


    {
        path: 'sign-in',
        pathMatch: 'full',
        component: LayoutComponent,
        data: { layout: 'classy' },
        loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)
    },

    {
        path: '',
        pathMatch: 'full',
        component: LayoutComponent,
        data: { layout: 'classy' },
        loadChildren: () => import('app/modules/home/home.module').then(m => m.HomeModule)
    },



    { path: 'sign-up', pathMatch: 'full', component: LayoutComponent, data: { layout: 'empty' }, loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule) },
    { path: 'starttrial', pathMatch: 'full', component: LayoutComponent, data: { layout: 'empty' }, loadChildren: () => import('app/modules/auth/starttrial/starttrial.module').then(m => m.AuthStartTrialModule) },


    // Auth routes for authenticated users
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', pathMatch: 'full', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
            { path: 'unlock-session', pathMatch: 'full', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)}
        ]
    },

    /*{
        path: 'layout',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        data: { layout: 'modern', userType: 'User' },
        resolve: { initialData: InitialDataResolver },
        loadChildren: () => import('app/modules/userprofile/userprofile.module').then(m => m.UserProfileModule)
    },*/

    /*{
        path: 'layout',
        canMatch: [AuthGuard],
        data: { layout: 'modern', userType: 'User' },
        resolve: { initialData: InitialDataResolver },
        loadChildren: () => import('app/layout/layout.module').then(m => m.LayoutModule)
    },*/

    /*{
        path: 'layout',
        canMatch: [AuthGuard],
        data: { layout: 'modern', userType: 'User' },
        resolve: { initialData: InitialDataResolver },
        component: LayoutComponent,
        children: [
            {
                path: 'adminchild',
                loadChildren: () => import('app/modules/admintest/adminchild/adminchild.module').then(m => m.AdminchildModule)
            },
            {
                path: 'adminsecondchild',
                loadChildren: () => import('app/modules/admintest/adminsecondchild/adminsecondchild.module').then(m => m.AdminsecondchildModule)
            }
        ]
    },*/

    {
        path: 'admintest',
        component: AdmintestComponent,
        children: [
            {
                path: 'adminchild',
                loadChildren: () => import('app/modules/admintest/adminchild/adminchild.module').then(m => m.AdminchildModule)
            },
            {
                path: 'adminsecondchild',
                loadChildren: () => import('app/modules/admintest/adminsecondchild/adminsecondchild.module').then(m => m.AdminsecondchildModule)
            },
            {
                path: 'layout',
                data: { layout: 'classy', userType: 'User' },
                loadChildren: () => import('app/layout/layout.module').then(m => m.LayoutModule)
            }
        ]
    },

    /*{
        path: 'adminprofile',
        pathMatch: 'full',
        resolve: { initialData: InitialDataResolver },
        component: LayoutComponent, data: { layout: 'modern', userType: 'Admin' },
        loadChildren: () => import('app/modules/adminprofile/adminprofile.module').then(m => m.AdminProfileModule)
    },*/

    /*{
        path: 'userprofile',
        pathMatch: 'full',
        canMatch: [AuthGuard],
        resolve: { initialData: InitialDataResolver },
        loadChildren: () => import('app/modules/userprofile/userprofile.module').then(m => m.UserProfileModule)
    },*/

    /*{
        path: 'layout',
        pathMatch: 'full',
        canMatch: [AuthGuard],
        data: { layout: 'modern', userType: 'User' },
        resolve: { initialData: InitialDataResolver },
        component: LayoutComponent,
        children: [
            {
                path: '', 
                loadChildren: () => import('app/modules/userprofile/userprofile.module').then(m => m.UserProfileModule)
            }
        ]
    },*/

    /*{
        path: '',
        canMatch: [AuthGuard],
        resolve: { initialData: InitialDataResolver },
        children: [
            { path: 'userprofile', pathMatch: 'full', component: LayoutComponent, data: { layout: 'modern', userType: 'User' }, loadChildren: () => import('app/modules/userprofile/userprofile.module').then(m => m.UserProfileModule) },
            { path: 'coachprofile', pathMatch: 'full', component: LayoutComponent, data: { layout: 'modern', userType: 'Coach' }, loadChildren: () => import('app/modules/coachprofile/coachprofile.module').then(m => m.CoachProfileModule) },
            { path: 'adminprofile', pathMatch: 'full', component: LayoutComponent, data: { layout: 'modern', userType: 'Admin' }, loadChildren: () => import('app/modules/adminprofile/adminprofile.module').then(m => m.AdminProfileModule) },
        ]
    },*/

        // TODO
    //{ path: '**', component: PageNotFoundComponent },  // Wildcard route for a 404 page

];

@NgModule({
    imports: [
        RouterModule.forRoot(
            appRoutes,
            {
                bindToComponentInputs: true,
                //enableTracing: true
            } // <-- debugging purposes only
        )
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
