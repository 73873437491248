import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Input } from '@angular/core';
import { ILoadResult } from '../../../../models/ILoadResult';
import { ProgramMessage } from '../../../../models/ProgramMessage';
import { SMService } from '../../../../services/sm.service';
import { environment } from '../../../../../environments/environment';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';

@Component({
  selector: 'app-programmessage',
  templateUrl: './programmessage.component.html'
})
export class ProgramMessageComponent implements OnInit {
  public theURL: string;
  public thehttp: HttpClient;
  public graphLoadForm: FormGroup;
  public loadResults: ILoadResult[];
  public currentResult: string;
  public currentUser: string = "";
  public programMessage = new ProgramMessage();
  private showRationale: boolean = false;
  public showVideo: boolean = false;
  public videourl: string = "";


    @ViewChild('userMessageVideo') userMessageVideoElementRef: ElementRef;
    @ViewChild('testImage') testImageElementRef: ElementRef;
    videoElement: HTMLVideoElement;


  //@Input("message") incomingmessage;
    @Input() msg: ProgramMessage;
    @Input() coachMode: boolean;

    @Output() msgEvent = new EventEmitter<ProgramMessage>();
    @Output() removeMsgEvent = new EventEmitter<ProgramMessage>();

  baseUrl = '';

  //  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string) {

  constructor(
        http: HttpClient,
        private formBuilder: FormBuilder,
        private smService: SMService
      )
      {
        this.baseUrl = environment.BASE_URL;
        this.theURL = this.baseUrl;
        this.thehttp = http;
  }

    async ngOnInit() {

        //console.log("Message Type is: " + this.msg.messageType);
        if (this.msg.messageType == "CoachMessage" || this.msg.messageType == "UserMessage") {

            if ((this.msg.responseFileName != null) && (this.msg.responseFileName != "") && (this.msg.responseFileName != undefined) && (this.msg.responseFileName != "undefined")) {

                this.showVideo = true;
            }
        }

    }

    GetMessageAvatar(): string {
        // Default avatar URL if none found
        // TODO - move into separate settings
        var url = "assets/images/avatars/cogsolo__mobile_logo_1.png";
        if (this.msg.imageURL != "" && this.msg.imageURL != undefined && this.msg.imageURL != null) {
            url = this.msg.imageURL;
        }
        return url;
    }


    addNewMessage(value: ProgramMessage) {
        this.msgEvent.emit(value);
    }

    removeMessage(value: ProgramMessage) {
        this.removeMsgEvent.emit(value);
    }


    AcceptRec(): void {

        // Send to Server
        console.log("Accepting Recommendation: " + this.msg.messageText);
        this.smService.AcceptRec(this.msg).subscribe(result => {

            // Get the response message from the server
            //this.programMessage = result;

            // Add message to client side programstream - send back to parent
            //this.addNewMessage(this.programMessage);

            // On receipt from server, update the message with the server's messageNumber and status
            console.log("Confirmed accepted recommendation.");

        }, error => console.error(error));

    }

    SkipRec(): void {

        // Send to Server
        console.log("Skipping Recommendation: " + this.msg.messageText);
        this.smService.SkipRec(this.msg).subscribe(result => {

            // Get the response message from the server
            //this.programMessage = result;

            // Add message to client side programstream - send back to parent
            //this.addNewMessage(this.programMessage);

            // On receipt from server, update the message with the server's messageNumber and status
            console.log("Confirmed skipped recommendation.");

        }, error => console.error(error));

    }

    SeeRationale(): void {
        this.showRationale = true;
        var reasons = this.msg.reasons;
        console.log(this.msg.reasons[0].reasonText);

    }    

    HideRationale(): void {
        this.showRationale = false;
    }    


    DeleteMessage(): void {

        // Send to Server
        console.log("Deleting Message: " + this.msg.messageText);
        this.smService.DeleteMessage(this.msg).subscribe(result => {

            // Get the response message from the server
            this.programMessage = result;

            // Remove message from client side programstream - send back to parent
            this.removeMessage(this.programMessage);
            console.log("Confirm Deleted Message.");

        }, error => console.error(error));
    }

    RequestReview(): void { }

    ReportFun(): void { }

    ReportHard(): void { }




 }

