<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation class="dark bg-slate-50 print:hidden"
                          [mode]="isScreenSmall ? 'over' : 'side'"
                          [name]="'mainNavigation'"
                          [navigation]="navigation.default"
                          [opened]="false">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-center w-full p-4 pl-6">

            <!-- Logo -->
            <div class="flex items-center justify-center">
                <img class="h-12 mb-5 mt-5" src="assets/images/logo/cogsolo_logo_1.png" />
            </div>

            <!-- Components -->
            <div class="flex items-center ml-auto">
                <notifications></notifications>
            </div>

        </div>

        <div class="flex flex-0 items-center justify-center h-8 pr-6 pl-2 mt-2 mb-4">
            <div class="ml-0.5 font-medium text-secondary">
                <span>Beta Version 0.6</span>
            </div>
        </div>


    </ng-container>

    <!-- Navigation footer hook -->
    <ng-container fuseVerticalNavigationContentFooter>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <!--<button mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
        <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>-->
        <!-- Logo -->
        <div class="flex-none items-center justify-center">
            <img class="h-12 mb-5 mt-5" src="assets/images/logo/cogsolo_logo_1.png" />
        </div>

        <!-- Spacer -->
        <div class="grow h-14"></div>

        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <!--<languages></languages>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <search [appearance]="'bar'"></search>
            <shortcuts></shortcuts>
            <messages></messages>-->
            <user [showAvatar]="false"></user>
        </div>

    </div>



    <!-- MAIN PORTION OF THE APPLICATION -->
    <div class="flex flex-col flex-auto">
        <div class="flex flex-col flex-auto min-w-0 bg-slate-50">

            <app-userprofile *ngIf="userType === 'User'"></app-userprofile>

            <app-coachprofile *ngIf="userType === 'Coach'"></app-coachprofile>

            <app-adminprofile *ngIf="userType === 'Admin'"></app-adminprofile>

        </div>
    </div>

    <!-- Footer -->
    <div class="flex items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card text-white dark:bg-transparent print:hidden bg-slate-800">
        <div class="flex-none w-14 h-14"></div>
        <div class="flex grow font-medium text-secondary items-center justify-center">
            IdeaGPS, Inc. &copy; {{currentYear}}
        </div>
        <div class="flex-none w-14 h-14"></div>
    </div>



</div>







