
<!--<router-outlet></router-outlet>-->

<div class="flex flex-row min-w-screen">
    <div class="">
        <!-- Navigation -->
        <fuse-vertical-navigation class="dark bg-gray-900 print:hidden"
                                  [mode]="isScreenSmall ? 'over' : 'side'"
                                  [name]="'defaultNavigation'"
                                  [navigation]="navigation.default"
                                  [opened]="!isScreenSmall"
                                  [autoCollapse]="false">
\            <ng-container fuseVerticalNavigationContentHeader>
                <div class="flex items-center justify-center w-full p-4 pl-6">
                    <div class="flex items-center">
                        <img class="w-48"
                             src="assets/images/logo/Cogsolo_Logo_2_Transparent.png">
                    </div>

                </div>

                <div class="flex flex-col items-center w-full p-2">
                    <div class="relative w-20 h-20">

                        <img *ngIf="hasProfileImage" [src]="userprofile.imageURL" class="w-full h-full rounded-full" />

                        <img *ngIf="!hasProfileImage" src="assets/images/avatars/Avatar_placeholder.png" class="w-full h-full rounded-full" />

                    </div>
                    <div class="flex flex-col items-center justify-center w-full mt-2">
                        <div class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium" *ngIf="!isOnboarding">
                            {{userprofile.name}}
                        </div>
                        <div class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary" *ngIf="isOnboarding">
                            {{userprofile.userName}}
                        </div>
                    </div>
                </div>

            </ng-container>

            <!--<ng-container fuseVerticalNavigationContentFooter>

                <div class="flex items-center justify-left pl-3 ml-auto opacity-75">
                    <user [showAvatar]="true"></user>
                </div>

                <mat-divider class="w-full"></mat-divider>

                <div class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mt-2 mb-4 opacity-50">
                    <span class="text-xs">IdeaGPS, Inc. &copy; {{currentYear}}</span>
                </div>

            </ng-container>-->
        </fuse-vertical-navigation>
    </div>

    <div class="grow">

        <!--<div>UserType is: {{userType}}</div>-->

        <div class="flex-auto grid grid-cols-10 h-full" *ngIf="userType === 'User'">

            <div class="flex flex-col col-span-10">
                <!-- Content -->
                <div class="flex flex-col flex-auto">

                    <!--THE USER NAME IS: {{userprofile.name}}  THIS WORKS FINE -->


                    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.  Otherwise, layout changes won't be registered and the view won't be updated! -->
                    <router-outlet></router-outlet>
                </div>
            </div>

            <!-- Message Sidebar -->
            <!--<div class="flex flex-col col-span-2 pr-4 bg-white messagesBar">
                <app-programstream [currentUserProfile]="userprofile"></app-programstream>
            </div>-->

        </div>

        <div class="flex-auto grid grid-cols-10 h-full" *ngIf="userType === 'Admin'">

            <div class="flex flex-col col-span-10">
                <!-- Content -->
                <div class="flex flex-col flex-auto">
                    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.  Otherwise, layout changes won't be registered and the view won't be updated! -->
                    <router-outlet *ngIf="true"></router-outlet>
                </div>
            </div>

        </div>


    </div>

</div>
