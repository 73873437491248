import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { ProgramMessage } from '../../../../models/ProgramMessage';
import { SMService } from '../../../../services/sm.service';
declare var MediaRecorder: any;
@Component({
    selector: 'app-video-uploader',
    templateUrl: './videouploader.component.html'
})
export class VideoUploaderComponent implements OnInit {

    // Taken from: Video Record and Play By Rajesh Gami
    // https://www.c-sharpcorner.com/article/how-to-record-a-video-using-angular/

    videoElement: HTMLVideoElement;
    recordVideoElement: HTMLVideoElement;
    mediaVideoRecorder: any;
    videoRecordedBlobs: Blob[];
    isRecording: boolean = false;
    hasRecording: boolean = false;
    downloadVideoUrl: string;
    stream: MediaStream;
    @ViewChild('recordedVideo') recordVideoElementRef: ElementRef;
    @ViewChild('liveVideo') videoElementRef: ElementRef;

    @Output() cancelRecEvent = new EventEmitter<string>();
    @Output() uploadRecEvent = new EventEmitter<Blob>();

    public showCapture: boolean = true;
    public allowSaving: boolean = true;

    constructor(
        private smService: SMService
    ) { }

    async ngOnInit() {

        var constraints = {
            audio: true,
            video: true
        };

        navigator.mediaDevices.getUserMedia(
            constraints
        ).then(
                stream => {
                    this.videoElement = this.videoElementRef.nativeElement;
                    this.recordVideoElement = this.recordVideoElementRef.nativeElement;
                    this.stream = stream;
                    this.videoElement.srcObject = this.stream;
                }
        );

        /*navigator.mediaDevices.getUserMedia({
            video: {
                width: 480
            }
        }).then(stream => {
            this.videoElement = this.videoElementRef.nativeElement;
            this.recordVideoElement = this.recordVideoElementRef.nativeElement;
            this.stream = stream;
            this.videoElement.srcObject = this.stream;
        });*/
    }


    public startVideoRecording() {
        console.log("startVideoRecording");
        this.hasRecording = false;
        this.videoRecordedBlobs = [];
        let options: any = {
            mimeType: 'video/webm; codecs=vp9',
        };
        try {
            this.mediaVideoRecorder = new MediaRecorder(this.stream, options);
        } catch (err) {
            console.log(err);
        }
        this.mediaVideoRecorder.start();
        this.isRecording = !this.isRecording;
        this.onDataAvailableVideoEvent();
        this.onStopVideoRecordingEvent();
    }
    public stopVideoRecording() {
        this.mediaVideoRecorder.stop();
        this.isRecording = !this.isRecording;
        this.hasRecording = true;
    }
    public playRecording() {
        if (!this.videoRecordedBlobs || !this.videoRecordedBlobs.length) {
            return;
        }
        this.recordVideoElement.play();
    }
    public onDataAvailableVideoEvent() {
        try {
            this.mediaVideoRecorder.ondataavailable = (event: any) => {
                if (event.data && event.data.size > 0) {
                    this.videoRecordedBlobs.push(event.data);
                }
            };
        } catch (error) {
            console.log(error);
        }
    }
    public onStopVideoRecordingEvent() {
        try {
            this.mediaVideoRecorder.onstop = (event: Event) => {
                const videoBuffer = new Blob(this.videoRecordedBlobs, {
                    type: 'video/webm'
                });
                this.downloadVideoUrl = window.URL.createObjectURL(videoBuffer);
                this.recordVideoElement.src = this.downloadVideoUrl;
            };
        } catch (error) {
            console.log(error);
        }
    }

    public cancelRecorder() {
        this.hasRecording = false;

        this.showCapture = true;
        this.allowSaving = true;

        // Raise event back to parent to close component
        this.cancelRecEvent.emit("cancelRecorder");

    }

    public uploadRecording() {

        // Get the video blob
        const videoBuffer = new Blob(this.videoRecordedBlobs, {
            type: 'video/mp4'
        });

        this.showCapture = false;
        this.allowSaving = false;

        // Raise event back to parent to close component
        this.uploadRecEvent.emit(videoBuffer);

    }



    public download() {
        var blob = new Blob(this.videoRecordedBlobs, {
            type: 'video/mp4'
        });
        var url = URL.createObjectURL(blob);
        var a = document.createElement('a');
        document.body.appendChild(a);
        //a.style = 'display: none';
        a.href = url;
        a.download = '';
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

    }

}
