/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'summary',
        title: 'Summary',
        subtitle: 'Overview of your progress',
        type: 'group',
        icon: 'heroicons_outline:chart-pie',
    },
    {
        id: 'skills',
        title: 'Skill Programs',
        subtitle: 'Your skill programs',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [

        ]
    },
];
export const compactNavigation: FuseNavigationItem[] = [

];
export const futuristicNavigation: FuseNavigationItem[] = [

];
export const horizontalNavigation: FuseNavigationItem[] = [

];
